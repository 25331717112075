import { Theme } from '../types/theme-types';

// TODO: Change this to `3xl and 4xl`
export const fontSizes: Theme['fontSizes'] = {
  xxxxl: 56,
  xxxl: 40,
  xxl: 32,
  xl: 24,
  l: 20,
  m: 16,
  s: 14,
  xs: 12,
  xxs: 10,
};

export const fontWeights: Theme['fontWeights'] = {
  light: 300,
  regular: 400,
  semiBold: 600,
};

export const lineHeights: Theme['lineHeights'] = {
  xxxxl: '64px',
  xxxl: '52px',
  xxl: '42px',
  xl: '32px',
  l: '28px',
  m: '24px',
  s: '22px',
  xs: '20px',
  xxs: '16px',
};

export const fonts: Theme['fonts'] = {
  body: "'Work Sans', sans-serif",
};
