import { createContext, useContext } from 'react';

/**
 * NativeWebServerPropsValue:
 * Server props controlling presence of native-web elements and hooks
 */
export type NativeWebServerPropsValue = {
  useragent: string;
  simulated: boolean;
};

/**
 * NativeWebServerPropsValue:
 * A means of passing in server props for the rendering of native-web elements and hooks
 */
export const NativeWebServerProps = createContext<
  undefined | NativeWebServerPropsValue
>(undefined);

export function useNativeWebServerProps() {
  const serverProps = useContext(NativeWebServerProps);

  return serverProps;
}
