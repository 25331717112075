import styled from '@emotion/styled';
import React from 'react';
import { Info } from 'phosphor-react';
import { space } from 'styled-system';
import { SpaceProps } from '../../types/theme-types';

type InfoButtonProps = {
  /**
   * Callback function for onClick event
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;

  id?: string;
  /**
   * ID of an element which contains a label for this InfoButton
   */
  labeledBy?: string;
  /**
   * The label string to use for this InfoButton. The title of the SVG is also set to
   * this value.
   */
  label?: string;
} & SpaceProps;

const InfoWrapper = styled(Info)`
  height: 16px;
  width: 16px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.link};

  &:hover {
    color: ${({ theme }) => theme.colors.hover};
    border-radius: 50%;
  }
`;
const ButtonWrapper = styled('button')`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 0;
  cursor: pointer;
  outline: none;
  vertical-align: inherit;
  display: inline-block;
  &:focus svg {
    outline: ${({ theme }) => theme.colors.hover} 2px solid;
  }

  ${space}
`;

export const InfoButton: React.FC<InfoButtonProps> = ({
  onClick,
  id,
  labeledBy,
  label,
  ...props
}) => {
  return (
    <ButtonWrapper
      type="button"
      onClick={onClick}
      aria-label={label}
      aria-labelledby={labeledBy}
      id={id}
      {...props}
    >
      <InfoWrapper>
        {label && <title>{label}</title>}
        <desc>
          Small circle with an i in the center signifying more information
        </desc>
      </InfoWrapper>
    </ButtonWrapper>
  );
};
