import { Palette } from '../constants/palette';
import { Theme } from '../types/theme-types';

const sizes = {
  s: '32px',
  m: '48px',
  l: '64px',
};

const selectorColors = {
  hoverColor: Palette.white,
  disabledColor: Palette.gray50,
  focusBorderColor: Palette.royalBlue,
  focusMiddleBorder: Palette.royalBlue,
  focusInnerBorder: Palette.white,
};

export const button: Theme['button'] = {
  borderRadius: '16px',
  borderRadii: {
    s: '8px',
    m: '12px',
    l: '16px',
  },
  selectorColors,
  sizes,
  variants: {
    primary: {
      color: 'secondary',
      backgroundColor: 'cta',
      borderColor: 'cta',
    },
    secondary: {
      color: 'cta',
      backgroundColor: 'secondary',
      borderColor: 'cta',
    },
    secondaryGray: {
      color: 'text',
      backgroundColor: 'secondaryGray',
      borderColor: 'transparent',
      '&:hover, &:focus, &:focus:not(:focus-visible)': {
        backgroundColor: 'gray90',
        borderColor: 'transparent',
      },
    },
    transparent: {
      color: 'cta',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      'button&:hover': {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: `hover`,
      },
    },
  },
};
