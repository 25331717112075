import { ComponentProps } from 'react';
import styled from '@emotion/styled';
import { Box } from '../box';
import type { CustomRadioComponentProps } from './CustomRadioInput';

export type RadioTagProps = ComponentProps<typeof Box> &
  CustomRadioComponentProps;

export const RadioTag = styled(Box)<RadioTagProps>`
  color: ${({ theme }) => theme?.colors?.primary};
  border-style: solid;
  border-color: #b2b2b2;
  border-width: 1px;
  border-radius: 8px;
  cursor: pointer !important;
  padding: 5px 16px;

  &:hover {
    background: ${({ theme }) => theme?.colors?.success};
    border-color: ${({ theme }) => theme?.colors?.primary};
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    `
    background: ${theme?.colors?.success};
    border-color: transparent;
  `}
`;
