import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';
import styled from '@emotion/styled';
import { v4 as uuidv4 } from 'uuid';
import { createPortal } from 'react-dom';
import { AnimatePresence } from 'framer-motion';
import { Toast } from './Toast';
import {
  ToastProps,
  ToastState,
  ToastOptions,
  ToastPosition,
} from './Toast.types';
import { Flex } from '../flex';
import { useCreateToastPortalContainer } from './use-create-toast-portal-container';
import { isBrowser, objectKeys } from '../../utils';
import { styles } from './Toast.styles';

export interface ToastContextValue {
  toast: (values: ToastProps) => void;
}

interface NavProviderProps {
  children: ReactNode;
}

export const ToastContext = createContext<ToastContextValue>(
  {} as ToastContextValue
);

interface WrapperProps {
  toastPosition: ToastPosition;
}
const Wrapper = styled(Flex)<WrapperProps>`
  gap: ${({ theme }) => theme.space.xs}px;
  z-index: ${({ theme }) => theme.zIndices.notificationMed};
  ${({ toastPosition }) => {
    return { ...styles[toastPosition] };
  }}
`;

const initialToastState: ToastState = {
  top: [],
  'top-left': [],
  'top-right': [],
  'bottom-left': [],
  bottom: [],
  'bottom-right': [],
};

export const ToastProvider = ({ children }: NavProviderProps): ReactElement => {
  const { loaded, portalId } = useCreateToastPortalContainer();
  const [toasts, setToasts] = useState<ToastState>(initialToastState);

  /**
   * Delete a toast record at its position
   */
  const removeToast = ({
    id,
    position,
  }: {
    id: string;
    position: ToastPosition;
  }) => {
    setToasts((prevState) => ({
      ...prevState,
      [position]: prevState[position].filter((toast) => toast.id !== id),
    }));
  };

  /**
   * Create properties for a new toast
   */
  const createToast = ({
    description = '',
    title = '',
    duration = 4000,
    position = 'top-right',
    status = 'success',
    onCloseComplete = () => {},
    isClosable = true,
  }: ToastProps): ToastOptions => {
    const id = uuidv4();
    return {
      description,
      duration,
      position,
      status,
      onRequestRemove: () => removeToast({ id, position }),
      onCloseComplete,
      title,
      id,
      requestClose: false,
      isClosable,
    };
  };

  const toast = (options: ToastProps) => {
    const newToast = createToast(options);
    const { position } = newToast;
    const isTop = position.includes('top');

    /**
     * - If the toast is positioned at the top edges, the
     * recent toast stacks on top of the other toasts.
     *
     * - If the toast is positioned at the bottom edges, the recent
     * toast stacks below the other toasts.
     */

    setToasts((state) => ({
      ...state,
      [position]: isTop
        ? [newToast, ...state[position]]
        : [...state[position], newToast],
    }));
  };

  const toastContextValue: ToastContextValue = useMemo(
    () => ({
      toast,
    }),
    []
  );

  const domNode = isBrowser ? document.getElementById(portalId) : null;

  return (
    <ToastContext.Provider value={toastContextValue}>
      {children}

      {loaded && domNode ? (
        createPortal(
          <>
            {objectKeys(toasts).map((position = 'top-right') => {
              const positionToasts = toasts[position];
              return (
                <Wrapper
                  key={position}
                  id={`uds-toast-manager-${position}`}
                  flexDirection="column"
                  toastPosition={position}
                >
                  <AnimatePresence initial={false}>
                    {positionToasts.map((t: ToastOptions) => (
                      <Toast key={t.id} {...t} />
                    ))}
                  </AnimatePresence>
                </Wrapper>
              );
            })}
          </>,
          domNode
        )
      ) : (
        <></>
      )}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  return context.toast;
};
