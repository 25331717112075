import type { Theme } from '../types/theme-types';
import { avatarVariants } from './avatar-variants';
import { breakpoints, mediaQueries } from './breakpoints';
import { input } from './input';
import { colors } from './colors';
import * as typography from './typography';
import { textVariants } from './text-variants';
import { space } from './space';
import { button } from './button';
import { borderRadiuses } from './border-radiuses';
import { switchVariants } from './switch-variants';
import { pillControl } from './pill-control';
import { alertVariants } from './alert-variants';
import { badgeVariants } from './badge-variants';
import { zIndices } from './zIndices';

export const theme: Theme = {
  avatarVariants,
  badgeVariants,
  breakpoints,
  mediaQueries,
  colors,
  space,
  button,
  radii: borderRadiuses,
  input,
  textVariants,
  switchVariants,
  ...typography,
  pillControl,
  alertVariants,
  zIndices,
};
