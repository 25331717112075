import styled from '@emotion/styled';
import { Box, BoxProps } from '../box/Box';
import { getValueFromStyledLikeArray } from '../../utils/styled-helpers';

export interface GridLayoutColumnProps extends BoxProps {
  /**
   * The number of columns that the column spans. Default 1.
   */
  span?: string | (string | null)[];
  /**
   * The column that the column starts from, inclusive.
   */
  spanStart?: string | (string | null)[];
}

const getColumnSpan =
  (position: number) =>
  ({ span = '1', spanStart = '1' }: GridLayoutColumnProps): string => {
    if (typeof span !== 'string') {
      // eslint-disable-next-line no-param-reassign
      span = getValueFromStyledLikeArray(span, position) ?? '1';
    }

    if (typeof spanStart !== 'string') {
      // eslint-disable-next-line no-param-reassign
      spanStart = getValueFromStyledLikeArray(spanStart, position) ?? '1';
    }

    return `${spanStart} / span ${parseInt(span, 10)}`;
  };

/**
 * A child component which provides a column within a grid
 */
export const GridLayoutColumn = styled(Box)<GridLayoutColumnProps>`
  grid-column: ${getColumnSpan(0)};

  ${({ theme }) => theme.mediaQueries.s} {
    grid-column: ${getColumnSpan(1)};
  }
  ${({ theme }) => theme.mediaQueries.m} {
    grid-column: ${getColumnSpan(2)};
  }
  ${({ theme }) => theme.mediaQueries.l} {
    grid-column: ${getColumnSpan(3)};
  }
  ${({ theme }) => theme.mediaQueries.xl} {
    grid-column: ${getColumnSpan(4)};
  }
`;
