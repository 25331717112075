import { CSSProperties } from 'react';
import { ToastPosition } from './Toast.types';

type Styles = {
  [p in ToastPosition]: CSSProperties;
};
export const styles: Styles = {
  top: {
    position: 'fixed',
    margin: '0px auto',
    top: '10px',
    left: '50%',
    transform: ' translateX(-50%)',
  },
  'top-right': {
    position: 'fixed',
    top: '10px',
    right: '10px',
  },
  'top-left': {
    position: 'fixed',
    top: '10px',
    left: '10px',
  },
  bottom: {
    position: 'fixed',
    margin: '0px auto',
    bottom: '10px',
    left: '50%',
    transform: ' translateX(-50%)',
  },
  'bottom-left': {
    position: 'fixed',
    bottom: '10px',
    left: '10px',
  },
  'bottom-right': {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
  },
};
