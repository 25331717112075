import React from 'react';
import { border, BorderProps } from 'styled-system';
import styled from '@emotion/styled';
import { Palette } from '../../constants/palette';

export const CardBorderVariants = {
  dark: '#01314A',
  light: '#E5E5E5',
};

export enum CardPalette {
  gray90 = Palette.gray90,
  gray96 = Palette.gray96,
  white = Palette.white,
  blue = Palette.blue,
  updaterRed = Palette.updaterRed,
  hiYellow = Palette.hiYellow,
  softBlue = Palette.softBlue,
  goGreen = Palette.goGreen,
  petalPink = Palette.petalPink,
  sunTan = Palette.sunTan,
}

export interface CardProps extends BorderProps {
  /**
   * Controlling Card kinds.
   * Possible variants: rounded or squared.
   */
  variant?: 'rounded' | 'squared';

  /**
   * Responsible to set Card background color.
   */
  backgroundColor?: CardPalette;

  /**
   * Responsible to set Card border color.
   */
  borderColor?: 'dark' | 'light';

  children: React.ReactNode;
}

export const defaultCardBorder = (props: CardProps) =>
  `1px solid ${CardBorderVariants[props.borderColor || 'light']}`;

export const Card = styled.div<CardProps>`
  border-radius: ${(props) =>
    props.variant === 'rounded' ? props.theme.radii.m : props.theme.radii.xs};
  border: ${defaultCardBorder};
  background-color: ${(props) => props.backgroundColor};
  padding: 12px 16px;
  display: inline-block;

  ${border}
`;

Card.defaultProps = {
  variant: 'squared',
  backgroundColor: CardPalette.white,
};
