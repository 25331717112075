import React from 'react';
import styled from '@emotion/styled';
import {
  compose,
  display,
  margin,
  variant as systemVariant,
  DisplayProps,
  MarginProps,
} from 'styled-system';
import css, { SystemStyleObject } from '@styled-system/css';
import { CheckCircle, Warning, Info } from 'phosphor-react';
import { Box } from '../box';
import { Text } from '../text';
import { Flex } from '../flex';
import { AlertVariantKey } from '../../types/theme-types';

export interface AlertProps extends DisplayProps, MarginProps {
  /**
   * Alert message to be displayed.
   */
  message?: string;
  /**
   * Type of alert: 'info', 'error', 'success', or 'warning'
   * This changes styles such as background color, icon, etc.
   */
  variant?: AlertVariantKey;
  /**
   * Alert header to be displayed.
   */
  header?: string;
  /**
   * Alert icon to be displayed.
   * Only supported by alert type 'info'
   */
  icon?: boolean;
}

const baseCss = css({
  pt: '16px',
  pb: '16px',
} as SystemStyleObject);

interface AlertWrapperProps extends AlertProps {
  variant: AlertVariantKey;
}
const AlertWrapper = styled.div<AlertWrapperProps>`
  ${({ theme }) =>
    compose(
      display,
      margin,
      systemVariant({ variants: theme.alertVariants.variants })
    )}
  ${baseCss}
  flex-direction: row;
  align-items: center;
  border: 1px solid ${({ theme, variant }) =>
    theme?.alertVariants?.variants[variant].borderColor};
  & svg {
    margin: auto;
   
    polyline {
      stroke: ${({ theme, variant }) =>
        theme?.alertVariants?.variants[variant].borderColor}
  }

`;

const IconWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  width: 24px;
`;

type AlertIconMap = {
  [s in AlertVariantKey]: React.ReactElement;
};

const AlertIcon: AlertIconMap = {
  success: <CheckCircle size={24} />,
  error: <Warning size={24} />,
  warning: <Warning size={24} />,
  info: <Info size={24} />,
};

export const Alert: React.FC<AlertProps> = ({
  variant = 'success',
  message,
  header,
  icon = true,
  ...rest
}) => {
  if (!icon && variant !== 'info') {
    // eslint-disable-next-line no-console
    console.warn("icon='false' prop is only supported by variant='info' ");
  }
  return (
    <AlertWrapper variant={variant} display="flex" {...rest}>
      {icon || variant !== 'info' ? (
        <IconWrapper ml="m" borderRadius="50%">
          {AlertIcon[variant]}
        </IconWrapper>
      ) : (
        ''
      )}
      <Flex mr="m" ml="m" flexDirection="column">
        {header ? (
          <Text as="h3" variant="sBold">
            {header}
          </Text>
        ) : (
          ''
        )}
        <Text color="primary" variant="s">
          {message}
        </Text>
      </Flex>
    </AlertWrapper>
  );
};
