import { useWebview } from './webview';
import { window } from './window';

export type NativeWebProps = {
  safeArea?: boolean;
};

const DefaultProps: NativeWebProps = {
  safeArea: false,
};

const DefaultPropsForWebview: NativeWebProps = {
  safeArea: true,
};

/**
 * Provides native bridge injected props and platform defaults
 */
export function useNativeWebProps(): NativeWebProps;
export function useNativeWebProps<T>(factory: (props: NativeWebProps) => T): T;
export function useNativeWebProps<T>(factory?: (props: NativeWebProps) => T) {
  const webview = useWebview();
  const defaultProps = webview == null ? DefaultProps : DefaultPropsForWebview;
  const globalProps = window<NativeWebProps>('NativeBridge', 'props');

  const props = { ...defaultProps, ...globalProps };

  return factory == null ? props : factory(props);
}
