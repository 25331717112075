import React, { useState, useMemo } from 'react';
import DayPicker, { DayPickerInputProps, Modifier } from 'react-day-picker';
import dateFnsFormat from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';
import styled from '@emotion/styled';
import orderBy from 'lodash/orderBy';

import { CalendarBlank } from 'phosphor-react';
import { Palette } from '../../constants';

import { TextInputProps } from '../input';
import { DateInput } from '../date-input';
import { DatePickerNav } from './DatePickerNav';
import { Box } from '../box';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '../modal';

const DatePickerInputWrapper = styled(Box)`
  .DayPicker {
    min-height: 400px;
  }
  .DayPicker-wrapper {
    outline: none;
  }

  .DayPicker .DayPicker-Weekday,
  .DayPicker .DayPicker-Day {
    font-family: 'Work Sans', sans-serif;
  }

  //  ===== START DAY STYLING ====
  // :hover
  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    // Note: I don't like how it's called success here... Do something
    // Takes "success" color and reduces color to 30%
    background-color: ${({ theme }) => theme.colors.success}${Math.floor(0.3 * 255).toString(16)};
  }
  // selected
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: ${({ theme }) => theme.colors.success};
    color: ${({ theme }) => theme.colors.primary};
  }
  // selected + :hover
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  }
  // disabled
  .DayPicker-Day.DayPicker-Day--disabled:not(.DayPicker-Day--outside) {
    color: ${Palette.gray50} !important;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M0 99 L99 0 L100 1 L1 100' fill='black' /></svg>");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%, auto;
  }
  .DayPicker-Day.DayPicker-Day--past.DayPicker-Day--disabled {
    color: ${Palette.gray50} !important;
    text-decoration: none;
    background: none;
  }
  // available
  .DayPicker-Day {
    color: ${({ theme }) => theme.colors.primary} !important;
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    width: 40px;
    height: 40px;

    justify-content: center;
    align-items: center;
    border-radius: 100%;
    text-align: center;
    display: inline-flex;

    // large viewport+
    ${({ theme }) => theme.mediaQueries.l} {
      font-size: ${({ theme }) => theme.fontSizes.l}px;
      width: 60px;
      height: 60px;
    }
  }
  // today's date
  .DayPicker-Day--today {
    position: relative;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};

    // Dot under date
    &::before {
      content: '';
      position: absolute;
      bottom: ${({ theme }) => theme.space.xs}px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 50%;
      width: ${({ theme }) => theme.space.xxs}px;
      height: ${({ theme }) => theme.space.xxs}px;
      background-color: ${({ theme }) => theme.colors.primary} !important;
    }

    &.DayPicker-Day--outside {
      &::before {
        display: none;
      }
    }
  }
  // Today & disabled
  .DayPicker-Day--disabled.DayPicker-Day--today {
    &::before {
      background-color: ${Palette.gray50} !important;
    }
  }
  //  ===== END DAY STYLING ====

  //  ===== START WEEKDAY STYLING ====
  .DayPicker-WeekdaysRow {
    display: flex;
    justify-content: space-between;
    padding-right: ${({ theme }) => theme.space.xs}px;
    padding-left: ${({ theme }) => theme.space.xs}px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};

    ${({ theme }) => theme.mediaQueries.l} {
      padding-right: ${({ theme }) => theme.space.s}px;
      padding-left: ${({ theme }) => theme.space.s}px;
    }

    & > .DayPicker-Weekday {
      font-size: ${({ theme }) => theme.fontSizes.m}px;
      color: ${({ theme }) => theme.colors.primary} !important;
    }
  }
  //  ===== END WEEKDAY STYLING ====

  //  ===== START WEEK STYLING ====
  .DayPicker-Week {
    display: flex;
    justify-content: space-between;
  }
  //  ===== END WEEK STYLING ====

  //  ===== START MONTH STYLING ====
  .DayPicker-Months > .DayPicker-Month {
    flex: 1;
    ${({ theme }) => theme.mediaQueries.l} {
      min-height: 395px;
    }
  }
  //  ===== END MONTH STYLING ====
`;

export interface DatePickerInputProps extends Omit<TextInputProps, 'value'> {
  /**
   * We're mocking this until we can fire onChange synthetically.
   */
  onChange: (e: { target: { value: string } }) => void;
  /**
   * Disable all dates after.
   */
  minDate?: Date | string;
  /**
   * Disable all dates after.
   */
  maxDate?: Date | string;
  /**
   * Day to be selected.
   */
  value: DayPickerInputProps['value'];
  /**
   * Array of Modifiers or Date to disable.
   */
  disabledDates?: Date[];

  /**
   * The name of the date input
   */
  name?: string;
}

type DateRange = { before?: Date; after?: Date };

//  ===== START DATE PARSER LOGIC ====
const DATE_FORMAT = 'MM/dd/yyyy';

function formatDate(date: Date) {
  return dateFnsFormat(date, DATE_FORMAT);
}

function filterDateValue(
  value: DayPickerInputProps['value']
): Date | undefined {
  try {
    if (typeof value === 'undefined' || value === '') return undefined;
    // Convert to UTC String then to Date again to fix off-by-1 bug
    const dateInUTC = new Date(value).toLocaleString('en-US', {
      timeZone: 'UTC',
    });
    return new Date(dateInUTC);
  } catch (_) {
    return undefined;
  }
}
//  ===== END DATE PARSER LOGIC ====

const WEEKDAYS_SHORT = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

export const DatePickerInput: React.FC<DatePickerInputProps> = ({
  onChange,
  minDate = undefined,
  maxDate = undefined,
  value = '',
  disabledDates = [],
  label = 'Date',
  name = 'date',
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    filterDateValue(value)
  );
  const range: DateRange = {};
  const formattedMinDate = minDate ? startOfDay(new Date(minDate)) : undefined;
  const formattedMaxDate = maxDate ? startOfDay(new Date(maxDate)) : undefined;

  // Only assign range if min and max dates are provided
  if (formattedMinDate !== undefined) {
    range.before = formattedMinDate;
  }
  if (formattedMaxDate !== undefined) {
    range.after = formattedMaxDate;
  }

  const blockedDays = [range, ...disabledDates];

  // Dates that are before current date
  const customModifiers = {
    past: { before: new Date() },
  };

  // TODO: figure out how to pass in change event
  const handleOnChange = (day: Date) => {
    // NOTE: We're mocking this until we can fire onChange synthetically
    // event.target.value

    const mockEvent = {
      target: {
        value: startOfDay(day).toISOString(),
        name,
      },
    };
    onChange?.(mockEvent);
  };
  // Return empty string if date is undefined
  const inputValue = useMemo(() => {
    try {
      return typeof selectedDate !== 'undefined'
        ? formatDate(startOfDay(selectedDate as Date))
        : '';
    } catch (_) {
      return '';
    }
  }, [selectedDate]);

  const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Todo: Update DateInput to handle input editing
    // Open Modal only on spacebar and click events
    if ('key' in e) {
      setIsOpen(true);
    }
  };

  /**
   * Defines edges for start & end range. NOTE: This is separate from the disabled date logic.
   *
   * Ignores selectedDate changes.
   */
  const absoluteDateRanges = useMemo(() => {
    const potentialStartDates = [selectedDate, formattedMinDate].filter(
      (date) => !!date
    );
    const potentialEndDates = [selectedDate, formattedMaxDate].filter(
      (date) => !!date
    );
    return {
      start: orderBy(
        potentialStartDates,
        [(date) => date?.getTime()],
        ['asc']
      )[0],
      end: orderBy(potentialEndDates, [(date) => date?.getTime()], ['desc'])[0],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedMinDate, formattedMaxDate]);

  const getInitialMonth = () => {
    const currentDate = new Date();
    if (selectedDate) return selectedDate;

    const isCurrentDateAfterMinDate = formattedMinDate
      ? currentDate.getTime() > formattedMinDate?.getTime()
      : true;
    const isCurrentDateBeforeMaxDate = formattedMaxDate
      ? currentDate.getTime() < formattedMaxDate?.getTime()
      : true;

    if (isCurrentDateAfterMinDate && isCurrentDateBeforeMaxDate) {
      return currentDate;
    }

    if (formattedMinDate !== undefined) return range.before;
    return undefined;
  };

  return (
    <DatePickerInputWrapper>
      <DateInput
        readOnly
        rightSlot={
          <CalendarBlank
            role="img"
            aria-label="calendar-icon"
            onClick={() => setIsOpen(true)}
          />
        }
        label={label}
        // Disable typing for user
        value={inputValue}
        // Open modal if input is "touched"
        onMouseDown={(event) => {
          event.preventDefault();
          setIsOpen(true);
        }}
        onKeyPress={(event) => {
          event.preventDefault();
          handleInputKeyPress(event);
        }}
        name={name}
        {...props}
      />
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody flexDirection="column" marginBottom="none">
            <DayPicker
              modifiers={customModifiers}
              // Convert to date object if selectedDate is string
              selectedDays={selectedDate as Modifier}
              onDayClick={(day, modifiers) => {
                if (!modifiers?.disabled) {
                  const dayAtStart = startOfDay(day);
                  setSelectedDate(dayAtStart);
                  handleOnChange(dayAtStart);
                  setIsOpen(false);
                }
              }}
              onDayKeyDown={(day, modifiers, e) => {
                if (
                  !modifiers?.disabled &&
                  (e.key === 'Space' || e.key === 'Enter')
                ) {
                  const dayAtStart = startOfDay(day);
                  setSelectedDate(dayAtStart);
                  handleOnChange(dayAtStart);
                  if (e.key === 'Enter') setIsOpen(false);
                }
              }}
              fromMonth={absoluteDateRanges.start}
              toMonth={absoluteDateRanges.end}
              weekdaysShort={WEEKDAYS_SHORT}
              disabledDays={blockedDays as Modifier[]}
              // Prevent showing user disabled month
              initialMonth={getInitialMonth()}
              navbarElement={({
                month,
                showPreviousButton,
                showNextButton,
                onPreviousClick,
                onNextClick,
              }) => (
                <DatePickerNav
                  month={month}
                  canNavigateToPreviousMonth={showPreviousButton}
                  canNavigateToNextMonth={showNextButton}
                  onPreviousClick={onPreviousClick}
                  onNextClick={onNextClick}
                  onCloseClick={() => setIsOpen(false)}
                />
              )}
              captionElement={<></>}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </DatePickerInputWrapper>
  );
};
