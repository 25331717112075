import { Theme } from '../types/theme-types';

export const avatarVariants: Theme['avatarVariants'] = {
  s: {
    height: '32px',
    width: '32px',
  },
  m: {
    height: '64px',
    width: '64px',
  },
  l: {
    height: '128px',
    width: '128px',
  },
};
