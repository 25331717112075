import React, { ChangeEvent, ComponentProps } from 'react';
import InputMask from 'react-input-mask';
import { onMaskedStateChange } from '../../utils/react-input-mask-helpers';
import { Input } from '../input';

// capture doesn't align between InputMask and input. It's not a necessary prop
// so we can just omit it
export type PhoneNumberInputProps = Omit<
  ComponentProps<typeof Input>,
  'capture'
>;

/**
 * Unmasks a masked phone value
 * @param event
 * @returns tuple of raw entry an formatted value
 */
export const unmaskPhoneNumber = (maskedValue: string): string =>
  maskedValue.replace(/[^0-9.]/g, '');

/**
 * Phone Input with North American Standard Format ()
 */
export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  value,
  label,
  disabled,
  onChange,
  id,
  ...rest
}) => {
  /**
   * Will unmask and uplift the changed phone number value
   * @param event
   */
  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line no-param-reassign
    event.target.value = unmaskPhoneNumber(event.target.value);
    onChange?.(event);
  };

  const mask = '+1 (999) 999-9999';

  return (
    <InputMask
      mask={mask}
      onChange={onChangeInput}
      disabled={disabled}
      value={value}
      beforeMaskedStateChange={(state) =>
        onMaskedStateChange(state, mask, unmaskPhoneNumber)
      }
      {...rest}
    >
      <Input id={id} label={label} />
    </InputMask>
  );
};
