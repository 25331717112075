import React from 'react';

import styled from '@emotion/styled';
import { css } from '@emotion/react';

import {
  ArrowCounterClockwise,
  Check,
  DownloadSimple,
  Trash,
  Warning,
} from 'phosphor-react';
import { Box } from '../box';
import { Flex } from '../flex';
import { IconButton } from '../icon-button';
import { Palette } from '../../constants/palette';
import { Text } from '../text';

export interface UploadedFileProps {
  fileName?: string;
  onDeleteFile?: React.MouseEventHandler<HTMLElement>;
  onDownloadFile?: React.MouseEventHandler<HTMLElement>;
  onRetryUpload?: React.MouseEventHandler<HTMLElement>;
  variant?: 'success' | 'error';
}

const VariantProps: {
  [key: string]: {
    color: any;
    icon: React.RefAttributes<SVGSVGElement>;
    text: string;
  };
} = {
  error: {
    color: Palette.errorRed,
    icon: (
      <Warning
        color={Palette.white}
        data-testid="uploaded-file-error-icon"
        size={25}
      />
    ),
    text: 'Upload failed',
  },
  success: {
    color: Palette.goGreen,
    icon: (
      <Check
        color={Palette.white}
        data-testid="uploaded-file-success-icon"
        size={24}
      />
    ),
    text: 'Uploaded',
  },
};

const dynamicBackgroundColor = (props: { color: string }) => css`
  background-color: ${props.color};
`;

const iconWrapperSize: number = 40;

const IconWrapper = styled(Box)`
  ${dynamicBackgroundColor};
  align-items: center;
  display: flex;
  flex-direction: row;
  height: ${iconWrapperSize}px;
  justify-content: center;
  width: ${iconWrapperSize}px;
`;

export const UploadedFile = ({
  fileName, // TODO: add a regex check (preferably on the backend) for filename
  onDeleteFile,
  onDownloadFile,
  onRetryUpload,
  variant = 'success',
}: UploadedFileProps) => {
  return (
    <Flex
      alignItems="center"
      flexDirection="row"
      justifyContent="space-between"
    >
      <Flex>
        <IconWrapper borderRadius="50%" color={VariantProps[variant].color}>
          {VariantProps[variant].icon}
        </IconWrapper>
        <Box marginLeft="s">
          <Flex alignContent="center" flexDirection="column">
            <Box>
              <Text color={VariantProps[variant].color} variant="xsBold">
                {VariantProps[variant].text}
              </Text>
            </Box>
            <Box>
              <Text color={Palette.royalBlue} variant="s">
                {fileName}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Flex>
      <Box>
        <Flex>
          {variant === 'success'
            ? !!onDownloadFile && (
                <Box marginRight="xs">
                  <IconButton
                    data-testid="download-file-button"
                    dataCy="download-file-button"
                    icon={
                      <DownloadSimple size={24} color={Palette.royalBlue} />
                    }
                    onClick={onDownloadFile}
                    variant="secondaryGray"
                  />
                </Box>
              )
            : !!onRetryUpload && (
                <Box marginRight="xs">
                  <IconButton
                    data-testid="retry-upload-button"
                    dataCy="retry-upload-button"
                    icon={
                      <ArrowCounterClockwise
                        size={24}
                        color={Palette.royalBlue}
                      />
                    }
                    onClick={onRetryUpload}
                    variant="secondaryGray"
                  />
                </Box>
              )}
          {!!onDeleteFile && (
            <Box>
              <IconButton
                data-testid="delete-file-button"
                dataCy="delete-file-button"
                icon={<Trash size={24} color={Palette.royalBlue} />}
                onClick={onDeleteFile}
                variant="secondaryGray"
              />
            </Box>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};
