import { PillControl, PillControlProps } from '../pill-control';
import { Radio } from '../radio';

interface RadioPillProps extends PillControlProps {}

export const RadioPill: React.FC<RadioPillProps> = (props) => (
  <PillControl {...props}>
    <Radio {...props} />
  </PillControl>
);
