import React, { useMemo, FC, isValidElement } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { IconProps, IconContext } from 'phosphor-react';
import { Button, ButtonProps } from '../button';

type PartialButtonProps = Omit<
  ButtonProps,
  'leftIcon' | 'rightIcon' | 'isFluidWidth'
>;
export interface IconButtonProps extends PartialButtonProps {
  /**
   * Icon that will appear in the center of the Button
   */
  icon?: React.ReactElement<IconProps>;
}

const buttonStyleOverrides = {
  borderRadius: '50%',
  height: '100%',
  padding: '0',
};

const IconWrapper = styled.div<IconButtonProps>`
  display: inline-flex;
  text-decoration: none;
  width: ${({ size = 'm', theme }) => theme.button.sizes[size]};
  height: ${({ size = 'm', theme }) => theme.button.sizes[size]};
  /* 
  * Utility class to hide content visually while keeping it screen reader-accessible.
  * Source: https://www.scottohara.me/blog/2017/04/14/inclusively-hidden.html 
  */
  .visually-hidden:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;

export const IconButton: FC<IconButtonProps> = ({
  icon,
  variant = 'primary',
  disabled,
  size = 'm',
  'aria-label': ariaLabel = 'icon button',
  dataCy,
  type = 'button',
  ...rest
}) => {
  const theme = useTheme();

  // only render react elements i.e. prevent user from adding plain text
  const children = isValidElement(icon) ? icon : null;

  const iconContext: IconProps = useMemo(
    () => ({
      size:
        size === 's'
          ? `calc(${theme.button.sizes.s} / 2)`
          : `calc(${theme.button.sizes.m} / 2)`,
      focusable: false,
    }),
    [size]
  );

  return (
    <IconWrapper size={size}>
      <Button
        {...rest}
        style={buttonStyleOverrides}
        variant={variant}
        isFluidWidth
        disabled={disabled}
        size={size}
        dataCy={dataCy}
        type={type}
      >
        <IconContext.Provider value={iconContext}>
          {children}
        </IconContext.Provider>
        <span className="visually-hidden">{ariaLabel}</span>
      </Button>
    </IconWrapper>
  );
};
