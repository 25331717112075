import styled from '@emotion/styled';
import {
  compose,
  display,
  color,
  space,
  variant,
  gridArea,
  textAlign,
  DisplayProps,
  ColorProps,
  SpaceProps,
  GridAreaProps,
} from 'styled-system';
import css from '@styled-system/css';
import {
  TextVariantKey,
  TextTags,
  TextAlignProps,
} from '../../types/theme-types';

export interface TextProps
  extends DisplayProps,
    ColorProps,
    SpaceProps,
    GridAreaProps,
    TextAlignProps {
  /**
   * Controlling font size, line height and font weight.
   * Possible variants: xxxxl, xxxxlBold, xxxl, xxxlBold, xxl, xxlBold, xl,
   * xlBold, l, lBold, m, mBold, s, sBold, xs, xsBold, xxs, xxsBold.
   */
  variant?: TextVariantKey | Array<TextVariantKey>;
  /**
   * Tag type, currently support h1, h2, ..., h6, span, label and p.
   */
  as?: TextTags;
  /**
   * Content for this component.
   */
  children?: React.ReactNode | string | null;
  /**
   * Prevents errors for when using the Text component as labels and allows
   * for form components to reuse styling and variants as defined here.
   */
  htmlFor?: string;
}

const baseCss = css({
  fontFamily: 'body',
  m: 0,
});

export const Text = styled.p<TextProps>`
  ${baseCss}

  ${({ theme }) =>
    compose(
      display,
      color,
      space,
      textAlign,
      gridArea,
      variant({
        variants: theme.textVariants,
      })
    )}
`;

Text.defaultProps = {
  variant: 'default',
  color: 'text',
};

export const H1 = styled(Text)``;
H1.defaultProps = {
  as: 'h1',
  variant: 'xl',
  color: 'text',
};

export const H2 = styled(Text)``;
H2.defaultProps = {
  as: 'h2',
  color: 'text',
};
export const H3 = styled(Text)``;
H3.defaultProps = {
  as: 'h3',
  color: 'text',
};
export const H4 = styled(Text)``;
H4.defaultProps = {
  as: 'h4',
  color: 'text',
};
export const H5 = styled(Text)``;
H5.defaultProps = {
  as: 'h5',
  color: 'text',
};
export const H6 = styled(Text)``;
H6.defaultProps = {
  as: 'h6',
  color: 'text',
};

export const P = styled(Text)``;
P.defaultProps = {
  as: 'p',
  color: 'text',
};

// export interface SemanticTextTagProps {
//   /**
//    * Controlling font size, line height and font weight.
//    * Possible variants: xxxxl, xxxxlBold, xxxl, xxxlBold, xxl, xxlBold, xl,
//    * xlBold, l, lBold, m, mBold, s, sBold, xs, xsBold, xxs, xxsBold.
//    */
//   variant: TypographyVariantKey;

//   children?: string | null;
// }

// export function H1({ variant, children }: SemanticTextTagProps) {
//   return (
//     <Text as="h1" variant={variant}>
//       {children}
//     </Text>
//   );
// }
// export function H2({ variant, children }: SemanticTextTagProps) {
//   return (
//     <Text as="h2" variant={variant}>
//       {children}
//     </Text>
//   );
// }
// export function H3({ variant, children }: SemanticTextTagProps) {
//   return (
//     <Text as="h3" variant={variant}>
//       {children}
//     </Text>
//   );
// }
// export function H4({ variant, children }: SemanticTextTagProps) {
//   return (
//     <Text as="h4" variant={variant}>
//       {children}
//     </Text>
//   );
// }
// export function H5({ variant, children }: SemanticTextTagProps) {
//   return (
//     <Text as="h5" variant={variant}>
//       {children}
//     </Text>
//   );
// }
// export function H6({ variant, children }: SemanticTextTagProps) {
//   return (
//     <Text as="h6" variant={variant}>
//       {children}
//     </Text>
//   );
// }
// export function P({ variant, children }: SemanticTextTagProps) {
//   return (
//     <Text as="p" variant={variant}>
//       {children}
//     </Text>
//   );
// }
