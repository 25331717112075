import styled from '@emotion/styled';
import React from 'react';
import { Box } from '../box';
import { Error } from '../error';
import { Flex } from '../flex';
import { SwitchProps, Switch } from '../switch';
import { Text } from '../text';

export interface LabeledSwitchProps extends SwitchProps {
  /**
   * The label of the switch
   * @example
   * label='Email notifications'
   */
  label: string;

  /**
   * Switch Description
   * @example
   * label='Notify provided email for all updates'
   */
  description: string;

  /**
   * The error message of the switch.
   * @example
   * error = "Unable to save preferences, please try again later."
   */
  error?: string;
}

const LabeledSwitchTag = styled.div`
  padding: 1rem 0;
`;

const LeftContent = styled.div`
  flex: 1;
  padding-right: 2rem;
`;

export const LabeledSwitch: React.FC<LabeledSwitchProps> = ({
  label,
  description,
  error,
  id,
  ...props
}) => (
  <LabeledSwitchTag>
    <Flex>
      <LeftContent>
        <Text as="label" variant="lBold" htmlFor={id} id={`${id}Label`}>
          {label}
        </Text>
        <Text variant="m" id={`${id}Description`}>
          {description}
        </Text>
      </LeftContent>
      <Switch
        {...props}
        id={id}
        aria-labelledby={`${id}Label ${id}Description`}
      />
    </Flex>
    {error && (
      <Box minHeight="20px">
        {error && (
          <Error as="span" variant="xs">
            {error}
          </Error>
        )}
      </Box>
    )}
  </LabeledSwitchTag>
);
