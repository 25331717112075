import { useState, useEffect } from 'react';
import { isBrowser } from '../../utils';

const portalId = 'uds-toast-portal';

export const useCreateToastPortalContainer = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!isBrowser) throw Error("Can' create DOM element");
    let portal: HTMLElement;

    const existingPortal = document.getElementById(portalId);

    if (existingPortal) {
      portal = existingPortal;
    } else {
      const div = document.createElement('div');
      div.id = portalId;
      document.getElementsByTagName('body')[0].prepend(div);
      portal = div;
      setLoaded(true);
    }

    return () => {
      document.getElementsByTagName('body')[0].removeChild(portal);
    };
  }, []);

  return { loaded, portalId };
};
