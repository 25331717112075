import { Theme } from '../types/theme-types';

export const breakpoints: Theme['breakpoints'] = [
  // xs
  '375px', // s
  '768px', // m
  '1024px', // l
  '1440px', // xl
];

export const mediaQueries: Theme['mediaQueries'] = {
  xs: `@media only screen and (max-width: calc(${breakpoints[0]} - 1px))`,
  s: `@media only screen and (min-width: ${breakpoints[0]})`,
  m: `@media only screen and (min-width: ${breakpoints[1]})`,
  l: `@media only screen and (min-width: ${breakpoints[2]})`,
  xl: `@media only screen and (min-width: ${breakpoints[3]})`,
};
