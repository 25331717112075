import { Theme } from '../types/theme-types';

export const textVariants: Theme['textVariants'] = {
  default: {
    fontSize: 'xl',
    fontWeight: 'regular',
    lineHeight: 'xl',
  },
  inherit: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
  },
  megaBold: {
    fontSize: 'mega',
    fontWeight: 'semiBold',
    lineHeight: 'mega',
    fontFeatureSettings: "'ss01' on, 'ss02' on",
    letterSpacing: '-0.02rem',
  },
  mega: {
    fontSize: 'mega',
    fontWeight: 'regular',
    lineHeight: 'mega',
  },
  xxxxlBold: {
    fontSize: 'xxxxl',
    fontWeight: 'semiBold',
    letterSpacing: '-0.02rem',
    fontFeatureSettings: "'ss06' on",
    lineHeight: 'xxxxl',
  },
  xxxxl: {
    fontSize: 'xxxxl',
    fontWeight: 'regular',
    lineHeight: 'xxxxl',
  },
  xxxxlLight: {
    fontSize: 'xxxxl',
    fontWeight: 'light',
    lineHeight: 'xxxxl',
  },
  xxxlBold: {
    fontSize: 'xxxl',
    fontWeight: 'semiBold',
    letterSpacing: '-0.02rem',
    lineHeight: 'xxxl',
  },
  xxxl: {
    fontSize: 'xxxl',
    fontWeight: 'regular',
    lineHeight: 'xxxl',
  },
  xxxlLight: {
    fontSize: 'xxxl',
    fontWeight: 'light',
    lineHeight: 'xxxl',
  },
  xxlBold: {
    fontSize: 'xxl',
    fontWeight: 'semiBold',
    letterSpacing: '-0.02rem',
    lineHeight: 'xxl',
  },
  xxl: {
    fontSize: 'xxl',
    fontWeight: 'regular',
    lineHeight: 'xxl',
  },
  xxlLight: {
    fontSize: 'xxl',
    fontWeight: 'light',
    lineHeight: 'xxl',
  },
  xlBold: {
    fontSize: 'xl',
    fontWeight: 'semiBold',
    letterSpacing: '-0.02rem',
    lineHeight: 'xl',
  },
  xl: {
    fontSize: 'xl',
    fontWeight: 'regular',
    lineHeight: 'xl',
  },
  xlLight: {
    fontSize: 'xl',
    fontWeight: 'light',
    lineHeight: 'xl',
  },
  lBold: {
    fontSize: 'l',
    fontWeight: 'semiBold',
    lineHeight: 'l',
  },
  l: {
    fontSize: 'l',
    fontWeight: 'regular',
    lineHeight: 'l',
  },
  lLight: {
    fontSize: 'l',
    fontWeight: 'light',
    lineHeight: 'l',
  },
  mBold: {
    fontSize: 'm',
    fontWeight: 'semiBold',
    lineHeight: 'm',
  },
  m: {
    fontSize: 'm',
    fontWeight: 'regular',
    lineHeight: 'm',
  },
  sBold: {
    fontSize: 's',
    fontWeight: 'semiBold',
    lineHeight: 's',
  },
  s: {
    fontSize: 's',
    fontWeight: 'regular',
    lineHeight: 's',
  },
  xsBold: {
    fontSize: 'xs',
    fontWeight: 'semiBold',
    lineHeight: 'xs',
  },
  xs: {
    fontSize: 'xs',
    fontWeight: 'regular',
    lineHeight: 'xs',
  },
  xxsBold: {
    fontSize: 'xxs',
    fontWeight: 'semiBold',
    lineHeight: 'xxs',
  },
  xxs: {
    fontSize: 'xxs',
    fontWeight: 'regular',
    lineHeight: 'xxs',
  },
};
