import { Theme } from '../types/theme-types';

export const zIndices: Theme['zIndices'] = {
  background: 0,
  content: 100,
  interaction: 200,
  navigation: 300,
  notificationLow: 400,
  notificationMed: 450,
  notificationHigh: 475,
};
