/**
 * Return the first non null value in the array before position.
 * Mimic's Styled System's array behavior.
 */
export function getValueFromStyledLikeArray<T>(
  styledLikeArray: (T | null)[],
  position: number
): T | undefined {
  const lastPositionToCheck = Math.min(position, styledLikeArray.length - 1);
  return (
    styledLikeArray
      .slice(0, lastPositionToCheck + 1)
      .reverse()
      .find((x) => x !== null) || undefined
  );
}
