import { ComponentProps } from 'react';
import styled from '@emotion/styled';
import { Card } from '../card';
import type { CustomRadioComponentProps } from './CustomRadioInput';

type RadioCardProps = ComponentProps<typeof Card> & CustomRadioComponentProps;

export const RadioCard = styled(Card)<RadioCardProps>`
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  border: unset;
  border-top: 8px solid ${({ theme }) => theme?.colors?.primary};
  cursor: pointer !important;
  padding: 24px;
  position: relative;
  overflow: hidden;

  box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.colors.gray90};

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.colors.primary};
    }
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    `box-shadow: 0px 0px 0px 2px ${theme.colors.primary} !important;`}
`;
