import { ComponentProps, FC } from 'react';
import styled from '@emotion/styled';
import { Flex } from '../flex';
import { RadioGroupContext } from './RadioGroupContext';
import { UseRadioGroupOptions, useRadioGroup } from './useRadioGroup';
import { RadioCard } from './RadioCard';
import { CustomRadioInput, CustomRadioInputProps } from './CustomRadioInput';
import { RadioTag } from './RadioTag';

export type RadioOption = {
  value: string;
  disabled?: boolean;
};

type RadioGroupProps = Omit<ComponentProps<typeof Flex>, 'onChange'> &
  UseRadioGroupOptions;

const StyledFieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
`;

export const RadioGroup: FC<RadioGroupProps> & {
  Card: FC<Omit<CustomRadioInputProps, 'Component'>>;
  Tag: FC<Omit<CustomRadioInputProps, 'Component'>>;
} = (props) => {
  const { children, ...rest } = props;
  const context = useRadioGroup(rest);

  return (
    <RadioGroupContext.Provider value={context}>
      <StyledFieldset role="radiogroup" disabled={context.disabled}>
        {children}
      </StyledFieldset>
    </RadioGroupContext.Provider>
  );
};

RadioGroup.Card = (props) => (
  <CustomRadioInput {...props} Component={RadioCard} />
);

RadioGroup.Tag = (props) => (
  <CustomRadioInput {...props} Component={RadioTag} />
);
