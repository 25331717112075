import { Eye, EyeSlash } from 'phosphor-react';
import { ComponentProps, FC, useMemo, useState } from 'react';
import { Input } from '../input';
import { IconButton } from '../icon-button';

export interface SecurityInputProps extends ComponentProps<typeof Input> {}

export const SecurityInput: FC<SecurityInputProps> = (props) => {
  const { type } = props;
  const [isHidden, setIsHidden] = useState<boolean>(true);

  const rightSlot = useMemo(() => {
    if (isHidden) {
      return (
        <IconButton
          type="button"
          onClick={() => setIsHidden(false)}
          icon={<EyeSlash />}
          variant="transparent"
        />
      );
    }

    return (
      <IconButton
        type="button"
        onClick={() => setIsHidden(true)}
        icon={<Eye />}
        variant="transparent"
      />
    );
  }, [isHidden]);

  return (
    <Input
      {...props}
      type={isHidden ? 'password' : type}
      rightSlot={rightSlot}
    />
  );
};
