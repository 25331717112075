import styled from '@emotion/styled';
import { Text, TextProps } from '../text';

export interface HelperProps extends TextProps {}

/**
 * Component that is used as a helper text for form fields.
 */

export const Helper = styled(Text)<HelperProps>`
  color: ${(props) => props.theme.input.helperColor};
`;

Helper.defaultProps = {
  as: 'span',
  variant: 'xs',
};
