import React from 'react';
import styled from '@emotion/styled';
import { ControlProps } from '../../types/control-types';

type SwitchSize = 's' | 'm' | 'l';
type SwitchColor = 'green' | 'blue' | 'white';

interface SwitchTagProps {
  elementSize: SwitchSize;
  color: SwitchColor;
}

export interface SwitchProps extends ControlProps {
  /**
   * Changes the color of the switch.
   */
  color?: SwitchColor;

  /**
   * Changes the size of the switch.
   */
  size?: SwitchSize;
}

const SwitchTag = styled.input<SwitchTagProps>`
  height: ${({ theme, elementSize }) =>
    theme.switchVariants.sizes[elementSize].height};
  width: ${({ theme, elementSize }) =>
    theme.switchVariants.sizes[elementSize].width};
  flex-shrink: 0;
  appearance: none;
  cursor: pointer;
  border-radius: ${({ theme, elementSize }) =>
    theme.switchVariants.sizes[elementSize].width};
  position: relative;
  border-color: ${({ theme }) => theme.colors.switchBackground};
  background-color: ${({ theme }) => theme.colors.switchBackground};
  transition-property: background-color, border-color;
  transition-duration: 0.2s;
  transition-timing-function: ease;

  &:focus {
    outline: none;
  }

  &:after {
    width: ${({ theme, elementSize }) =>
      theme.switchVariants.sizes[elementSize].buttonSize};
    height: ${({ theme, elementSize }) =>
      theme.switchVariants.sizes[elementSize].buttonSize};
    border-radius: ${({ theme, elementSize }) =>
      theme.switchVariants.sizes[elementSize].width};
    content: '';
    background-color: ${({ theme }) => theme.colors.switchButton};
    display: block;
    position: absolute;
    left: ${({ theme, elementSize }) =>
      theme.switchVariants.sizes[elementSize].padding};
    top: ${({ theme, elementSize }) =>
      theme.switchVariants.sizes[elementSize].padding};
    transition-property: left, background-color, border-color;
    transition-duration: 0.2s;
    transition-timing-function: ease;
  }

  &:checked {
    background-color: ${({ theme, color }) =>
      theme.switchVariants.colorSchemes[color].background};
    border-color: ${({ theme }) => theme.colors.secondary};

    &:after {
      left: 45%;
      background-color: ${({ theme, color }) =>
        theme.switchVariants.colorSchemes[color].slider};
    }
  }

  &:disabled {
    border-color: ${({ theme }) => theme.colors.disabled};
    background-color: ${({ theme }) => theme.colors.disabled};
    cursor: default;

    &:after {
      background-color: ${({ theme }) => theme.colors.switchButton};
    }
  }

  &:focus {
    outline: 1px solid
      ${({ theme, color }) => theme.switchVariants.colorSchemes[color].outline};
  }
`;

export const Switch: React.FC<SwitchProps> = ({
  size = 's',
  color = 'green',
  ...rest
}) => <SwitchTag type="checkbox" color={color} elementSize={size} {...rest} />;
