import styled from '@emotion/styled';
import { grid } from 'styled-system';
import { Grid, GridProps } from '../grid/Grid';
import { getValueFromStyledLikeArray } from '../../utils/styled-helpers';

export interface GridLayoutProps extends GridProps {
  /**
   * The number of columns. If provided as a string that will be the number
   * of columns for all viewports. If an array is provided the standard
   * media query behavior applies i.e. [default, s, m, l, xl].
   */
  columns?: string | (string | null)[];

  /**
   * If the margin defined by design should be included.
   * This margin is 16px xs > m and 60px at l and xl
   * default: true
   */
  includeMargin?: boolean;
}

const defaultColumns = ['4', '4', '4', '12', '12'];
const getColumnNumber =
  (position: number) =>
  ({ columns = defaultColumns }: GridLayoutProps): string => {
    if (typeof columns === 'string') {
      return columns;
    }
    return getValueFromStyledLikeArray(columns, position) ?? '4';
  };

/**
 * A layout component that lets you apply a range
 * of grid properties.
 *
 * By default 4 columns are displayed at xs, s, and m. 12 columns are
 * rendered at l and xl. Column gaps are 16px with 4 columns and 24px with
 * 12.
 */
export const GridLayout = styled(Grid)<GridLayoutProps>`
  grid-template-columns: repeat(${getColumnNumber(0)}, 1fr);
  grid-auto-flow: row;
  display: grid;
  max-width: 100%;

  column-gap: 16px;
  margin: ${({ includeMargin = true }) => (includeMargin ? '16px' : 0)};

  ${({ theme }) => theme.mediaQueries.s} {
    grid-template-columns: repeat(${getColumnNumber(1)}, 1fr);
  }
  ${({ theme }) => theme.mediaQueries.m} {
    grid-template-columns: repeat(${getColumnNumber(2)}, 1fr);
  }
  ${({ theme }) => theme.mediaQueries.l} {
    grid-template-columns: repeat(${getColumnNumber(3)}, 1fr);
    column-gap: 24px;
    margin: ${({ includeMargin = true }) => (includeMargin ? '60px' : 0)};
  }
  ${({ theme }) => theme.mediaQueries.xl} {
    grid-template-columns: repeat(${getColumnNumber(4)}, 1fr);
  }

  ${grid}
`;
