import React from 'react';
import { ArrowLeft, ArrowRight, X } from 'phosphor-react';
import { Box } from '../box';
import { Grid } from '../grid';
import { Text } from '../text';
import { IconButton } from '../icon-button';

interface DatePickerNavProps {
  month: Date;
  canNavigateToPreviousMonth?: boolean;
  canNavigateToNextMonth?: boolean;
  onPreviousClick: () => void;
  onNextClick: () => void;
  onCloseClick: () => void;
}

export const DatePickerNav: React.FC<DatePickerNavProps> = ({
  month,
  canNavigateToPreviousMonth,
  canNavigateToNextMonth,
  onPreviousClick,
  onNextClick,
  onCloseClick,
}) => {
  const currentMonthString = month.toLocaleString('default', {
    month: 'long',
  });
  const currentYearString = month.toLocaleString('default', {
    year: 'numeric',
  });

  return (
    <Grid
      paddingTop="m"
      paddingBottom="none"
      gridRowGap="m"
      gridTemplateAreas={`"actionText close"
    "currentMonth navButtons"`}
    >
      {/* Top */}
      <Text gridArea="actionText" as="h3" variant="lBold">
        Select a date
      </Text>
      {/* Note: Button with no styling that looks like text */}
      <Box
        cursor="pointer"
        border="none"
        backgroundColor="transparent"
        as="button"
        justifySelf="end"
        gridArea="close"
        onClick={() => onCloseClick()}
      >
        <X size={24} />
      </Box>
      {/* Bottom */}
      <Text
        gridArea="currentMonth"
        marginY="auto"
        as="span"
        role="heading"
        variant="m"
        aria-live="polite"
      >
        {currentMonthString} {currentYearString}
      </Text>
      <Grid
        gridColumnGap="xs"
        gridTemplateColumns="1fr 1fr"
        justifySelf="end"
        gridArea="navButtons"
      >
        <IconButton
          disabled={!canNavigateToPreviousMonth}
          size="s"
          type="button"
          variant="secondary"
          icon={<ArrowLeft />}
          onClick={() => onPreviousClick()}
        />
        <IconButton
          size="s"
          type="button"
          disabled={!canNavigateToNextMonth}
          variant="secondary"
          icon={<ArrowRight />}
          onClick={() => onNextClick()}
        />
      </Grid>
    </Grid>
  );
};
