import { useViewportSizes } from '.';
import { getValueFromStyledLikeArray } from '../utils/styled-helpers';

export function useStyledLikeArray<T>(styledLikeArray: T | (T | null)[]) {
  const { isSmall, isMedium, isLarge, isExtraLarge } = useViewportSizes();

  if (!Array.isArray(styledLikeArray)) {
    return styledLikeArray;
  }

  let arrayKey = 0;
  if (isSmall) {
    arrayKey = 1;
  } else if (isMedium) {
    arrayKey = 2;
  } else if (isLarge) {
    arrayKey = 3;
  } else if (isExtraLarge) {
    arrayKey = 4;
  }

  return getValueFromStyledLikeArray(styledLikeArray, arrayKey);
}
