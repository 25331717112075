import React, { ReactNode, Ref, useMemo } from 'react';
import styled from '@emotion/styled';
import { css as emotionCSS } from '@emotion/react';
import { space } from 'styled-system';
import css, { SystemStyleObject } from '@styled-system/css';
import { Box } from '../box';
import { SpaceProps, ColorProps, Theme } from '../../types/theme-types';
import { Error } from '../error';
import { Helper } from '../helper';

export interface InputProps {
  /**
   * The label of the Input.
   * @example
   * label = "Username"
   */
  label: string;
  /**
   * The error message of the input.
   * @example
   * error = "Something went wrong"
   */
  error?: string;
  /**
   * The helper of the Input.
   * @example
   * helper = "This is a helper text"
   */
  helper?: string;
  /**
   * The left slot of the Input.
   * @example
   * leftSlot = {<Icon></Icon>}
   */
  leftSlot?: ReactNode;
  /**
   * The left slot of the Input.
   * @example
   * rightSLot = {<a href="google.com" target={"_blank"} >Learn more</a>}
   */
  rightSlot?: ReactNode;
  /**
   * If `true`, the element will be disabled.
   * It will set the `disabled` HTML attribute
   * @example
   * disabled
   */
  disabled?: boolean;
  inputRef?: Ref<HTMLInputElement> | undefined;
  isDropDownOpen?: boolean;
  /**
   * Input will contain data name for locating the input.
   */
  dataCy?: string;
}

type Omitted = 'size' | 'prefix';
export interface TextInputProps
  extends InputProps,
    Omit<React.InputHTMLAttributes<HTMLInputElement>, Omitted> {}

type HelperColorProps = Pick<InputProps, 'disabled' | 'error'> & {
  theme: Theme;
};

const getWrapperBorderColor = ({
  theme,
  disabled,
  error,
}: HelperColorProps) => {
  if (disabled) return theme.input.disabledColor;
  if (error) return theme.input.errorColor;
  return theme.input.borderColor;
};

const getWrapperFocusedBorderColor = ({
  theme,
  disabled,
  error,
}: HelperColorProps) => {
  if (disabled) return theme.input.disabledColor;
  if (error) return theme.input.errorColor;
  return theme.input.borderFocusColor;
};

const getWrapperHoveredBorderColor = ({
  theme,
  disabled,
  error,
}: HelperColorProps) => {
  if (disabled) return theme.input.disabledColor;
  if (error) return theme.input.errorColor;
  return theme.input.borderHoverColor;
};

type WrapperProps = Pick<InputProps, 'disabled' | 'error'> &
  SpaceProps &
  ColorProps & { isDropDownOpen: boolean };

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => getWrapperBorderColor(props)};
  border-radius: 8px;
  height: 64px;
  background-color: ${(props) => props.theme.input.backgroundColor};
  transition: box-shadow 300ms;
  &:focus-within {
    border-color: ${(props) => getWrapperFocusedBorderColor(props)};
    box-shadow: 0px 4px 4px rgba(117, 117, 117, 0.25);
  }

  &:hover {
    border-color: ${(props) => getWrapperHoveredBorderColor(props)};
  }

  ${({ isDropDownOpen, theme }) =>
    isDropDownOpen &&
    emotionCSS`
    border-color: ${theme.input.borderHoverColor};
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: 1px solid transparent;
    z-index: ${theme.zIndices.interaction};
    &:hover {
      border-color: ${theme.input.borderHoverColor};
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom: 1px solid transparent;
    }
    &:focus-within {
      border-color: ${theme.input.borderHoverColor};
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom: 1px solid transparent;
    }
    `}
  ${space};
`;

const getFocusedLabelColor = ({ disabled, error, theme }: HelperColorProps) => {
  if (disabled) return theme.input.disabledColor;
  if (error) return theme.input.errorColor;
  return theme.input.focusedColor;
};

const getLabelColor = ({ disabled, error, theme }: HelperColorProps) => {
  if (disabled) return theme.input.disabledColor;
  if (error) return theme.input.errorColor;
  return theme.input.labelColor;
};

type LabelProps = Pick<InputProps, 'disabled' | 'error'>;

/**
 * The default placeholder (`placeholder="&nbsp;"`) is used to inform the css when there's a value in the input.
 * For inputs that have actual placeholders we need to treat it as if they've always
 * got either a placeholder or a value so that the label shows up in the top left.
 */
const LabelBaseStyles = css({
  fontSize: 'm',
  fontWeight: 'regular',
  'input:focus-within ~ &, input:focus-within:not(:placeholder-shown) ~ &': {
    fontSize: 'xs',
    fontWeight: 'semiBold',
  },
  'input:-webkit-autofill:not(:focus-within) ~ &, input:focus:not(:focus-within) ~ &, input:not(:focus-within):not(:placeholder-shown) ~ &':
    {
      fontSize: 'xs',
      fontWeight: 'regular',
    },
  /** inputs with placeholders have labels which are always on top left, e.g. `DateInput`
   * those inputs use class `input-with-placeholder` on the Input */
  'input.input-with-placeholder ~ &': {
    fontSize: 'xs',
    transform: `translate3d(0, -10px, 0)`,
  },
  whiteSpace: 'nowrap',
} as SystemStyleObject);

const Label = styled.label<LabelProps>`
  ${LabelBaseStyles}
  color: ${(props) => getLabelColor(props)};
  font-family: 'Work Sans', sans-serif;
  position: absolute;
  pointer-events: none;
  top: calc(64px / 3);
  transition: transform 0.1s ease-in-out;
  input:-webkit-autofill ~ &, input:focus-within ~ &, input:not(:placeholder-shown) ~ & {
    transform: translate3d(0, -10px, 0);
    color: ${(props) => getFocusedLabelColor(props)};
  },
  input:not(:focus-within) ~ &, input:not(:focus-within):not(:placeholder-shown) ~ & {
    color: ${(props) => getLabelColor(props)};
  },
`;

const InputWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  width: 100%;
  height: 100%;
`;

const StyledInputBaseStyles = css({
  fontSize: 'm',
  fontWeight: 'regular',
  lineHeight: 'm',
} as SystemStyleObject);

const StyledInput = styled.input`
  ${StyledInputBaseStyles}
  display: block;
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.input.color};
  background-color: inherit;
  border: none;
  outline: none;

  /*
    Add specificity since there is some competition for padding properties.
  */
  &,
  &[type='text'],
  &[type='tel'],
  &[type='password'],
  &[type='email'] {
    padding: 0;
    padding-top: calc(64px / 3);
  }

  box-sizing: border-box;
  font-family: 'Work Sans', sans-serif;

  /* Change text in autofill textbox*/
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    background-color: white !important;
    background-clip: content-box !important;
  }

  &::placeholder {
    color: ${({ theme }) => theme.input.labelColor};
  }

  &:disabled {
    color: ${({ theme }) => theme.input.disabledColor};
  }
`;

const Slot = styled.div<SpaceProps>`
  white-space: nowrap;
  vertical-align: middle;
  ${space}
`;

export const Input: React.FC<TextInputProps> = ({
  label,
  error,
  helper,
  leftSlot,
  rightSlot,
  disabled,
  inputRef,
  isDropDownOpen = false,
  dataCy,
  ...props
}) => {
  const cyName = useMemo(() => {
    if (dataCy) {
      return dataCy;
    }
    if (props.id) {
      return props.id;
    }
    if (props.name) {
      return props.name;
    }
    return 'unknown';
  }, [dataCy, props.id, props.name]);

  return (
    <>
      <Wrapper
        paddingX="s"
        disabled={disabled}
        error={error}
        isDropDownOpen={isDropDownOpen}
      >
        {leftSlot && <Slot marginRight="xs">{leftSlot}</Slot>}
        <InputWrapper>
          <StyledInput
            ref={inputRef}
            type="text"
            placeholder="&nbsp;"
            disabled={disabled}
            data-cy={cyName}
            {...props}
          />
          <Label
            disabled={disabled}
            error={error}
            htmlFor={props?.id}
            data-cy={`input-label-${cyName}`}
          >
            {label}
          </Label>
        </InputWrapper>
        {rightSlot && <Slot marginLeft="xs">{rightSlot}</Slot>}
      </Wrapper>

      {(error || helper) && (
        <Box minHeight="22px" marginTop="xxs">
          {/* eslint-disable-next-line no-nested-ternary */}
          {error ? (
            <Error as="span" variant="xs" data-cy={`input-error-${cyName}`}>
              {error}
            </Error>
          ) : helper ? (
            <Helper data-cy={`input-helper-${cyName}`}>{helper}</Helper>
          ) : null}
        </Box>
      )}
    </>
  );
};
