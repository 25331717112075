/*eslint-disable*/
import snakeCase from 'lodash/snakeCase';
import { TrackEvent, EventTypeMapSpec, EventTypeMap, EventType } from './types';
/**
 * Converts a front end event into an event stream event.
 *
 * @param {object} event Front end formatted event
 * @returns {object} Event stream formatted event
 */
export const updaterEventFromEvent = (event: TrackEvent) => ({
  timestamp: event.timestamp,
  domain: snakeCase(event.domain),
  object: snakeCase(event.object),
  verb: snakeCase(event.verb),
  user: event.user,
  page: event.page,
  details: event.details,
});

/**
 * Takes an EventTypeMap configuration and returns an object
 * track can be used to access the tracking object to pass into track()
 * @param config The EventTypeMap configuration
 * @returns A EventTypeMapSpec object and closely follow the shape of the passed configuration but can be used to pass into track()
 */
export const registerEventMap = <ThisEventTypeMap extends EventTypeMap>(
  config: ThisEventTypeMap
) => {
  const configRep: Record<
    string,
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    Record<string, Record<string, EventType<any>>>
  > = {};

  for (const domain in config) {
    configRep[domain] = {};
    for (const object in config[domain]) {
      configRep[domain][object] = {};
      for (const verb in config[domain][object]) {
        const path = [domain, object, verb];
        const eventTemplate = {
          path,
          details: 'dfs',
        };
        configRep[domain][object][verb] = eventTemplate;
      }
    }
  }
  return configRep as EventTypeMapSpec<ThisEventTypeMap>;
};

/**
 * Generate an `EventType` object used for configuring the allowable
 * event types for the tracker
 * @returns An EventType
 */
export const eventType = <Details = undefined>(): EventType<Details> => ({
  details: {} as Details,
});

export default eventType;
