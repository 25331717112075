import React from 'react';
import styled from '@emotion/styled';
import { space } from 'styled-system';
import { CaretDown } from 'phosphor-react';
import css, { SystemStyleObject } from '@styled-system/css';
import { SpaceProps, Theme } from '../../types/theme-types';
import { Box } from '../box/Box';
import { Error } from '../error';
import { Helper } from '../helper';

type OptionValue = string | number | readonly string[] | undefined;

interface DropdownSelectOption<T extends OptionValue> {
  label: T;
  value?: string; // if value is undefined label is used as the value
}

export interface DropdownSelectProps<T extends OptionValue> {
  /**
   * The label of the DropdownSelect component.
   * @example
   * label = "Choose option"
   */
  label: string;
  /**
   * The id of the DropdownSelect component.
   * @example
   * label = "select-1"
   */
  id?: string;
  /**
   * The name of the DropdownSelect component.
   * @example
   * label = "Countries"
   */
  name: string;
  /**
   * The value of the DropdownSelect component.
   * @example
   * value = "option1"
   */
  value?: string;
  /**
   * The helper of the DropDownSelect.
   * @example
   * helper = "This is a helper text"
   */
  helper?: string;

  /**
   * The error message of the DropdownSelect component.
   * @example
   * error = "Something went wrong"
   */
  error?: string;
  /**
   * If `true`, the element will be disabled.
   * It will set the `disabled` HTML attribute
   * @example
   * disabled
   */
  disabled?: boolean;
  /**
   * The options of DropdownSelect component.
   * @example
   * options = [{value: 'option1', label: 'Option 1'}]
   */
  options: DropdownSelectOption<T>[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLSelectElement>) => void;
}

export interface SelectProps {
  error?: string;
  default?: string;
  disabled?: boolean;
  hasValue?: boolean;
}

type HelperColorProps = Pick<SelectProps, 'disabled' | 'error'> & {
  theme: Theme;
};

const getColor = (
  theme: Theme,
  defaultColor: string,
  disabled?: boolean,
  error?: string | boolean
) => {
  if (disabled) return theme.input.disabledColor;
  if (error) return theme.input.errorColor;
  return defaultColor;
};

const getWrapperBorderColor = ({ theme, disabled, error }: HelperColorProps) =>
  getColor(theme, theme.input.borderColor, disabled, error);

const getWrapperFocusedBorderColor = ({
  theme,
  disabled,
  error,
}: HelperColorProps) =>
  getColor(theme, theme.input.borderFocusColor, disabled, error);

const getWrapperHoveredBorderColor = ({
  theme,
  disabled,
  error,
}: HelperColorProps) =>
  getColor(theme, theme.input.borderHoverColor, disabled, error);

type WrapperProps = Pick<SelectProps, 'disabled' | 'error'> & SpaceProps;

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  align-items: center;
  height: 64px;
  background-color: ${(props) => props.theme.input.backgroundColor};
  transition: box-shadow 300ms;

  ${space};
`;

const SelectWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  width: 100%;
  height: 100%;
`;

const getFocusedLabelColor = ({ disabled, error, theme }: HelperColorProps) =>
  getColor(theme, theme.input.focusedColor, disabled, error);

const getLabelColor = ({ disabled, error, theme }: HelperColorProps) =>
  getColor(theme, theme.input.labelColor, disabled, error);

const getSelectColor = ({ disabled, theme }: HelperColorProps) =>
  getColor(theme, theme.input.color, disabled, false);

type LabelProps = Pick<SelectProps, 'disabled' | 'error' | 'hasValue'>;

const LabelBaseStyles = (hasValue: boolean) =>
  css({
    fontSize: hasValue ? 'xs' : 'm',
    fontWeight: 'regular',
    lineHeight: hasValue ? 'xs' : 'default',
    fontFamily: 'body',
    paddingX: 's',
    'select:focus ~ &': {
      lineHeight: 'xs',
      fontSize: 'xs',
      fontWeight: 'semiBold',
    },
  } as SystemStyleObject);

const Label = styled.label<LabelProps>`
  ${({ hasValue }) => LabelBaseStyles(hasValue as boolean)}
  color: ${getLabelColor};
  position: absolute;
  pointer-events: none;
  top: calc(64px / 3);
  transition: transform 0.2s linear;
  select:focus ~ & {
    top: 10px;
    color: ${getFocusedLabelColor};
  }
  ${(props) =>
    props.hasValue &&
    `
    top: 10px;
    color: ${(helperColorProps: HelperColorProps) =>
      getFocusedLabelColor(helperColorProps)};
  `}
`;

const BaseStyledSelect = css({
  lineHeight: 'm',
  fontSize: 'm',
  fontWeight: 'regular',
  fontFamily: 'body',
  paddingX: 's',
  paddingY: '0',
} as SystemStyleObject);

const StyledSelect = styled.select`
  ${BaseStyledSelect}
  display: block;
  width: 100%;
  height: 100%;
  color: ${getSelectColor};
  background-color: inherit;
  border: none;
  outline: none;
  padding-top: calc(64px / 3);
  box-sizing: border-box;
  appearance: none;
  option {
    color: ${(props) => props.theme.input.labelColor};
  }
  /* Removes chrome autofill color */
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  /* Change text in autofill textbox*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }

  border: 1px solid ${getWrapperBorderColor};
  border-radius: 8px;
  &:focus-within {
    border-color: ${(props) => getWrapperFocusedBorderColor(props)};
    box-shadow: 0px 4px 4px rgba(117, 117, 117, 0.25);
  }

  &:hover {
    border-color: ${getWrapperHoveredBorderColor};
  }
`;

const ArrowDown = styled(CaretDown)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${({ theme }) => theme.space.s}px;
  margin: auto;
  pointer-events: none;
`;

const DropDownOption: React.FC<DropdownSelectOption<OptionValue>> = ({
  label,
  value,
}) => <option value={value || label}>{label}</option>;

export const DropDownSelect: React.FC<DropdownSelectProps<OptionValue>> = ({
  label = '',
  name,
  value,
  disabled,
  helper,
  error,
  options,
  onChange,
  onBlur,
  onFocus,
  id,
}) => {
  const hasValue = value !== undefined;

  return (
    <>
      <Wrapper disabled={disabled} error={error}>
        <SelectWrapper>
          <StyledSelect
            value={value}
            name={name}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            id={id}
          >
            <option value="" hidden />
            {options.map((option) => (
              <DropDownOption
                key={option.value}
                label={option.label}
                value={option.value}
              />
            ))}
          </StyledSelect>
          <Label
            htmlFor={id}
            disabled={disabled}
            error={error}
            hasValue={hasValue}
          >
            {label}
          </Label>
          <ArrowDown height="16" width="16" color="#000000" weight="bold" />
        </SelectWrapper>
      </Wrapper>

      {(error || helper) && (
        <Box minHeight="20px" marginTop="xxs">
          {/* eslint-disable-next-line no-nested-ternary */}
          {error ? (
            <Error as="span" variant="xs">
              {error}
            </Error>
          ) : helper ? (
            <Helper>{helper}</Helper>
          ) : null}
        </Box>
      )}
    </>
  );
};
