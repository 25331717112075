import styled from '@emotion/styled';
import {
  compose,
  margin,
  padding,
  MarginProps,
  PaddingProps,
  FlexboxProps,
  flexbox,
} from 'styled-system';
import { LabeledControl, LabeledControlProps } from '../labeled-control';
import { Checkbox } from '../checkbox';

interface LabeledCheckboxProps
  extends LabeledControlProps,
    FlexboxProps,
    MarginProps,
    PaddingProps {}

const LabeledCheckboxWrapper = styled(LabeledControl)<LabeledCheckboxProps>`
  ${compose(margin, padding, flexbox)}
`;

LabeledCheckboxWrapper.defaultProps = {
  alignItems: 'flex-start',
};

export const LabeledCheckbox: React.FC<LabeledCheckboxProps> = ({
  px,
  py,
  mb,
  mr,
  children,
  size = 'm',
  label,
  alignItems,
  ...rest
}) => {
  return (
    <LabeledCheckboxWrapper
      px={px}
      py={py}
      mb={mb}
      mr={mr}
      label={label}
      size={size}
      alignItems={alignItems}
      {...rest}
    >
      <Checkbox size={size} {...rest} />
    </LabeledCheckboxWrapper>
  );
};
