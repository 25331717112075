import { useLayoutEffect, useState } from 'react';

/*
 * An array of media queries which conform to the
 * [matchMedia](https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia)
 * spec.
 */
export type MediaQueries = Array<string>;

/*
 * An ordered array of booleans which are true if the related query in
 * MediaQueries is matched, false if not.
 */
export type MatchedMedia = Array<boolean>;

export function useMatchMedia(
  queries: MediaQueries,
  defaultValues: MatchedMedia = []
): MatchedMedia {
  const initialValues = defaultValues.length
    ? defaultValues
    : Array(queries.length).fill(false);

  if (typeof window === 'undefined' || typeof window.matchMedia === 'undefined')
    return initialValues;

  const mediaQueryLists = queries.map((q) => window.matchMedia(q));
  const getValue = (): MatchedMedia => {
    // Return the value for the given queries
    const matchedQueries = mediaQueryLists.map((mql) => mql.matches);

    return matchedQueries;
  };

  // State and setter for matched value
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [value, setValue] = useState(getValue);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    // Event listener callback
    // Note: By defining getValue outside of useEffect we ensure that it has ...
    // ... current values of hook args (as this hook only runs on mount/dismount).
    const handler = (): void => setValue(getValue);
    // Set a listener for each media query with above handler as callback.
    mediaQueryLists.forEach((mql) => mql.addListener(handler));
    // Remove listeners on cleanup
    return (): void =>
      mediaQueryLists.forEach((mql) => mql.removeListener(handler));
    // this dep array is intentionally empty
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return value;
}
