import React from 'react';
import styled from '@emotion/styled';
import { system } from 'styled-system';
import { StandardLonghandProperties } from 'csstype';
import { Box, BoxProps } from '../box';

type OmittedImgHTMLAttributes = 'border' | 'height' | 'width' | 'color';

/**
 * Removes conflicting keys from styled-system & ImgHTMLAttributes
 */
interface ImgHTMLAttributes
  extends Omit<
    React.ImgHTMLAttributes<HTMLImageElement>,
    OmittedImgHTMLAttributes
  > {}

export interface ImageProps extends BoxProps, ImgHTMLAttributes {
  /**
   * Sets how the content should be resized to fit its container.
   */
  objectFit?: StandardLonghandProperties['objectFit'];
}

const Image = styled(Box)<ImageProps>`
  ${system({
    objectFit: true,
  })}
`;

Image.defaultProps = {
  as: 'img',
};

export { Image };
