import styled from '@emotion/styled';
import { Popover as TinyPopover, ArrowContainer } from 'react-tiny-popover';
import { useState } from 'react';
import { InfoButton } from '../info-button';
import { Palette } from '../../constants/palette';

interface TooltipProps {
  details: string;
  maxwidth?: number;
}

const PopoverBox = styled.div`
  padding: ${({ theme }) => theme.space.s}px;
  background-color: ${Palette.white};
  border-radius: 8px;
  border: 1px solid ${Palette.gray80};
`;

const ButtonWrapper = styled.div`
  vertical-align: middle;
`;

const adjustToPixel = (width: string | number): string => {
  // approximate number of pixels in a character width
  const charToPixelFactor = 15;
  const pxValueRegex = /[0-9]+px/;

  if (typeof width === 'string' && width.match(pxValueRegex)) {
    return width;
  }
  if (typeof width === 'number') {
    // used to support legacy code
    return `${width * charToPixelFactor}px`;
  }
  // default value
  return '300px';
};

export const Popover: React.FC<TooltipProps> = ({ details, maxwidth = 20 }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <TinyPopover
      isOpen={isPopoverOpen}
      positions={['top', 'bottom']}
      content={({ position, childRect, popoverRect, ...rest }) => (
        <ArrowContainer
          popoverRect={popoverRect}
          childRect={childRect}
          position={position}
          arrowSize={10}
          arrowColor={Palette.gray80}
          style={{ maxWidth: adjustToPixel(maxwidth) }}
        >
          <PopoverBox {...rest}>{details}</PopoverBox>
        </ArrowContainer>
      )}
      onClickOutside={() => setIsPopoverOpen(false)}
    >
      <ButtonWrapper
        onClick={(e) => {
          setIsPopoverOpen(!isPopoverOpen);
          e.stopPropagation();
        }}
      >
        <InfoButton />
      </ButtonWrapper>
    </TinyPopover>
  );
};
