import styled from '@emotion/styled';
import css, { SystemStyleObject } from '@styled-system/css';
import { Text } from '../text';

export interface TextLinkProps {
  /**
   * The URL that the hyperlink points to
   */
  href?: string;
  /**
   * Add target props for link
   */
  // @ts-ignore
  target?: React.HTMLAttributeAnchorTarget;
  /**
   * Defines the relationship between a linked resource and the current document
   */
  rel?: string;

  /**
   * Resposible to hide default a link text underline decoreation
   */
  showTextDecoration?: boolean;

  /**
   * Prevent link from changing color after being clicked.
   */
  noVisitedColorChange?: boolean;
  /**
   * Hover state add underline.
   */
  underlineOnHover?: boolean;
}

export const baseStyles = css({
  display: 'inline-block',
  cursor: 'pointer',
  color: 'link',
  '&:visited': {
    color: 'visited',
  },
  '&:hover': {
    color: 'hover',
  },
} as SystemStyleObject);

export const TextLink = styled(Text)<TextLinkProps>`
  ${baseStyles}
  color: ${(props) => props.color};
  ${({ noVisitedColorChange, color, theme }) =>
    noVisitedColorChange &&
    `
      &:visited {
        color: ${color};
        &:hover {
          color: ${theme.colors.hover};
        }
      }
  `}

  text-decoration: ${(props) =>
    props.showTextDecoration ? 'underline' : 'none'};

  ${({ underlineOnHover }) =>
    underlineOnHover &&
    `
      &:hover {
        text-decoration: underline;
      }
    `}
`;

TextLink.defaultProps = {
  as: 'a',
  variant: 'inherit',
};
