import { Theme } from '../types/theme-types';
import { Palette } from '../constants/palette';

export const badgeVariants: Theme['badgeVariants'] = {
  default: {
    color: Palette.white,
    backgroundColor: Palette.blue,
  },
  info: {
    backgroundColor: Palette.blue30,
  },
  cancelled: {
    backgroundColor: Palette.gray90,
  },
  pending: {
    backgroundColor: Palette.orange,
  },
  done: {
    backgroundColor: Palette.mint40,
  },
  warning: {
    backgroundColor: Palette.petalPink,
  },
};
