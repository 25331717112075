import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Palette } from '../../constants/palette';
import { ControlProps } from '../../types/control-types';

export type RadioSize = 's' | 'm' | 'l';
export type RadioColor = 'green' | 'blue' | 'white';

export const CHECKBOX_SIZES: {
  [size in RadioSize]: {
    container: number;
    innerCircle: number;
  };
} = {
  s: {
    container: 16,
    innerCircle: 8,
  },
  m: {
    container: 24,
    innerCircle: 14,
  },
  l: {
    container: 40,
    innerCircle: 22,
  },
};

export const RADIO_COLORS: {
  [size in RadioColor]: {
    selected: Palette;
    notSelected: Palette;
    background: Palette;
  };
} = {
  green: {
    selected: Palette.goGreen,
    notSelected: Palette.gray80,
    background: Palette.white,
  },
  blue: {
    selected: Palette.blue,
    notSelected: Palette.gray80,
    background: Palette.white,
  },
  white: {
    selected: Palette.blue,
    notSelected: Palette.gray80,
    background: Palette.white,
  },
};

const radioColorsDisabled: {
  [size in RadioColor]: {
    main: Palette;
    background: Palette;
  };
} = {
  green: {
    main: Palette.gray90,
    background: Palette.transparent,
  },
  blue: {
    main: Palette.gray90,
    background: Palette.transparent,
  },
  white: {
    main: Palette.gray90,
    background: Palette.transparent,
  },
};

interface RadioTagProps {
  // Using `elementSize` instead of `size` to avoid overlap with `input`'s
  // native prop.
  elementSize: RadioSize;
  color: RadioColor;
}
/**
 * Return the CSS styles for equal height and size.
 *
 * @param dimension
 */
const getSquareDimensions = (dimension: number) => css`
  height: ${dimension}px;
  width: ${dimension}px;
`;

const RadioTag = styled.input<RadioTagProps>`
  ${({ elementSize }) =>
    getSquareDimensions(CHECKBOX_SIZES[elementSize].container)};
  flex-shrink: 0;
  align-items: center;
  appearance: none;
  background-color: ${({ color }) => RADIO_COLORS[color].background};
  border-radius: ${Number.MAX_SAFE_INTEGER}px;
  border: 2px solid ${({ color }) => RADIO_COLORS[color].notSelected};
  cursor: pointer;
  display: grid;
  justify-content: center;
  margin: 0;

  &,
  &::after {
    transition: all 0.2s ease;
    box-sizing: border-box;
  }

  &:disabled {
    border-color: ${({ color }) => radioColorsDisabled[color].main};
    background-color: ${({ color }) => radioColorsDisabled[color].background};
  }

  &:checked {
    border-color: ${({ color }) => RADIO_COLORS[color].selected};
  }
  &:disabled:checked {
    border-color: ${({ color }) => radioColorsDisabled[color].main};
  }

  &::after {
    content: '';
    ${({ elementSize }) =>
      getSquareDimensions(CHECKBOX_SIZES[elementSize].innerCircle)};
    border-radius: ${Number.MAX_SAFE_INTEGER}px;
  }

  &:checked::after {
    background-color: ${({ color }) => RADIO_COLORS[color].selected};
  }

  &:checked:disabled::after {
    background-color: ${({ color }) => radioColorsDisabled[color].main};
  }
`;

export interface RadioProps extends ControlProps {
  /**
   * Changes the color of the checkbox.
   */
  color?: RadioColor;

  /**
   * Changes the size of the checkbox.
   */
  size?: RadioSize;

  /**
   * Sets the data-cy propery of the checkbox for tracking
   */
  dataCy?: string;
}

/**
 * Radio
 *
 * @description Radio component.
 *
 * @component
 * @example
 * return (
 *   <Radio
 *     size="m"
 *     color="green"
 *     checked={false}
 *     onClick={() => { ... }}
 *   />
 * )
 */
const Radio: React.FC<RadioProps> = ({
  size = 'm',
  color = 'green',
  dataCy,
  ...otherProps
}) => {
  const cyName = useMemo(() => {
    if (dataCy) {
      return dataCy;
    }
    if (otherProps.id) {
      return otherProps.id;
    }
    if (otherProps.name) {
      return otherProps.name;
    }
    return 'radio-unknown';
  }, [dataCy, otherProps.id, otherProps.name]);

  return (
    <RadioTag
      type="radio"
      elementSize={size}
      color={color}
      data-cy={cyName}
      {...otherProps}
    />
  );
};

export { Radio };
