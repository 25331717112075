import { useCallback, useRef, DependencyList } from 'react';
import { useSafeLayoutEffect } from './use-safe-layout-effect';

/**
 * React hook to persist any value between renders,
 * but keeps it up-to-date if it changes.
 *
 * @param value the value or function to persist
 */
export function useCallbackRef<T extends (...args: any[]) => any>(
  fn: T | undefined,
  deps: DependencyList = []
): T {
  const ref = useRef(fn);

  useSafeLayoutEffect(() => {
    ref.current = fn;
  });

  return useCallback(((...args) => ref.current?.(...args)) as T, deps);
}
