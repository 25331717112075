import React from 'react';
import styled from '@emotion/styled';
import { variant } from 'styled-system';
import { Box, BoxProps } from '../box';
import { Text } from '../text';
import { BadgeKey } from '../../types/theme-types';

export type BadgeVariants = BadgeKey;

type PreferredBadgeText =
  | 'Processing'
  | 'Cancelled'
  | 'Recently viewed'
  | 'Needs more'
  | 'In Review'
  | 'Done'
  | 'Almost Done';

type OmittedBoxProps =
  | 'backgroundColor'
  | 'color'
  | 'p'
  | 'padding'
  | 'pt'
  | 'paddingTop'
  | 'pb'
  | 'paddingBottom'
  | 'pl'
  | 'paddingLeft'
  | 'pr'
  | 'paddingRight'
  | 'py'
  | 'paddingY'
  | 'px'
  | 'paddingX';
export interface BadgeProps extends Omit<BoxProps, OmittedBoxProps> {
  /**
   * Controls background-color & hide/show dot.
   */
  variant?: BadgeVariants;
  /**
   * Any string works, but you get typeahead for common cases.
   */
  text: PreferredBadgeText | string;
}

const BadgeComponent = styled(Box)`
  ${({ theme }) => variant({ variants: theme.badgeVariants })}
`;

const CircleDotComponent = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: ${({ theme }) => theme.colors.error};
  margin: auto;
  border-radius: 50%;
  width: ${({ theme }) => theme.space.xs}px;
  height: ${({ theme }) => theme.space.xs}px;
  border: none;
  cursor: default;
`;

// eslint-disable-next-line @typescript-eslint/no-shadow
export const Badge: React.FC<BadgeProps> = ({ text, ...rest }) => {
  const isWarningVariant = rest.variant === 'warning';
  return (
    <BadgeComponent
      position="relative"
      display="inline-block"
      px="xs"
      borderRadius="xxs"
      backgroundColor="active"
      {...rest}
    >
      {isWarningVariant && <CircleDotComponent>&nbsp;</CircleDotComponent>}
      <Text
        marginLeft={isWarningVariant ? '12px' : 'default'}
        display="inline-block"
        color="primary"
        variant="xs"
      >
        {text}
      </Text>
    </BadgeComponent>
  );
};
