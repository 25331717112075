import React, { FC, ReactElement } from 'react';
import { useBridge, Bridge } from './bridge';

export type NativeWebElementProps = {
  as?: FC<{ bridge: Bridge }> | ReactElement | null;
};

/**
 * Ignored in a web browser, enabled in a WebView
 *
 * browser: returns its children
 *
 * webview: renders the `as` prop
 */
export const NativeWebElement: FC<NativeWebElementProps> = ({
  as: ElementOrComponent,
  children,
}) => {
  const bridge = useBridge();
  const element =
    typeof ElementOrComponent === 'function' ? (
      <ElementOrComponent bridge={bridge} />
    ) : (
      ElementOrComponent
    );

  return <>{bridge.status === 'webview:present' ? element : children}</>;
};
