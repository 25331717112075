import styled from '@emotion/styled';
import { Box } from '../box';
import { Text } from '../text';
import { Palette } from '../../constants/palette';
import { ControlProps } from '../../types/control-types';

export enum SegmentedControlElementPalette {
  gray90 = Palette.gray90,
  gray96 = Palette.gray96,
  white = Palette.white,
  blue = Palette.blue,
  goGreen = Palette.goGreen,
}

export interface SegmentedControlElementProps extends ControlProps {
  /**
   * Label text that sits to the right of the input control.
   */
  label: string | React.ReactNode;

  /**
   * If `true` it sets focus styles on pill
   */
  isFocused?: boolean;

  /**
   * Changes the color of the active tab.
   */
  activeColor?: SegmentedControlElementPalette;

  /**
   * Changes or sets the data-cy attribute of the control
   */
  dataCy?: string;

  /**
   * Changes if the control is active by default
   */
  defaultChecked?: boolean;
}

type StyleType = Pick<SegmentedControlElementProps, 'activeColor'>;

const SegmentedControTag = styled.input`
  opacity: 0;
  height: 0;
  position: absolute;
`;

const SegmentedControLabelInner = styled.div<StyleType>`
  border-radius: ${(props) => props.theme.radii.l};
  border: solid 1px ${(props) => props.theme.colors.secondary};
  padding: ${({ theme }) => `${theme.space.s}px ${theme.space.xxs}px`};
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  ${({ theme }) => theme.mediaQueries.m} {
    min-width: 147px;
  }
`;

const SegmentedControLabel = styled.label<StyleType>`
  padding: ${({ theme }) => `${theme.space.xxxs}px`};
  flex-shrink: 0;
  appearance: none;
  border-radius: ${(props) => props.theme.radii.l};
  border: solid 1px transparent;
  cursor: pointer;
  display: grid;
  margin: 0;
  &:hover,
  &:focus {
    cursor: pointer;
    border-color: ${({ activeColor }) => `${activeColor}`};
    background-color: ${({ theme }) => `${theme.colors.secondary}`};
  }
`;

const SegmentedControBox = styled(Box)<StyleType>`
  input:checked ~ label {
    border-color: transparent;
    &:focus,
    &:hover {
      border: solid 1px ${({ activeColor }) => `${activeColor}`};
    }
    & > div {
      background-color: ${({ activeColor }) => `${activeColor}`};
      border: solid 1px ${({ activeColor }) => `${activeColor}`};
    }
  }
`;

export const SegmentedControlElement: React.FC<
  SegmentedControlElementProps
> = ({
  value,
  onChange,
  name,
  disabled,
  label,
  dataCy,
  activeColor = SegmentedControlElementPalette.goGreen,
  defaultChecked,
}) => {
  return (
    <SegmentedControBox activeColor={activeColor}>
      <SegmentedControTag
        value={value}
        onChange={onChange}
        name="tab-toggle"
        type="radio"
        id={name}
        disabled={disabled}
        defaultChecked={defaultChecked}
      />
      <SegmentedControLabel
        activeColor={activeColor}
        data-cy={dataCy}
        htmlFor={name}
      >
        <SegmentedControLabelInner>
          <Text variant={['sBold', 'sBold', 'mBold', 'mBold']}>{label}</Text>
        </SegmentedControLabelInner>
      </SegmentedControLabel>
    </SegmentedControBox>
  );
};
