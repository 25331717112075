import { ChangeEvent, RefObject } from 'react';
import styled from '@emotion/styled';
import { UploadSimple } from 'phosphor-react';
import { Button } from '../button';
import { Palette } from '../../constants/palette';

const StyledButton = styled(Button)<{ isMobile?: boolean }>`
  ${({ isMobile }) =>
    isMobile &&
    `
  color: ${Palette.darkBlue};
  border-color: ${Palette.darkBlue};
  background-color: transparent;
  `}
`;

interface FileInputButtonProps {
  label?: string;
  onFilesSelected: (files: File[] | FileList) => void;
  isMobile: boolean;
  fileInputClicked: () => void;
  fileInputRef: RefObject<HTMLInputElement>;
}

export const FileInputButton: React.FC<FileInputButtonProps> = ({
  label = 'Browse files',
  onFilesSelected,
  isMobile,
  fileInputClicked,
  fileInputRef,
}) => {
  const onFileChange = (e: ChangeEvent) =>
    onFilesSelected(
      Array.from((e?.target as HTMLInputElement)?.files as FileList)
    );

  return (
    <>
      <StyledButton
        type="button"
        name="browse-files"
        isFluidWidth={isMobile}
        rightIcon={
          isMobile ? (
            <UploadSimple size="16" color={Palette.darkBlue} />
          ) : undefined
        }
        size={isMobile ? 'l' : 'm'}
        isMobile={isMobile}
        onClick={fileInputClicked}
      >
        {label}
      </StyledButton>
      {/* This input is not shown visually, thus UDS component is not used  (UDS component does not support display property) */}
      <input
        multiple
        data-cy="browse-files-input"
        onChange={onFileChange}
        ref={fileInputRef}
        style={{ display: 'none' }}
        type="file"
      />
    </>
  );
};
