import { useMemo } from 'react';
import { ArrowLeft, ArrowRight } from 'phosphor-react';
import styled from '@emotion/styled';
import { Flex } from '../flex/Flex';
import { Text } from '../text/Text';
import { IconButton } from '../icon-button/IconButton';
import { generatePagination } from './PaginationUtility';

interface PaginationProps {
  /**
   * Current Page Number
   */
  pageIndex: number;
  /**
   * Total page count
   */
  pageCount: number;
  /**
   * Defines if user can go to previous page
   * Usually disabled on 1st page
   */
  canPreviousPage: boolean;
  /**
   * Defines if user can go to next page
   * Usually disabled on last page
   */
  canNextPage: boolean;
  /**
   * Handles page nagivation on click
   */
  gotoPage: (page: number) => void;
  /**
   * Next page, pervious page, and displayed page numbers
   * buttons will contain data name for locating the button.
   */
  dataCy?: string;
}

const StyledText = styled(Text)`
  color: white;
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;
const borderWidth = '1px';
const StyledIconButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.active};
`;
const StyledSecondaryButton = styled.button`
  font-size: 14px;
  font-family: 'Work Sans', sans-serif;
  height: auto;
  width: 32px;
  border-radius: 50%;
  border: 0px;
  color: #28293d;
  background: transparent;
  text-align: center;
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray90};
    border-color: transparent;
    border-radius: 50%;
  }
  &:focus,
  &:focus-visible {
    color: white;
    background: #3957e1;
    outline:none;  
    border: 0px;
    box-shadow: ${({ theme }) =>
      `0 0 0 ${borderWidth} inset ${theme.button.selectorColors.focusInnerBorder}, 0 0 0 ${borderWidth} ${theme.button.selectorColors.focusMiddleBorder}`};
  }
  /* Remove the focus indicator on mouse-focus for browsers that have focus-visible */
  &:focus:not(:focus-visible) {
    border-color: ${({ theme }) => theme.colors.primary};
    outline: none;
    &:before {
      content: none;
    },
  },

`;

// Expressly built to work seamlessly with React Table
export const Pagination: React.FC<PaginationProps> = ({
  pageIndex,
  pageCount,
  canPreviousPage,
  canNextPage,
  gotoPage,
  dataCy,
}: PaginationProps) => {
  const cyName = useMemo(() => {
    if (dataCy) {
      return dataCy;
    }
    return 'button-unknown';
  }, [dataCy]);

  const pages = useMemo(
    // utility function which handles page number and ellipsis genertion
    () => generatePagination(pageCount, pageIndex),
    [pageCount, pageIndex]
  );
  const handlePage = (page: number) => () => {
    if (gotoPage) gotoPage(page);
  };
  return (
    <Flex py="s" px="m" alignItems="center" justifyContent="space-between">
      <Flex role="navigation" justifyContent="space-around">
        {pageCount > 1 ? (
          <>
            <Flex ml="xs">
              <IconButton
                icon={<ArrowLeft />}
                onClick={handlePage(pageIndex - 1)}
                aria-label="pervious page"
                disabled={!canPreviousPage}
                size="s"
                dataCy={`${cyName}-pervious-page`}
                variant="secondary"
              />
            </Flex>
            {pages.map((page: number | string, index: number) => {
              const isActive = page === pageIndex;
              if (
                index !== 0 &&
                typeof page === 'string' &&
                typeof pages[index - 1] === 'string'
              )
                return null;

              if (typeof page === 'number' && isActive) {
                return (
                  <Flex
                    ml="xs"
                    key={typeof page === 'number' ? page : page + index}
                  >
                    <StyledIconButton
                      icon={<StyledText variant="s">{page + 1}</StyledText>}
                      size="s"
                      aria-label={String(page + 1)}
                      variant="primary"
                      onClick={handlePage(page)}
                    />
                  </Flex>
                );
              }
              if (typeof page === 'number') {
                return (
                  <Flex
                    ml="xs"
                    key={typeof page === 'number' ? page : page + index}
                  >
                    <StyledSecondaryButton
                      type="button"
                      onClick={handlePage(page)}
                      data-cy={`${cyName}-${page}`}
                    >
                      {page + 1}
                    </StyledSecondaryButton>
                  </Flex>
                );
              }
              return (
                <Flex
                  ml="xs"
                  key={typeof page === 'number' ? page : page + index}
                >
                  <Text variant="s">{page}</Text>
                </Flex>
              );
            })}
            <Flex ml="xs">
              <IconButton
                icon={<ArrowRight />}
                onClick={handlePage(pageIndex + 1)}
                disabled={!canNextPage}
                size="s"
                aria-label="next page"
                variant="secondary"
                dataCy={`${cyName}-next-page`}
              />
            </Flex>
          </>
        ) : null}
      </Flex>
    </Flex>
  );
};
