export const selectFromWindow = <T>(...selectors: string[]) => {
  if (typeof window === 'undefined') return null;

  return selectors.reduce(
    (source, key) =>
      // @ts-expect-error: index type issue
      source?.[key],
    window,
  ) as unknown as undefined | T;
};

export { selectFromWindow as window };
