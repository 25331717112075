import React from 'react';
import styled from '@emotion/styled';
import { RadioColor } from '../radio';
import { Flex } from '../flex';
import { Box } from '../box';

import { SegmentedControlElement } from './SegmentedControlElement';

type SegmentedControlOption = {
  value: string;
  label: string;
  disabled?: boolean;
};
export interface SegmentedControlProps {
  color?: RadioColor;
  value: string;
  onChange: (newValue: string) => void | Promise<void>;
  options: SegmentedControlOption[];
  name?: string;
  /**
   * option to space horizontal radio buttons equally using flex
   * default is false
   */
  distributeEqually?: boolean;
}
type StyleType = Pick<SegmentedControlProps, 'distributeEqually'>;

const SegmentedControlWrapper = styled(Flex)<StyleType>`
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: ${({ theme }) => `${theme.space.xxxs}px`};
  background: ${({ theme }) => `${theme.colors.secondary}`};
  border-radius: calc(${(props) => props.theme.radii.l} + 4px);
  border: solid 1px ${({ theme }) => `${theme.colors.secondary}`};
  &:focus-within {
    border: solid 1px ${({ theme }) => `${theme.colors.success}`};
  }
  ${({ theme }) => theme.mediaQueries.m} {
    width: ${({ distributeEqually }) =>
      distributeEqually ? '100%' : 'fit-content'};
  }
`;

const SegmentedControlFlexBox = styled(Box)<StyleType>`
  flex: 1;
  margin-right: ${({ theme }) => theme.space.xxxs}px;
  & + & {
    margin-left: ${({ theme }) => theme.space.xxxs}px;
  }
  &:last-of-type {
    margin-right: 0;
  }
  ${({ theme }) => theme.mediaQueries.m} {
    flex: ${({ distributeEqually }) => (distributeEqually ? 1 : null)};
  }
`;

/**
 * SegmentedControls
 *
 * @description This component implements a tab-like control.
 *
 * @component
 * @example
 *
 *const options = [
 *  {
 *     label: 'Value 1',
 *     value: 'value_1',
 *   },
 *  {
 *     label: 'Value 2',
 *     value: 'value_2',
 *   },
 * ];
 *
 * const [value, setValue] = useState('value_1');
 *
 * return (
 *   <SegmentedControls
 *     options={options}
 *     onChange={setValue}
 *   />
 * )
 */
export const SegmentedControls: React.FC<SegmentedControlProps> = ({
  options,
  value,
  onChange,
  color,
  name,
  distributeEqually = false,
}) => {
  return (
    <SegmentedControlWrapper distributeEqually={distributeEqually}>
      {options.map(({ value: optionValue, label, disabled }) => (
        <SegmentedControlFlexBox
          key={optionValue}
          distributeEqually={distributeEqually}
        >
          <SegmentedControlElement
            activeColor={color as any}
            id={optionValue}
            value={optionValue}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            disabled={disabled}
            name={name || optionValue}
            label={label}
            defaultChecked={optionValue === value}
          />
        </SegmentedControlFlexBox>
      ))}
    </SegmentedControlWrapper>
  );
};
