import { BeforeMaskedStateChangeStates } from 'react-input-mask';

/**
 * function to check if input has reached max input characters
 * When input has filled fully, it should behave as insert instead of replacing next character.
 */
export function onMaskedStateChange(
  state: BeforeMaskedStateChangeStates,
  mask: string,
  unmaskFunc: (value: string) => string
) {
  const { nextState, previousState } = state;
  const { value: nextValue } = nextState;
  const maskValueMaxLength = unmaskFunc(mask).length;

  if (previousState) {
    const { value: previousValue, selection } = previousState;

    const unmaskedPreviousValue = unmaskFunc(previousValue).length;
    const unmaskedNextValue = unmaskFunc(nextValue).length;

    // default behaviour of react input mask is to replace the next character
    // when cursor is in between characters & no text is selected
    // ignore the next value if all characters are entered instead of replacing next character
    if (
      selection?.start === selection?.end &&
      unmaskedPreviousValue === maskValueMaxLength &&
      unmaskedPreviousValue === unmaskedNextValue
    ) {
      return {
        ...previousState,
      };
    }
  }

  return {
    ...nextState,
  };
}
