import { TrackEventViewport } from './types';
/**
 * Retrieves the current viewport dimensions and offse attributes to send along
 * with our events.
 * @returns The attributes
 */
const getViewportMetrics = (): TrackEventViewport => {
  if (typeof window === 'undefined') {
    return {
      size: 'medium',
      innerWidth: 600,
      innerHeight: 600,
      outerWidth: 600,
      outerHeight: 600,
      pageXOffset: 0,
      pageYOffset: 0,
    };
  }

  const { innerWidth } = window;
  const { innerHeight } = window;
  const { outerWidth } = window;
  const { outerHeight } = window;
  const { pageXOffset } = window;
  const { pageYOffset } = window;
  const { screen } = window;

  return {
    // prettier-ignore
    // eslint-disable-next-line no-nested-ternary
    size: innerWidth < 768 ? 'small'
      : innerWidth <= 1024 ? 'medium'
        : 'large',
    innerWidth,
    innerHeight,
    outerWidth,
    outerHeight,
    pageXOffset,
    pageYOffset,
    ...(screen
      ? {
          availWidth: screen.availWidth,
          availHeight: screen.availHeight,
        }
      : {}),
  };
};

export default getViewportMetrics;
