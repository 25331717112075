import { Info, X } from 'phosphor-react';
import React, { useState } from 'react';
import { motion, Variants, useIsPresent } from 'framer-motion';
import { ToastOptions } from './Toast.types';
import { ColorKey } from '../../types/theme-types';
import { Box } from '../box';
import { Flex } from '../flex';
import { Text } from '../text';
import { useUpdateEffect } from '../../hooks/use-update-effect';
import { useTimeout } from '../../hooks/use-timeout';

interface ToastProps extends ToastOptions {
  onRequestRemove: () => void;
}

const toastMotionVariants: Variants = {
  initial: (props) => {
    const { position } = props;

    const dir = ['top', 'bottom'].includes(position) ? 'y' : 'x';

    let factor = ['top-right', 'bottom-right'].includes(position) ? 1 : -1;
    if (position === 'bottom') factor = 1;

    return {
      opacity: 0,
      [dir]: factor * 24,
    };
  },
  animate: {
    opacity: 1,
    y: 0,
    x: 0,
    scale: 1,
    transition: {
      duration: 0.4,
      ease: [0.4, 0, 0.2, 1],
    },
  },
  exit: {
    opacity: 0,
    scale: 0.85,
    transition: {
      duration: 0.2,
      ease: [0.4, 0, 1, 1],
    },
  },
};

const toastBackgroundColor: Record<string, ColorKey> = {
  default: 'success',
  success: 'success',
  error: 'error',
  info: 'info',
  warning: 'warning',
};

export const Toast: React.FC<ToastProps> = ({
  status,
  title,
  description,
  duration = 4000,
  requestClose = false,
  onRequestRemove,
  onCloseComplete,
  isClosable,
  position = 'top-right',
}) => {
  const [delay, setDelay] = useState<number | null>(duration);

  const isPresent = useIsPresent();

  useUpdateEffect(() => {
    if (!isPresent) {
      onCloseComplete?.();
    }
  }, [isPresent]);

  useUpdateEffect(() => {
    setDelay(duration);
  }, [duration]);

  const onMouseEnter = () => setDelay(null);

  const onMouseLeave = () => setDelay(duration);

  const close = () => {
    if (isPresent) onRequestRemove();
  };

  React.useEffect(() => {
    if (isPresent && requestClose) {
      onRequestRemove();
    }
  }, [isPresent, requestClose, onRequestRemove]);

  useTimeout(close, delay);

  return (
    <motion.div
      variants={toastMotionVariants}
      layout
      initial="initial"
      animate="animate"
      exit="exit"
      custom={{ position }}
      onHoverStart={onMouseEnter}
      onHoverEnd={onMouseLeave}
    >
      <Box position="relative">
        <Box
          backgroundColor={toastBackgroundColor[status]}
          padding="xs"
          borderRadius="xs"
          width="352px"
          position="relative"
        >
          <Flex alignItems="center">
            <Flex alignSelf="flex-start">
              <Info size={24} color="white" weight="fill" />
            </Flex>
            <Box ml="xs">
              {title && (
                <Text variant="mBold" color="white" margin={0} mr="m">
                  {title}
                </Text>
              )}
              {description && (
                <Text variant="s" color="white" mr="m">
                  {description}
                </Text>
              )}
            </Box>
          </Flex>
        </Box>
        {isClosable && (
          <Box
            as="button"
            onClick={onRequestRemove}
            position="absolute"
            right="xs"
            top="xs"
            padding="none"
            margin="none"
            border="none"
            backgroundColor="transparent"
            aria-label="Close"
          >
            <X size={16} color="white" weight="bold" />
          </Box>
        )}
      </Box>
    </motion.div>
  );
};
