import { FC } from 'react';
import styled from '@emotion/styled';
import { useNativeWebProps } from '@updater/native-web';
import { Box } from '../box';
import { Flex } from '../flex';
import { Logo } from '../logo';
import { TextLink } from '../text-link';
import { useViewportSizes } from '../../hooks';
import { ProfileMenu, ProfileItem } from './ProfileMenu';

interface UpdaterHeaderProps {
  authText?: string;
  displayName?: string;
  onLogoClick?: () => void;
  onLogoutClick?: () => void;
  isMenuRequired?: boolean;
  profilePath?: string;
  ProfileLinkWrapper?: FC;
}

const HeaderWrapper = styled(Flex)`
  overflow: hidden;
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: ${({ theme }) => theme.zIndices.navigation};
`;

export const UpdaterHeader: React.FC<UpdaterHeaderProps> = ({
  authText = 'Logout',
  displayName = '',
  onLogoClick,
  onLogoutClick,
  isMenuRequired,
  profilePath = '/profile',
  ProfileLinkWrapper,
}) => {
  const { isMobile } = useViewportSizes();

  const { height, style } = useNativeWebProps(({ safeArea }) => {
    const heights = ['72px', '72px', '92px', '92px', '92px'];

    return !safeArea
      ? { height: heights }
      : {
          height: heights.map(
            (size) => `calc(${size} + env(safe-area-inset-top))`
          ),
          style: {
            boxShadow: '0 0 0 .125rem #4441',
            paddingTop: 'calc(1.5rem + env(safe-area-inset-top))',
          },
        };
  });

  return (
    <HeaderWrapper
      as="header"
      width="100%"
      height={height}
      padding="m"
      marginX="auto"
      className="header"
      data-testid="header"
      style={style}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        maxWidth="1440px"
        marginX="auto"
      >
        {/* Note: Button with no styling that looks like text */}
        <Box
          onClick={onLogoClick}
          as="button"
          cursor="pointer"
          border="none"
          backgroundColor="transparent"
          padding="none"
        >
          <Logo
            color="text"
            size={isMobile ? 'm' : 'l'}
            data-testid="updater-svg-logo"
          />
        </Box>

        {isMenuRequired && (
          <ProfileMenu label={displayName}>
            <ProfileItem>
              {ProfileLinkWrapper ? (
                <ProfileLinkWrapper>
                  <TextLink
                    noVisitedColorChange
                    variant="xxxlBold"
                    color="white"
                    underlineOnHover
                  >
                    Profile
                  </TextLink>
                </ProfileLinkWrapper>
              ) : (
                <TextLink
                  data-testid="profile"
                  noVisitedColorChange
                  variant="xxxlBold"
                  color="white"
                  underlineOnHover
                  href={profilePath}
                >
                  Profile
                </TextLink>
              )}
            </ProfileItem>
            <ProfileItem>
              <TextLink
                data-testid="help"
                noVisitedColorChange
                variant="xxxlBold"
                color="white"
                target="__blank"
                href="https://help.updater.com/hc/en-us"
                underlineOnHover
              >
                Help
              </TextLink>
            </ProfileItem>
            <ProfileItem>
              <TextLink
                data-testid="log-out"
                noVisitedColorChange
                variant="xxxlBold"
                color="white"
                onClick={onLogoutClick}
                underlineOnHover
              >
                {authText}
              </TextLink>
            </ProfileItem>
          </ProfileMenu>
        )}
      </Flex>
    </HeaderWrapper>
  );
};
