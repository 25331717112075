import React, { useState, useRef, useMemo, FC } from 'react';
import styled from '@emotion/styled';
import { Eye, EyeSlash } from 'phosphor-react';
import { Flex } from '../flex';
import { Error } from '../error';
import { Box } from '../box';
import { IconButton } from '../icon-button';
import { TextProps } from '../text';
import { SpaceProps, ColorProps, Theme } from '../../types/theme-types';
import { Helper } from '../helper';

const nonDigitsRegex = /[^0-9]/;

export interface SSNCodeInputProps {
  /**
   * The label of the Input.
   * @example
   * label = "SSN"
   */
  label: string;
  /**
   * The id of the Input.
   * @example
   * label = "ssn-input-2"
   */
  id?: string;
  /**
   * The isLoading property controls the read-only state of the CodeInput.
   * @example
   * isLoading = true
   */
  isLoading?: boolean;
  /**
   * The error message of the CodeInput component.
   * @example
   * error = "Something went wrong"
   */
  error?: string;
  /**
   * The helper of the Input.
   * @example
   * helper = "This is a helper text"
   */
  helper?: string;
  /**
   * Helper/Error will contain data name for locating the input.
   */
  dataCy?: string;
  /**
   * The onChange will be called when the user changes the code input content
   */
  onChange: (code: string) => void;
  /**
   * The value of the component.
   *
   * Due to legacy support reasons this component can be used controlled or
   * uncontrolled. If you provide a value it is controlled, if you do not it is
   * not. HOWEVER please note that the uncontrolled behavior is deprecated and
   * might be removed in a future release.
   */
  value?: string;
  disabled?: boolean;
}
type InputWrapperProps = Pick<SSNCodeInputProps, 'disabled' | 'error'> &
  SpaceProps &
  ColorProps;

const InputWrapper = styled.input<InputWrapperProps>`
  position: relative;
  text-align: center;
  width: 15px;
  font-size: ${({ theme }) => theme.fontSizes.m}px;
  line-height: ${({ theme }) => theme.lineHeights.m};
  color: ${({ theme }) => theme.colors.primary};
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
  margin-right: ${({ theme }) => theme.space.xxs}px;

  &:focus {
    border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
    outline: none;
  }
  ${({ theme }) => theme.mediaQueries.m} {
    margin-right: ${({ theme }) => theme.space.xs}px;
  }
  &:last-of-type {
    margin-right: 20px;
  }
`;

type HelperColorProps = Pick<SSNCodeInputProps, 'disabled' | 'error'> & {
  theme: Theme;
};

type LabelFocusProps = HelperColorProps & {
  isFocused: boolean;
};

interface FocusProps {
  isFocused: boolean;
}

type TextWrapperProps = Pick<SSNCodeInputProps, 'disabled' | 'error'> &
  TextProps &
  FocusProps;

const getWrapperBorderColor = ({
  theme,
  disabled,
  error,
}: HelperColorProps) => {
  if (disabled) return theme.input.disabledColor;
  if (error) return theme.input.errorColor;
  return theme.input.borderColor;
};

const getWrapperFocusedBorderColor = ({
  theme,
  disabled,
  error,
}: HelperColorProps) => {
  if (disabled) return theme.input.disabledColor;
  if (error) return theme.input.errorColor;
  return theme.input.borderFocusColor;
};

const getWrapperHoveredBorderColor = ({
  theme,
  disabled,
  error,
}: HelperColorProps) => {
  if (disabled) return theme.input.disabledColor;
  if (error) return theme.input.errorColor;
  return theme.input.borderHoverColor;
};

const getLabelColor = ({
  disabled,
  error,
  theme,
  isFocused,
}: LabelFocusProps) => {
  if (disabled) return theme.input.disabledColor;
  if (error) return theme.input.errorColor;
  if (isFocused) return theme.colors.primary;
  return theme.input.labelColor;
};

type ContainerProps = Pick<SSNCodeInputProps, 'disabled' | 'error'> &
  SpaceProps &
  ColorProps;

const ContainerWrapper = styled.div<ContainerProps>`
  border: 1px solid ${(props) => getWrapperBorderColor(props)};
  height: 64px;
  padding: 8px;
  display: flex;
  width: 100%;
  border-radius: 5px;
  transition: box-shadow 300ms;
  &:focus-within {
    border-color: ${(props) => getWrapperFocusedBorderColor(props)};
    box-shadow: 0px 4px 4px rgba(117, 117, 117, 0.25);
  }
  &:hover {
    border-color: ${(props) => getWrapperHoveredBorderColor(props)};
  }
`;

const HyphenWrapper = styled.span`
  margin-right: 5px;
`;

const FieldsWrapper = styled.div`
  width: 100%;
  height: 100%;
  align-self: end;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0;
  grid-auto-columns: min-content;
  align-items: end;
`;

const TextWrapper = styled.label<TextWrapperProps>`
  color: ${(props) => getLabelColor(props)};
  position: absolute;
  font-size: ${({ theme }) => theme.fontSizes.xs}px;
`;

const getEmptyCodeValue = () => Array.from(Array(9)).map(() => '');
export const SSNInput: FC<SSNCodeInputProps> = ({
  label,
  isLoading,
  error,
  helper,
  disabled,
  dataCy,
  onChange,
  id,
  value: valueProp,
  ...props
}) => {
  const controlledComponent = typeof valueProp !== 'undefined';
  const controlledValue = controlledComponent
    ? getEmptyCodeValue().map((empty, i) => valueProp[i] ?? empty)
    : undefined;
  if (!controlledComponent) {
    console.warn(
      'SSNInput is being used as an uncontrolled component. This behavior is deprecated. Please provide a value and use it as a controlled component.'
    );
  }

  const cyName = useMemo(() => {
    if (dataCy) {
      return dataCy;
    }
    return 'unknown';
  }, [dataCy]);

  const className = '';
  // This code is only used for the uncontrolled component version
  const [code, setCode] = useState(getEmptyCodeValue);
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const inputs = useRef<(HTMLInputElement | null)[]>([]);
  const inputValue = controlledValue || code;

  const showIcon = useMemo(() => {
    if (isHidden) {
      return <EyeSlash />;
    }

    return <Eye />;
  }, [isHidden]);

  const onCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    slot: number
  ) => {
    const { value } = e.target;
    if (nonDigitsRegex.test(value)) return;

    const newCode = [...inputValue];
    newCode[slot] = value;
    if (!controlledComponent) {
      setCode(newCode);
    }

    const isNotLastSlot = slot !== 9 - 1;
    if (isNotLastSlot) {
      inputs.current[slot + 1]?.focus();
    }
    onChange(newCode.join(''));
  };

  const onKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    slot: number
  ) => {
    const isBackspaceKeyDown = e.key === 'Backspace';
    const isNotFirstSlotBackspaced =
      isBackspaceKeyDown && !inputValue[slot] && slot !== 0;

    if (isNotFirstSlotBackspaced) {
      const previousSlot = slot - 1;
      const newCode = [...inputValue];

      newCode[previousSlot] = '';
      if (!controlledComponent) {
        setCode(newCode);
      }

      inputs.current[previousSlot]?.focus();

      onChange(newCode.join(''));
    }
  };

  const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = e.clipboardData
      .getData('Text')
      .replace(new RegExp(nonDigitsRegex, 'g'), '');

    /**
     * Ensure that the pasted value's length is always 9 characters long
     * regardless of the pasted input length.
     */
    const pastedCode = Array.from(Array(9)).map(
      (_, idx) => pastedValue[idx] ?? ''
    );

    if (!controlledComponent) {
      setCode(() => [...pastedCode]);
    }

    /**
     * Setting state directly will not trigger `onCodeChange` so we need to
     * call `onChange` here to notify the parent of the change.
     */
    onChange(pastedCode.join(''));
  };

  const onContainerFocused = () => {
    setIsFocused(true);
  };

  const onContainerBlur = () => {
    setIsFocused(false);
  };

  return (
    <>
      <Flex
        {...props}
        justifyContent="start"
        alignItems="center"
        width="100%"
        onFocus={onContainerFocused}
        onBlur={onContainerBlur}
        className={className.concat(' fs-exclude')}
      >
        <ContainerWrapper error={error}>
          <TextWrapper
            htmlFor={id}
            variant="s"
            error={error}
            isFocused={isFocused}
          >
            {label}
          </TextWrapper>
          <FieldsWrapper>
            {inputValue.slice(0, 3).map((num, idx) => {
              const initFocus = !inputValue[0].length && idx === 0;
              return (
                <InputWrapper
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  type={isHidden ? 'password' : 'text'}
                  inputMode="numeric"
                  maxLength={1}
                  value={num}
                  autoFocus={initFocus}
                  readOnly={isLoading}
                  error={error}
                  disabled={disabled}
                  onChange={(e) => onCodeChange(e, idx)}
                  onKeyDown={(e) => onKeyDown(e, idx)}
                  ref={(ref) => inputs.current.push(ref)}
                  onPaste={onPaste}
                  className={className.concat(' fs-exclude')}
                  id={id}
                />
              );
            })}
            <HyphenWrapper>-</HyphenWrapper>
            {inputValue.slice(3, 5).map((num, idx) => {
              return (
                <InputWrapper
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx + 3}
                  type={isHidden ? 'password' : 'text'}
                  inputMode="numeric"
                  maxLength={1}
                  value={num}
                  readOnly={isLoading}
                  error={error}
                  disabled={disabled}
                  onChange={(e) => onCodeChange(e, idx + 3)}
                  onKeyDown={(e) => onKeyDown(e, idx + 3)}
                  ref={(ref) => inputs.current.push(ref)}
                  onPaste={onPaste}
                  className={className.concat(' fs-exclude')}
                />
              );
            })}
            <HyphenWrapper>-</HyphenWrapper>
            {inputValue.slice(5).map((num, idx) => {
              return (
                <InputWrapper
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx + 5}
                  type={isHidden ? 'password' : 'text'}
                  inputMode="numeric"
                  maxLength={1}
                  value={num}
                  readOnly={isLoading}
                  error={error}
                  disabled={disabled}
                  onChange={(e) => onCodeChange(e, idx + 5)}
                  onKeyDown={(e) => onKeyDown(e, idx + 5)}
                  ref={(ref) => inputs.current.push(ref)}
                  onPaste={onPaste}
                  className={className.concat(' fs-exclude')}
                />
              );
            })}
          </FieldsWrapper>
          <IconButton
            type="button"
            onClick={() => setIsHidden(!isHidden)}
            icon={showIcon}
            variant="transparent"
            disabled={disabled}
          />
        </ContainerWrapper>
      </Flex>

      {(error || helper) && (
        <Box minHeight="20px" marginTop="xxs">
          {/* eslint-disable-next-line no-nested-ternary */}
          {error ? (
            <Error as="span" variant="xs" data-cy={`ssninput-error-${cyName}`}>
              {error}
            </Error>
          ) : helper ? (
            <Helper data-cy={`ssninput-helper-${cyName}`}>{helper}</Helper>
          ) : null}
        </Box>
      )}
    </>
  );
};
