import { useMemo } from 'react';
import type { RadioGroupContextType } from './RadioGroupContext';

export type UseRadioGroupOptions = {
  value: RadioGroupContextType['value'];
  onChange: RadioGroupContextType['onChange'];
  onReSelect?: RadioGroupContextType['onReSelect'];
  disabled?: boolean;
  name: string;
  onClick?: (event: any) => any;
};

export const useRadioGroup = (
  options: UseRadioGroupOptions
): RadioGroupContextType => {
  const {
    value,
    onChange,
    disabled = false,
    name,
    onClick,
    onReSelect,
  } = options;

  return useMemo(
    () => ({
      name,
      onChange,
      value,
      disabled,
      onClick,
      onReSelect,
    }),
    [disabled, name, onChange, onClick, value, onReSelect]
  );
};
