import {
  compose,
  margin,
  padding,
  MarginProps,
  PaddingProps,
} from 'styled-system';
import styled from '@emotion/styled';
import { LabeledControl, LabeledControlProps } from '../labeled-control';
import { Radio } from '../radio';

interface LabeledRadioProps
  extends LabeledControlProps,
    PaddingProps,
    MarginProps {}

const LabeledRadioWrapper = styled(LabeledControl)<LabeledRadioProps>`
  ${compose(margin, padding)}
`;

export const LabeledRadio: React.FC<LabeledRadioProps> = ({
  px,
  py,
  mb,
  mr,
  ...rest
}) => {
  return (
    <LabeledRadioWrapper px={px} py={py} mb={mb} mr={mr} {...rest}>
      <Radio {...rest} />
    </LabeledRadioWrapper>
  );
};
