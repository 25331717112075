import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { compose, display, DisplayProps } from 'styled-system';
import { TextVariantKey, Theme } from '../../types/theme-types';
import { ControlProps } from '../../types/control-types';
import { Text } from '../text';

export type LabeledControlSize = 's' | 'm' | 'l';
export type LabeledControlColor = 'green' | 'blue' | 'white';

export interface LabeledControlProps extends ControlProps {
  /**
   * Label text that sits to the right of the input control.
   */
  label: string | React.ReactNode;
  /**
   * If `true` it sets focus styles on pill
   */
  isFocused?: boolean;
  /**
   * Changes the size of the input control.
   *  @example
   *  's' | 'm' | 'l';
   */
  size?: LabeledControlSize;
  /**
   * Changes the color of the input control.
   */
  color?: LabeledControlColor;

  /**
   * Changes the variant of the label
   * @example
   * labelVariant='s'
   */
  labelVariant?: TextVariantKey | Array<TextVariantKey>;

  /**
   * Changes or sets the data-cy attribute of the control
   */
  dataCy?: string;
}

interface LabeledControlWrapperProps extends DisplayProps {
  isFocused: boolean;
  color?: LabeledControlColor;
  disabled?: boolean;
  size: LabeledControlSize;
  htmlFor: string | unknown;
}

interface ControlLabelProps {
  disabled?: boolean;
}

const getColor = (theme: Theme, disabled = false) =>
  disabled
    ? theme.pillControl.selectorColors.disabledColor
    : theme.pillControl.baseColors.color;

const ControlLabel = styled(Text)<ControlLabelProps>`
  color: ${({ disabled, theme }) => getColor(theme, disabled)};
`;

const LabeledControlWrapper = styled.div<LabeledControlWrapperProps>`
  ${compose(display)}
  cursor: pointer;
  width: auto;
  align-items: center;
  color: ${({ disabled, theme }) => getColor(theme, disabled)};

  &::after {
    transition: all 0.1s ease;
  }

  ${({ isFocused, color, theme }) =>
    isFocused &&
    css`
      & input[type='radio']:not(:checked),
      & input[type='checkbox']:not(:checked) {
        outline: solid 1px
          ${color === 'green'
            ? theme.pillControl.baseColors.color
            : theme.pillControl.selectorColors.focusBorderColor};
        outline-offset: 1px;
      }
    `}
`;

export const LabeledControl: React.FC<LabeledControlProps> = ({
  label,
  size = 'm',
  labelVariant = 'm',
  isFocused = false,
  color = 'green',
  children,
  disabled,
  id,
  dataCy,
  ...rest
}) => {
  const cyName = useMemo(() => {
    if (dataCy) {
      return `labeledControl-${dataCy}`;
    }
    if (id) {
      return `labeledControl-${id}`;
    }
    if (rest.name) {
      return `labeledControl-${rest.name}`;
    }
    return 'labeledControl-unknown';
  }, [id, rest.name, dataCy]);

  return (
    <LabeledControlWrapper
      htmlFor={id}
      as="label"
      display="flex"
      isFocused={isFocused}
      color={color}
      disabled={disabled}
      size={size}
      data-cy={cyName}
      {...rest}
    >
      {children}
      <ControlLabel
        as="span"
        variant={labelVariant}
        marginLeft={10}
        disabled={disabled}
        data-cy={cyName}
      >
        {label}
      </ControlLabel>
    </LabeledControlWrapper>
  );
};
