import React, { useState, Ref, RefObject, useRef } from 'react';
import { X, MagnifyingGlass, ArrowRight } from 'phosphor-react';
import styled from '@emotion/styled';
import { Input } from '../input';
import { IconButton } from '../icon-button';
import { Box } from '../box';
import { Center } from '../center';

export interface SearchEvent {
  value: string;
}

export interface SearchInputTrackingEvent {
  verb: 'searched' | 'focused';
  object: 'search_input';
  details: {
    component: 'search_input';
    componentName?: string;
    value: string;
  };
}

interface AdditionalInputProps {
  label?: string;
  onSearch?: (e: SearchEvent) => void;
  onChange?: (e: React.ChangeEvent) => boolean | void;
  onClear?: (e: SearchEvent) => void;
  width?: string | number;
  inputRef?: Ref<HTMLInputElement> | undefined;
  initialValue?: string;
  initialSearchedValue?: string;
  track?: (event: SearchInputTrackingEvent) => void | undefined;
}

export interface SearchInputProps
  extends AdditionalInputProps,
    Omit<
      React.InputHTMLAttributes<HTMLInputElement>,
      'size' | 'prefix' | 'onChange'
    > {}

const ClearInputButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.disabled};
  border-color: ${({ theme }) => theme.colors.disabled};

  &:active {
    background-color: ${({ theme }) => theme.colors.lightGray};
  }

  @media (hover: hover) {
    &:hover {
      border-color: ${({ theme }) => theme.colors.lightGray};
      background-color: ${({ theme }) => theme.colors.lightGray};
    }
  }
`;

const MagnifyingGlassButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.text};
`;

export const SearchInput: React.FC<SearchInputProps> = ({
  id,
  label = 'Search',
  onChange,
  onSearch,
  disabled = false,
  width,
  inputRef,
  onClear,
  initialValue,
  initialSearchedValue,
  name,
  track,
  onFocus,
  ...otherProps
}) => {
  const [currentSearchValue, setCurrentSearchValue] = useState<string>(
    initialSearchedValue || ''
  );
  const [value, setValue] = useState<string>(initialValue || '');
  const showClear =
    (value && currentSearchValue === value) || (!value && currentSearchValue);

  const internalRef: RefObject<HTMLInputElement> =
    useRef<HTMLInputElement>(null);
  const ref: RefObject<HTMLInputElement> = inputRef
    ? (inputRef as RefObject<HTMLInputElement>)
    : internalRef;

  const boxProps: { width?: string | number } = {};
  if (typeof width === 'number') {
    boxProps.width = `${width}px`;
  } else if (typeof width === 'string') {
    boxProps.width = width;
  }

  const search = () => {
    if (disabled) return;
    if (currentSearchValue === value.toString()) return;

    setCurrentSearchValue(value.toString());
    track?.({
      verb: 'searched',
      object: 'search_input',
      details: {
        component: 'search_input',
        componentName: name,
        value,
      },
    });
    onSearch?.({ value: value.toString() });
  };

  const clear = () => {
    if (disabled) return;
    if (currentSearchValue === '') return;

    const previousSearchValue = currentSearchValue;
    setValue('');
    setCurrentSearchValue('');
    track?.({
      verb: 'searched',
      object: 'search_input',
      details: {
        component: 'search_input',
        componentName: name,
        value,
      },
    });
    onSearch?.({ value: '' });
    onClear?.({ value: previousSearchValue });
  };

  const hasFocus = (): Boolean => {
    if (internalRef?.current) {
      return !!(document && document.activeElement === internalRef?.current);
    }

    return false;
  };

  const focus = () => {
    if (internalRef?.current) {
      internalRef.current.focus();
    }
  };

  const getAppropriateIcon = () => {
    if (showClear) {
      return (
        <ClearInputButton
          key="clear-search-button"
          icon={<X weight="bold" />}
          onClick={clear}
          size="s"
          disabled={disabled}
          dataCy="clearSearchButton"
        />
      );
    }
    if (value) {
      return (
        <IconButton
          key="do-search-button"
          icon={<ArrowRight weight="bold" />}
          size="s"
          onClick={search}
          disabled={disabled}
          dataCy="doSearchButton"
        />
      );
    }
    return (
      <MagnifyingGlassButton
        key="focus-search-button"
        icon={<MagnifyingGlass weight="bold" size="18px" />}
        size="s"
        variant="transparent"
        disabled={disabled}
        onClick={focus}
        onMouseDown={(e) => {
          if (hasFocus()) {
            e.stopPropagation();
            e.preventDefault();
          }
        }}
        dataCy="magnifyingGlassButton"
      />
    );
  };

  return (
    <Box {...boxProps}>
      <Input
        id={id}
        label={label}
        aria-label={label}
        onChange={(e) => {
          if (onChange?.(e) !== false) {
            setValue(e.currentTarget.value);
          }
        }}
        disabled={disabled}
        value={value}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            search();
          }
        }}
        onFocus={(e) => {
          track?.({
            verb: 'focused',
            object: 'search_input',
            details: {
              component: 'search_input',
              componentName: name,
              value,
            },
          });
          onFocus?.(e);
        }}
        inputRef={ref}
        rightSlot={<Center vertical>{getAppropriateIcon()}</Center>}
        {...otherProps}
      />
    </Box>
  );
};
