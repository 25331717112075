import { Theme } from '../types/theme-types';
import { Palette } from '../constants/palette';

export const pillControl: Theme['pillControl'] = {
  selectorColors: {
    disabledColor: Palette.gray50,
    hoverBackground: Palette.gray90,
    focusBorderColor: Palette.goGreen,
  },
  baseColors: {
    bg: Palette.gray96,
    borderColor: Palette.gray96,
    color: Palette.darkBlue,
  },
};
