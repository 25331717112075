/**
 * Palette is where the base colors live
 * Palette color names should be descriptive of the color,
 * i.e. it should have the name of color in it and match closely
 * to the Figma style guide
 */

export enum Palette {
  // Redesign 2022
  // *************
  // Note: Please only add colors by value names, not usage.
  // For example, don't put "warningOrange", put "orange"
  // If you feel you need to include usage to differentiate it from
  // another color, speak to design (Ulrik) first and ask what the
  // name of the color should be.
  orange = '#F9C87B',
  green = '#48C66B',

  //= =================================================
  // Beiges
  //= =================================================
  beige = '#D9C9BB',
  beige10 = '#FDF9F5',
  beige20 = '#F7F4F1',
  beige30 = '#ECE4DD',
  beige40 = '#ECE4DD',
  beige50 = '#E6DBD2',
  beige60 = '#DFD2C6',
  //= =================================================
  // Blues
  //= =================================================
  blue = '#62A0F8',
  royalBlue = '#3957E1',
  royalBlue80 = '#2F48BB',
  royalBlue90 = '#263A96',
  darkBlue = '#28293C',
  lightBlue = '#62A0F8',
  primaryBlue = '#01314A',
  blue10 = '#F5F7FD',
  blue20 = '#E0ECFE',
  blue30 = '#D7DDF9',
  blue40 = '#B0CFFB',
  blue50 = '#96C0FA',
  blue60 = '#7CB0F9',
  //= =================================================
  // Corals
  //= =================================================
  coral = '#F8D9D7',
  coral10 = '#FFEEEC',
  coral20 = '#FFE6E4',
  coral30 = '#FFD5D3',
  coral40 = '#FFC0BC',
  coral50 = '#FFACA6',
  coral60 = '#FF9790',
  //= =================================================
  // Mints
  //= =================================================
  mint = '#76E4AF',
  mint10 = '#EEFCF5',
  mint20 = '#E3FAEF',
  mint30 = '#C8F4DF',
  mint40 = '#BAF1D7',
  mint50 = '#A4EDCA',
  mint60 = '#8DE8BC',
  //= =================================================
  // Reds
  //= =================================================
  red = '#EA1616',
  updaterRed = '#F94550',
  //= =================================================
  // Grays
  //= =================================================
  black = '#000000',
  gray10 = '#1A1A1A',
  gray20 = '#333333',
  gray30 = '#4D4D4D',
  gray40 = '#666666',
  gray50 = '#808080',
  gray60 = '#999999',
  gray70 = '#B2B2B2',
  gray80 = '#CCCCCC',
  gray90 = '#E5E5E5',
  gray94 = '#EFEFEF',
  gray96 = '#F5F5F5',
  white = '#FFFFFF',
  transparent = 'rgba(0,0,0,0)',
  //= =================================================
  // Sunset Colors - Soon to be deprecated colors for v4
  //= =================================================
  /**
   * @deprecated "hiYellow" will be deprecated in v4 of ui-uds.
   */
  hiYellow = '#F9CB24',
  /**
   * @deprecated "softBlue" will be deprecated in v4 of ui-uds.
   */
  softBlue = '#7DB8E5',
  /**
   * @deprecated "softerBlue" will be deprecated in v4 of ui-uds.
   */
  softerBlue = '#7DB8E54D',
  /**
   * @deprecated "goGreen" will be deprecated in v4 of ui-uds.
   */
  goGreen = '#62CE91',
  /**
   * @deprecated "petalPink" will be deprecated in v4 of ui-uds.
   */
  petalPink = '#F8D9D7',
  /**
   * @deprecated "sunTan" will be deprecated in v4 of ui-uds.
   */
  sunTan = '#ECE2D9',
  /**
   * @deprecated "confirmationGreen" will be deprecated in v4 of ui-uds.
   */
  confirmationGreen = '#43B929',
  /**
   * @deprecated "warningOrange" will be deprecated in v4 of ui-uds.
   */
  warningOrange = '#F2A328',
  /**
   * @deprecated "errorRed" will be deprecated in v4 of ui-uds.
   */
  errorRed = '#ED0037',
  /**
   * @deprecated "linkBlue" will be deprecated in v4 of ui-uds.
   */
  linkBlue = '#0069A0',
  /**
   * @deprecated "hoverBlue" will be deprecated in v4 of ui-uds.
   */
  hoverBlue = '#0083C8',
}
