import { Palette } from '../constants/palette';
import { Theme } from '../types/theme-types';

export const input: Theme['input'] = {
  backgroundColor: Palette.white,
  color: Palette.darkBlue,
  disabledColor: Palette.gray80,
  borderColor: Palette.gray80,
  borderFocusColor: Palette.black,
  borderHoverColor: Palette.darkBlue,
  errorColor: Palette.errorRed,
  focusedColor: Palette.darkBlue,
  helperColor: Palette.gray40,
  labelColor: Palette.gray40,
};
