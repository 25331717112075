import React from 'react';
import styled from '@emotion/styled';
import { Button } from '../button';
import type { ButtonProps } from '../button/Button';

export type AnchorButtonProps = Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  'type' | 'onClick' | 'target'
>;

export type ButtonLinkProps = ButtonProps &
  AnchorButtonProps & {
    /**
     * The URL that the hyperlink points to
     */
    href: string;
    /**
     * Add target props for link
     */
    // @ts-ignore
    target?: React.HTMLAttributeAnchorTarget;
    /**
     * Defines the relationship between a linked resource and the current document
     */
    rel?: string;
  };

const ButtonLinkWrapper = styled.a<ButtonLinkProps>`
  display: inline-flex;
  text-decoration: none;
  width: ${({ isFluidWidth }) => (isFluidWidth ? '100%' : 'auto')};
`;

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  href,
  target,
  rel,
  isFluidWidth = false,
  ...props
}) => (
  <ButtonLinkWrapper
    isFluidWidth={isFluidWidth}
    href={href}
    target={target}
    rel={rel}
  >
    <Button {...props} isFluidWidth={isFluidWidth} />
  </ButtonLinkWrapper>
);
