import { Theme } from '../types/theme-types';

export const borderRadiuses: Theme['radii'] = {
  none: '0px',
  xxs: '4px',
  xs: '8px',
  s: '16px',
  m: '24px',
  l: '32px',
  xl: '40px',
  xxl: '48px',
  xxxl: '56px',
  '50%': '50%',
};
