import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  compose,
  display,
  margin,
  MarginProps,
  DisplayProps,
} from 'styled-system';
import pick from 'lodash/pick';
import { Theme } from '../../types/theme-types';
import { CHECKBOX_SIZES } from '../radio';
import { LabeledControlProps } from '../labeled-control';
import { Text } from '../text';

const paddingSizes: {
  [size in PillControlSize]: {
    horizontal: number;
    vertical: number;
  };
} = {
  // vertical padding is 1 pixel less to make up for 1px border
  s: {
    horizontal: 8,
    vertical: 7,
  },
  m: {
    horizontal: 24,
    vertical: 11,
  },
  l: {
    horizontal: 16,
    vertical: 11,
  },
};

enum PillSize {
  s = '32px',
  m = '48px',
  l = '64px',
}

export type PillControlSize = 's' | 'm' | 'l';

export interface PillControlProps extends LabeledControlProps, MarginProps {
  /** The right slot of the input control.
   *  @example:
   *  rightSlot="$50/mo"
   */
  rightSlot?: React.ReactNode;
}

const getColor = (theme: Theme, disabled = false) =>
  disabled
    ? theme.pillControl.selectorColors.disabledColor
    : theme.pillControl.baseColors.color;

const RightSlotWrapper = styled.span<RightSlotProps>`
  margin-left: auto;
  align-self: center;
  color: ${({ disabled, theme }) => getColor(theme, disabled)};
  & > svg {
    vertical-align: middle;
    line-height: inherit;
    color: inherit;
  }
`;

const PillControlWrapper = styled.label<PillControlWrapperProps>`
  display: block;
  border: 1px solid;
  background-color: ${({ theme }) => theme.pillControl.baseColors.bg};
  border-color: ${({ theme }) => theme.pillControl.baseColors.borderColor};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  ${({ theme, size }) => {
    const height = PillSize[size];
    return css`
      min-height: ${height};
      border-radius: ${theme.radii.l};
      padding: ${paddingSizes[size].vertical}px
        ${paddingSizes[size].horizontal}px;
    `;
  }}

  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled
        ? theme.pillControl.baseColors.bg
        : theme.pillControl.selectorColors.hoverBackground};
  }

  ${({ isFocused }) =>
    isFocused &&
    css`
      & input[type='radio'],
      & input[type='checkbox'] {
        outline: none;
      }
    `}
  ${compose(display, margin)}
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

interface ControlLabelProps {
  disabled?: boolean;
}

const ControlLabel = styled(Text)<ControlLabelProps>`
  color: ${({ disabled, theme }) => getColor(theme, disabled)};
`;

const pickableMarginProps = [
  'm',
  'margin',
  'mt',
  'marginTop',
  'mb',
  'marginBottom',
  'ml',
  'marginLeft',
  'mr',
  'marginRight',
  'my',
  'marginY',
  'mx',
  'marginX',
];

export const PillControl: React.FC<PillControlProps> = (props) => {
  const {
    label,
    size = 'm',
    isFocused = false,
    children,
    rightSlot,
    disabled,
    id,
  } = props;
  const marginProps: MarginProps = pick(props, pickableMarginProps);

  return (
    <PillControlWrapper
      {...marginProps}
      isFocused={isFocused}
      size={size}
      disabled={disabled}
      htmlFor={id}
    >
      <Inner>
        <Left>
          {children}
          <ControlLabel
            as="span"
            variant="m"
            marginLeft={10}
            disabled={disabled}
          >
            {label}
          </ControlLabel>
        </Left>
        <RightSlotWrapper disabled={disabled}>{rightSlot}</RightSlotWrapper>
      </Inner>
    </PillControlWrapper>
  );
};

interface PillControlWrapperProps extends MarginProps, DisplayProps {
  size: keyof typeof CHECKBOX_SIZES;
  isFocused: boolean;
  disabled?: boolean;
}

interface RightSlotProps {
  disabled?: boolean;
}
