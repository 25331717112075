import { Palette } from '../constants/palette';
import { Theme } from '../types/theme-types';

export const switchVariants: Theme['switchVariants'] = {
  sizes: {
    s: {
      height: '16px',
      width: '27px',
      padding: '1px',
      buttonSize: '14px',
    },
    m: {
      height: '24px',
      width: '40px',
      padding: '2px',
      buttonSize: '20px',
    },
    l: {
      height: '40px',
      width: '67px',
      padding: '3px',
      buttonSize: '34px',
    },
  },
  colorSchemes: {
    blue: {
      background: Palette.blue,
      slider: Palette.white,
      outline: Palette.goGreen,
    },
    green: {
      background: Palette.gray94,
      slider: Palette.goGreen,
      outline: Palette.blue,
    },
    white: {
      background: Palette.gray94,
      slider: Palette.blue,
      outline: Palette.goGreen,
    },
  },
};
