import styled from '@emotion/styled';
import {
  display,
  border,
  color,
  space,
  layout,
  compose,
  alignSelf,
  justifySelf,
  flex,
  flexGrow,
  flexShrink,
  flexBasis,
  order,
  gridColumn,
  gridRow,
  gridArea,
  position,
  system,
  textAlign,
} from 'styled-system';

import {
  StandardLonghandProperties,
  StandardShorthandProperties,
} from 'csstype';

import {
  SpaceProps,
  ColorProps,
  LayoutProps,
  BorderProps,
  DisplayProps,
  BoxFlexboxProps,
  BoxGridProps,
  PositionProps,
  TextAlignProps,
} from '../../types/theme-types';

export interface BoxProps
  extends SpaceProps,
    ColorProps,
    LayoutProps,
    BorderProps,
    DisplayProps,
    BoxFlexboxProps,
    BoxGridProps,
    PositionProps,
    TextAlignProps {
  cursor?: StandardLonghandProperties['cursor'];
  gap?: StandardShorthandProperties['gap'];
  columnGap?: StandardLonghandProperties['columnGap'];
  rowGap?: StandardLonghandProperties['rowGap'];
}

/**
 * Because Box is intended to be a child component, and not a flex, or grid, parent we only
 * support placing these properties on it.
 */
const flexboxParsers = [
  alignSelf,
  justifySelf,
  flex,
  flexGrow,
  flexShrink,
  flexBasis,
  order,
];

const gridParsers = [gridColumn, gridRow, gridArea];

/**
 * A generic layout/wrapper component that lets you apply a range
 * of common styles very quickly,like margins, paddings, colors and grid properties.
 */
export const Box = styled.div<BoxProps>`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
  ${compose(
    position,
    color,
    border,
    display,
    space,
    position,
    layout,
    textAlign,
    ...flexboxParsers,
    ...gridParsers
  )}
  ${system({
    cursor: true,
    columnGap: true,
    rowGap: true,
    gap: true,
  })}
`;
