import React, { ElementType, useMemo } from 'react';
import styled from '@emotion/styled';
import { IconProps, IconContext } from 'phosphor-react';
import {
  compose,
  display,
  margin,
  space,
  variant,
  DisplayProps,
  MarginProps,
} from 'styled-system';
import css, { SystemStyleObject } from '@styled-system/css';
import {
  SpaceProps,
  ButtonVariantKey,
  ButtonSizeKey,
} from '../../types/theme-types';
import type { AnchorButtonProps } from '../button-link';

export type BaseButtonProps = {
  /**
   * Button variant name
   */
  variant?: ButtonVariantKey;
  /**
   * Controls size of button
   */
  size?: ButtonSizeKey;
  /**
   * If `true`, the button will take up the full width of its container
   */
  isFluidWidth?: boolean;
  /**
   * Controls if Button will appear disabled
   */
  disabled?: boolean;
  /**
   * Button will show an icon before the button's label.
   */
  leftIcon?: React.ReactElement<IconProps>;
  /**
   * Button will show an icon after the button's label.
   */
  rightIcon?: React.ReactElement<IconProps>;
  /**
   * Button will contain data name for locating the button.
   */
  dataCy?: string;
  /**
   *
   */
  track?: (event: {
    verb: 'clicked' | 'submitted';
    details: {
      component: 'button';
      componentName: string | undefined;
    };

    /**
     * DEPRECATED use details.component
     */
    component: 'button';
    /**
     * DEPRECATED use details.componentName
     */
    componentName: string | undefined;
  }) => void | undefined;
};

type NativeButtonProps = BaseButtonProps & {
  /**
   * Callback function for onClick event
   */
  onClick?: React.MouseEventHandler<HTMLElement>;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>;

export type ButtonProps = Partial<
  AnchorButtonProps & NativeButtonProps & DisplayProps & MarginProps
> & {
  as?: ElementType;
};

const ButtonIcon = styled.span<SpaceProps>`
  ${space}
  display: flex;
`;

type ButtonWrapperProps = ButtonProps & {
  variant: ButtonVariantKey;
  size: ButtonSizeKey;
};

const borderWidth = '1px';

const baseCss = css({
  borderWidth: `${borderWidth}`,
  fontSize: 's',
  fontWeight: 'semiBold',
  lineHeight: 's',
  cursor: 'pointer',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
  borderStyle: 'solid',
  position: 'relative',
} as SystemStyleObject);

export const ButtonWrapper = styled.button<ButtonWrapperProps>`
  font-family: 'Work Sans', sans-serif;
  ${baseCss}
  width: ${({ isFluidWidth }) => (isFluidWidth ? '100%' : 'auto')};
  padding: ${({ theme, size }) => `0 ${theme.space[size]}px`};
  height: ${({ theme, size }) => theme.button.sizes[size]};
  border-radius: ${({ theme, size }) =>
    theme.button.borderRadii
      ? theme.button.borderRadii[size]
      : theme.button.borderRadius};
  &:active {
    background-color: ${({ theme }) => theme.colors.hover};
    color: ${({ theme }) => theme.button.selectorColors.hoverColor};
  }
  @media (hover: hover) {
    &:hover {
      border-color: ${({ theme }) => theme.colors.hover};
      background-color: ${({ theme }) => theme.colors.hover};
      color: ${({ theme }) => theme.button.selectorColors.hoverColor};
    };
  }
  /* Provide :focus styles for browsers that don't support :focus-visible */
  &:focus,
  &:focus-visible {
     border-color: ${({ theme }) =>
       theme.button.selectorColors.focusBorderColor};
    /** this creates inner border */
    &:before {
      content: '';
      border-radius: ${({ theme, size }) => theme?.button?.borderRadii?.[size]};
      box-shadow: ${({ theme }) =>
        `0 0 0 ${borderWidth} inset ${theme.button.selectorColors.focusInnerBorder}, 0 0 0 ${borderWidth} ${theme.button.selectorColors.focusMiddleBorder}`};
      position: absolute;
      width: 100%;
      height: 100%;
    },
  },
  /* Remove the focus indicator on mouse-focus for browsers that have focus-visible */
  &:focus:not(:focus-visible) {
    border-color: ${({ theme }) => theme.colors.cta};
    outline: none;
    &:before {
      content: none;
    },
  },
  &:disabled {
    color: ${({ theme }) => theme.button.selectorColors.disabledColor};
    background-color: ${({ theme }) => theme.colors.disabled};
    border-color: ${({ theme }) => theme.colors.disabled};
    cursor: default;
  }
  ${({ theme }) =>
    compose(display, margin, variant({ variants: theme.button.variants }))}
`;

export const Button: React.FC<ButtonProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-shadow
  variant = 'primary',
  children,
  size = 'm',
  isFluidWidth = false,
  disabled,
  leftIcon,
  rightIcon,
  dataCy,
  onClick,
  track,
  ...props
}) => {
  const iconContext: IconProps = {
    size: 12,
    weight: 'fill',
    focusable: false,
  };

  const cyName = useMemo(() => {
    if (dataCy) {
      return dataCy;
    }
    if (props.id) {
      return `button-${props.id}`;
    }
    if (props.name) {
      return `button-${props.name}`;
    }
    return 'button-unknown';
  }, [props.id, props.name, dataCy]);

  return (
    <ButtonWrapper
      variant={variant}
      disabled={disabled}
      isFluidWidth={isFluidWidth}
      size={size}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      data-cy={cyName}
      onClick={(e) => {
        track?.({
          verb: props?.type === 'submit' ? 'submitted' : 'clicked',
          component: 'button',
          componentName: props?.name,
          details: {
            component: 'button',
            componentName: props?.name,
          },
        });
        onClick?.(e);
      }}
      {...props}
    >
      {leftIcon && (
        <ButtonIcon mr="xs">
          <IconContext.Provider value={iconContext}>
            {leftIcon}
          </IconContext.Provider>
        </ButtonIcon>
      )}
      {children}
      {rightIcon && (
        <ButtonIcon ml="xs">
          <IconContext.Provider value={iconContext}>
            {rightIcon}
          </IconContext.Provider>
        </ButtonIcon>
      )}
    </ButtonWrapper>
  );
};
