import React from 'react';
import styled from '@emotion/styled';
import { Flex } from '../flex';
import { Box } from '../box';

export interface SegmentedControlAreaProps {
  children?: React.ReactNode;
  activeValue: string;
  value: string;
  /**
   * option to enable/disable transitions in the tab area
   * default is fade
   */
  transition?: 'fade' | 'none';
}

type StyleType = Pick<SegmentedControlAreaProps, 'transition'> & {
  active: boolean;
};

export const SegmentedControlAreaWrapper = styled(Flex)`
  width: 100%;
  flex-wrap: wrap;
  position: relative;
`;

const SegmentedControlAreaInner = styled(Box)<StyleType>`
  width: 100%;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  ${({ transition, active }) =>
    transition === 'fade'
      ? `
    visibility: ${active ? 'visible' : 'hidden'};
    opacity: ${active ? '1' : '0'};
    transition: visibility 1s, opacity 0.3s linear;
  }
  `
      : `
      visibility: ${active ? 'visible' : 'hidden'};
      `}
`;

/**
 * SegmentedControlArea
 *
 * @description This component implement a tab area.
 *
 * @component
 * @example
 *
 *const options = [
 *  {
 *     label: 'Value 1',
 *     value: 'value_1',
 *   },
 *  {
 *     label: 'Value 2',
 *     value: 'value_2',
 *   },
 * ];
 *
 * const [value, setValue] = useState('value_1');
 *
 * return (
 *   <SegmentedControlArea
 *     value={options[0].value}
 *     activeValue={value}
 *   >
 *    <div> Tab 1 Content </div>
 *   </SegmentedControlArea>
 * )
 */
export const SegmentedControlArea: React.FC<SegmentedControlAreaProps> = ({
  value,
  activeValue,
  transition = 'fade',
  children,
  ...rest
}) => {
  return (
    <SegmentedControlAreaInner
      {...rest}
      transition={transition}
      active={value === activeValue}
    >
      {children}
    </SegmentedControlAreaInner>
  );
};
