import TextareaAutosize from 'react-textarea-autosize';
import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { space } from 'styled-system';
import css from '@styled-system/css';
import { Box } from '../box';
import { Text } from '../text';
import { SpaceProps, Theme } from '../../types/theme-types';
import { Error } from '../error';

export interface TextareaProps {
  /**
   * The label of the Input.
   * @example
   * label = "Users feedback using the application in lengthy detail"
   * maxRows=4
   * minRows=1
   */
  label: string;
  /**
   * The error message of the input.
   * @example
   * error = "Something went wrong"
   */
  error?: string;
  /**
   * The helper of the Input.
   * @example
   * helper = "This is a helper text"
   */
  helper?: string;
  /**
   * The left slot of the Input.
   * @example
   * leftSlot = {<Icon></Icon>}
   */
  leftSlot?: ReactNode;
  /**
   * The left slot of the Input.
   * @example
   * rightSLot = {<a href="google.com" target={"_blank"} >Learn more</a>}
   */
  rightSlot?: ReactNode;
  /**
   * If `true`, the element will be disabled.
   * It will set the `disabled` HTML attribute
   * @example
   * disabled
   */
  disabled?: boolean;
  /**
   * Maximum row height
   */
  maxRows?: number;
  /**
   * Minimum row height
   */
  minRows?: number;
}

type Omitted = 'size' | 'prefix' | 'type' | 'height' | 'style';
interface TextareaInputProps
  extends TextareaProps,
    Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, Omitted> {}
type HelperColorProps = Pick<TextareaProps, 'disabled' | 'error'> & {
  theme: Theme;
};

const getContainerBorderColor = ({
  theme,
  disabled,
  error,
}: HelperColorProps) => {
  if (disabled) return theme.input.disabledColor;
  if (error) return theme.input.errorColor;
  return theme.input.borderColor;
};
const getContainerFocusedBorderColor = ({
  theme,
  disabled,
  error,
}: HelperColorProps) => {
  if (disabled) return theme.input.disabledColor;
  if (error) return theme.input.errorColor;
  return theme.input.borderFocusColor;
};
const getContainerHoveredBorderColor = ({
  theme,
  disabled,
  error,
}: HelperColorProps) => {
  if (disabled) return theme.input.disabledColor;
  if (error) return theme.input.errorColor;
  return theme.input.borderHoverColor;
};
type ContainerProps = Pick<TextareaProps, 'disabled' | 'error'> & SpaceProps;
const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => getContainerBorderColor(props)};
  border-radius: 8px;
  min-height: 64px;
  background-color: ${(props) => props.theme.input.backgroundColor};
  transition: box-shadow 300ms;
  &:focus-within {
    border-color: ${(props) => getContainerFocusedBorderColor(props)};
    box-shadow: 0px 4px 4px rgba(117, 117, 117, 0.25);
    padding-bottom: ${(props) => props.theme.space.s}px;
  }
  &:hover {
    border-color: ${(props) => getContainerHoveredBorderColor(props)};
  }
  ${space};
`;
const getFocusedLabelColor = ({ disabled, error, theme }: HelperColorProps) => {
  if (disabled) return theme.input.disabledColor;
  if (error) return theme.input.errorColor;
  return theme.input.focusedColor;
};
const getLabelColor = ({ disabled, error, theme }: HelperColorProps) => {
  if (disabled) return theme.input.disabledColor;
  if (error) return theme.input.errorColor;
  return theme.input.labelColor;
};
type LabelProps = Pick<TextareaProps, 'disabled' | 'error'>;
const LabelBaseStyles = css({
  fontSize: 'm',
  fontWeight: 'regular',
  'textarea:focus-within ~ &, textarea:focus-within:not(:placeholder-shown) ~ &':
    {
      fontSize: 'xs',
      fontWeight: 'semiBold',
    },
  'textarea:focus:not(:focus-within) ~ &, textarea:not(:focus-within):not(:placeholder-shown) ~ &':
    {
      fontSize: 'xs',
      fontWeight: 'regular',
    },
});
const Label = styled.label<LabelProps>`
  ${LabelBaseStyles}
  color: ${(props) => getLabelColor(props)};
  font-family: 'Work Sans', sans-serif;
  position: absolute;
  pointer-events: none;
  top: calc(64px / 3);
  transition: transform 0.1s ease-in-out;
  textarea:focus-within ~ &, textarea:not(:placeholder-shown) ~ & {
    transform: translate3d(0, -10px, 0);
    color: ${(props) => getFocusedLabelColor(props)};
  },
  textarea:not(:focus-within) ~ &, textarea:not(:focus-within):not(:placeholder-shown) ~ & {
    color: ${(props) => getLabelColor(props)};
  },
`;
const Helper = styled(Text)`
  color: ${(props) => props.theme.input.helperColor};
`;
const InputWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  textarea:focus-within ~ &,
  textarea:not(:placeholder-shown) ~ & {
    padding-top: 32px;
  }
  ,
  textarea:not(:focus-within) ~ &,
  textarea:not(:focus-within):not(:placeholder-shown) ~ & {
    padding-top: 32px;
  }
`;
const StyledTextareaBaseStyles = css({
  fontSize: 'm',
  fontWeight: 'regular',
  lineHeight: 'm',
});
const StyledInput = styled(TextareaAutosize)`
  ${StyledTextareaBaseStyles}
  display: block;
  width: 100%;
  height: auto;
  color: ${(props) => props.theme.input.color};
  background-color: inherit;
  border: none;
  outline: none;
  padding: 0;
  // padding-top: 32px;
  box-sizing: border-box;
  font-family: 'Work Sans', sans-serif;
`;
const Slot = styled.div<SpaceProps>`
  white-space: nowrap;
  vertical-align: middle;
  ${space}
`;
export const Textarea: React.FC<TextareaInputProps> = ({
  label,
  error,
  helper,
  leftSlot,
  rightSlot,
  disabled,
  minRows,
  maxRows,
  ...props
}) => (
  <>
    <Container paddingX="s" disabled={disabled} error={error}>
      {leftSlot && <Slot marginRight="xs">{leftSlot}</Slot>}
      <InputWrapper>
        <StyledInput
          minRows={minRows}
          maxRows={maxRows}
          placeholder=" "
          {...props}
        />
        <Label disabled={disabled} error={error} htmlFor={props?.id}>
          {label}
        </Label>
      </InputWrapper>
      {rightSlot && <Slot marginLeft="xs">{rightSlot}</Slot>}
    </Container>
    <Box minHeight="22px" marginTop="xxs">
      {/* eslint-disable-next-line no-nested-ternary */}
      {error ? (
        <Error as="span" variant="xs">
          {error}
        </Error>
      ) : helper ? (
        <Helper as="span" variant="xs">
          {helper}
        </Helper>
      ) : null}
    </Box>
  </>
);
