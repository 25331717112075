import { Palette } from '../constants/palette';
import { Theme } from '../types/theme-types';

// this assumes more variants will come such as "error"
export const alertVariants: Theme['alertVariants'] = {
  variants: {
    success: {
      color: Palette.confirmationGreen,
      borderColor: Palette.confirmationGreen,
      borderRadius: 'xxs',
    },
    error: {
      color: Palette.errorRed,
      borderColor: Palette.errorRed,
      borderRadius: 'xxs',
    },
    info: {
      color: Palette.blue,
      borderColor: Palette.blue,
      borderRadius: 'xxs',
    },
    warning: {
      color: Palette.warningOrange,
      borderColor: Palette.warningOrange,
      borderRadius: 'xxs',
    },
  },
};
