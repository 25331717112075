import {
  ComponentProps,
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { Global, css } from '@emotion/react';
import styled from '@emotion/styled';
import { position } from 'styled-system';
import { X } from 'phosphor-react';
import { Box } from '../box';
import { Flex } from '../flex';
import { Text } from '../text';

type FlexProps = ComponentProps<typeof Flex>;
type BoxProps = ComponentProps<typeof Box>;

// global styles that get applied when the modal is open
const modalOpenGlobalStyles = css`
  html {
    overflow: hidden;

    &::webkit-scrollbar {
      display: none;
    }
  }
  body {
    overflow: 'hidden';
  }
`;

// this is shared by all components that contain content to maintain consistent padding
const contentPaddingProp: FlexProps['p'] = ['s', 's', 'l'];

const fullScreenProps: Partial<FlexProps> = {
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

const ModalFlexWrapper = styled(Flex)`
  ${position}
`;

export type ModalProps = FlexProps & {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export type ModalContextType = Pick<ModalProps, 'isOpen'> & {
  toggleModal: () => void | Promise<void>;
  openModal: () => void | Promise<void>;
  closeModal: () => void | Promise<void>;
};

export const ModalContext = createContext<ModalContextType>(
  {} as ModalContextType
);

export const Modal: FC<ModalProps> = (props) => {
  const { isOpen, setIsOpen } = props;

  // TODO: replace the following 3 functions with `useDisclosure`
  const openModal = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const toggleModal = useCallback(() => {
    setIsOpen((previousValue) => !previousValue);
  }, [setIsOpen]);

  // function used as listener for `keydown` event
  const handleKeydown = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    },
    [setIsOpen]
  );

  // this effect adds / removes the `keydown` event listener
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleKeydown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [isOpen]);

  const context = useMemo(
    () => ({
      closeModal,
      openModal,
      toggleModal,
      isOpen,
    }),
    [closeModal, openModal, toggleModal, isOpen]
  );

  return (
    <ModalContext.Provider value={context}>
      {isOpen && <Global styles={modalOpenGlobalStyles} />}

      {isOpen && (
        <ModalFlexWrapper
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          {...fullScreenProps}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          backgroundColor="transparent"
          zIndex="notificationLow"
          {...props}
        />
      )}
    </ModalContext.Provider>
  );
};

export type ModalOverlayProps = BoxProps & {
  handleClick?: () => void | Promise<void>;
};

const ModalOverlayWrapper = styled(Box)`
  ${position}
`;

export const ModalOverlay: FC<ModalOverlayProps> = (props) => {
  const { handleClick } = props;
  const { closeModal } = useContext(ModalContext);

  return (
    <ModalOverlayWrapper
      {...fullScreenProps}
      backgroundColor="primary"
      opacity={0.8}
      zIndex="notificationLow"
      onClick={() => {
        if (handleClick) {
          handleClick();
        }
        closeModal();
      }}
      {...props}
    />
  );
};

export type ModalContentProps = FlexProps & {
  includeVerticalPadding?: boolean;
};

export const ModalContent: FC<ModalContentProps> = (props) => {
  return (
    <Box
      zIndex="notificationLow"
      maxWidth={['100%', '100%', '75%']}
      minWidth={[undefined, undefined, '50%']}
      width={['100%', '100%', 'auto']}
      maxHeight={['100%', '100%', '75%']}
      height={['100%', '100%', 'auto']}
      position="relative"
      flexDirection="column"
      backgroundColor="secondary"
      borderRadius={['none', 'none', 'xs']}
      overflowY="auto"
      {...props}
    />
  );
};

export const ModalTitle: FC<ModalTitleProps> = (props) => (
  <Text id="modal-title" variant="lBold" {...props} />
);

type ModalSubtitleProps = ComponentProps<typeof Text>;

export const ModalSubtitle: FC<ModalSubtitleProps> = (props) => (
  <Text id="modal-description" variant="m" mt="4px" mb="m" {...props} />
);

export type ModalHeaderProps = FlexProps & {
  hideHeaderBorder?: boolean;
};

const ModalHeaderLine = styled(Box)`
  margin-top: auto;
`;

export const ModalHeader: FC<ModalHeaderProps> = (props) => {
  const { children, hideHeaderBorder, ...rest } = props;

  return (
    <Flex
      flexDirection="column"
      paddingTop={contentPaddingProp}
      paddingRight={contentPaddingProp}
      paddingLeft={contentPaddingProp}
      width="100%"
      position="relative"
      minHeight="108px"
      {...rest}
    >
      {children}

      {!hideHeaderBorder && (
        <ModalHeaderLine
          marginBottom="m"
          borderBottom="1px solid #CCCCCC"
          width="100%"
        />
      )}
    </Flex>
  );
};

export type ModalBodyProps = FlexProps;

export const ModalBody: FC<ModalBodyProps> = (props) => (
  <Flex
    marginBottom={contentPaddingProp}
    paddingX={contentPaddingProp}
    flexGrow={1}
    {...props}
  />
);

export type ModalFooterProps = FlexProps;

export const ModalFooter: FC<ModalFooterProps> = (props) => (
  <Flex
    marginTop={contentPaddingProp}
    marginRight={contentPaddingProp}
    marginBottom="m"
    marginLeft={contentPaddingProp}
    {...props}
  />
);

type ModalTitleProps = ComponentProps<typeof Text>;

type ModalCloseButtonProps = ComponentProps<typeof X>;

const ModalCloseButtonWrapper = styled(Flex)<
  FlexProps & React.HTMLProps<HTMLButtonElement>
>`
  background: none;
  border: none;
  cursor: pointer;
`;

export const ModalCloseButton: FC<ModalCloseButtonProps> = (props) => {
  const { closeModal } = useContext(ModalContext);

  return (
    <ModalCloseButtonWrapper
      position="absolute"
      right={contentPaddingProp}
      top={contentPaddingProp}
      onClick={closeModal}
      as="button"
      type="button"
    >
      <X {...props} />
    </ModalCloseButtonWrapper>
  );
};
