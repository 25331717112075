import React from 'react';
import styled from '@emotion/styled';
import { CircleWavyCheck } from 'phosphor-react';
import { variant } from 'styled-system';
import { css, SystemStyleObject } from '@styled-system/css';
import { Box, BoxProps } from '../box';
import { Image } from '../image';

export interface AvatarProps extends Omit<BoxProps, 'color'> {
  size?: 's' | 'm' | 'l';
  name: string;
  src: string;
  showBadge?: boolean;
}

const IconStyles = css({
  '& > .avatar-icon': {
    position: 'absolute',
    display: 'block',
    top: '0',
    right: '0',
    width: '40%',
    transform: 'translateX(25%)',
    zIndex: 'content',
    fill: 'confirmation',
  },
} as SystemStyleObject);

const AvatarContainer = styled(Box)`
  ${IconStyles}
  ${(props) => variant({ variants: props.theme.avatarVariants, prop: 'size' })}
`;

export const Avatar: React.FC<AvatarProps> = ({
  size = 'm',
  name,
  src,
  showBadge,
  ...props
}) => (
  <AvatarContainer
    {...props}
    size={size}
    display="inline-block"
    position="relative"
  >
    <Image
      display="block"
      src={src}
      alt={name}
      objectFit="cover"
      width="100%"
      height="100%"
      borderRadius="50%"
    />
    {showBadge && <CircleWavyCheck className="avatar-icon" weight="fill" />}
  </AvatarContainer>
);
