import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import {
  TrackerConfig,
  TrackEvent,
  TrackEventUser,
  TrackEventInput,
  TrackEventViewport,
  EventTypeMap,
  EventTypeMapSpec,
  EventType,
  EventTypeSpec,
  OptimizelyClientWithTrack,
} from './types';
import { makeEventStreamTrack } from './eventstream';
import getViewportMetrics from './viewport';
import { registerEventMap } from './utils';
// eslint-disable-next-line import/no-cycle
import { TrackEventVerb } from '.';
import { logger } from './logger';
import { makeOptimizelyTrack } from './optimizely';

const isServer = typeof window === 'undefined';

/**
 * Need to hack the dom Navigator api typings to use legacy properties
 */
interface NavigatorConnection {
  type: string;
  effectiveType: string;
}

interface Navigator {
  mozConnection: NavigatorConnection;
  webkitConnection: NavigatorConnection;
  connection: NavigatorConnection;
}

declare const navigator: Navigator;

interface TrackerConfigByParent<ThisEventTypeMap extends EventTypeMap> {
  parentTracker: Tracker<EventTypeMap>;
  eventTypeMap: ThisEventTypeMap;
}

/**
 * Main API for doing things with the tracker lib. Create and instance
 * and then use `tracker.track()`.
 */
class Tracker<ThisEventTypeMap extends EventTypeMap> {
  Events: EventTypeMapSpec<ThisEventTypeMap>;

  private config: TrackerConfig<ThisEventTypeMap>;

  private currentViewportMetrics: TrackEventViewport;

  private internalCurrentUser: TrackEventUser | null;

  private parentTracker: Tracker<EventTypeMap> | null;

  private eventStreamTrack?(event: TrackEvent): Promise<void | Response>;

  private optimizelyTrack?(event: TrackEvent): void;

  private queue: Array<TrackEvent>;

  private dependantTrackers: Array<Tracker<EventTypeMap>>;

  private appName: string;

  private trackerInterval: ReturnType<typeof setTimeout> | undefined;

  /**
   * Creates an instance of a tracker
   * @param config Configuration options
   */
  constructor(
    config:
      | TrackerConfig<ThisEventTypeMap>
      | TrackerConfigByParent<ThisEventTypeMap>
  ) {
    this.parentTracker = null;
    if ('parentTracker' in config) {
      this.config = {
        ...config.parentTracker.config,
        eventTypeMap: config.eventTypeMap,
      };
      this.parentTracker = config.parentTracker;
    } else {
      this.config = config;
    }

    this.currentViewportMetrics = getViewportMetrics();
    this.internalCurrentUser = null;
    this.queue = [];
    this.dependantTrackers = [];
    this.appName = this.config.appName || 'mover';

    this.Events = registerEventMap(config.eventTypeMap);
    if (this.config.destinations?.eventstream) {
      this.eventStreamTrack = makeEventStreamTrack(
        this.config.destinations?.eventstream
      );
    }

    if (this.config.destinations?.optimizely?.client) {
      this.optimizelyTrack = makeOptimizelyTrack(
        this.config.destinations.optimizely.client
      );
    }

    // Unfortunately we can't overload with arrow functions
    this.track = this.track.bind(this);

    if (this.config.destinations && !isServer) {
      this.trackerInterval = setInterval(() => {
        this.sendEvents();
      }, 500);
      window.addEventListener('beforeunload', () => {
        this.sendEvents();
      });
    } else {
      this.trackerInterval = undefined;
    }
  }

  destroy() {
    if (this?.trackerInterval) {
      clearInterval(this.trackerInterval);
      this.sendEvents();
    }
  }

  get currentUser(): TrackEventUser | null {
    return this?.parentTracker?.currentUser || this.internalCurrentUser;
  }

  attachDependantTracker(dependantTracker: Tracker<EventTypeMap>): void {
    this.dependantTrackers.push(dependantTracker);
    if (isServer) {
      this.sendEvents();
    }
  }

  /**
   * Adds events to the queue
   */
  private enqueue = (event: TrackEvent): void => {
    if (this.parentTracker) {
      this.parentTracker.enqueue(event);
    } else {
      this.queue.push(event);
      if (isServer) {
        this.sendEvents();
      }
    }
  };

  /**
   * Consumes events off the queue and fires them
   * to the configured destinations
   */
  private sendEvents = async () => {
    if (!this.eventStreamTrack && !this.optimizelyTrack) {
      return;
    }
    while (this.queue.length > 0) {
      const event = this.queue[0];
      if (this.eventStreamTrack) {
        this.eventStreamTrack(event);
      }
      if (this.optimizelyTrack) {
        this.optimizelyTrack(event);
      }
      this.queue.shift();
    }
    this.dependantTrackers.forEach((dependantTracker) => {
      while (dependantTracker.queue.length > 0) {
        const event = dependantTracker.queue[0];
        event.page.environment = this.config.environment;
        this.enqueue(event);
        dependantTracker.queue.shift();
      }
    });
  };

  /**
   * Generates a new event object
   * @param eventInput The unique event attributes provided by the caller
   * @returns A set of generic attributes to be passed a long with a new event
   */
  private buildEvent = <Details>({
    domain = 'unknown',
    object,
    verb,
    details,
    pageName,
    appName,
  }: TrackEventInput<Details>): TrackEvent => {
    if (typeof window === 'undefined' || window.navigator === undefined) {
      throw Error('Cannot call track when not in browser.');
    }

    const connection =
      typeof navigator !== 'undefined' &&
      (navigator.connection ||
        navigator.mozConnection ||
        navigator.webkitConnection);

    let network: { type: string; effectiveType: string } = {
      type: 'unknown',
      effectiveType: 'unknown',
    };
    if (connection) {
      const { type, effectiveType } = connection;
      network = { type, effectiveType };
    }

    let source;

    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search);
      source = params.get('src');
    }

    return {
      channel: this.config.channel,
      domain,
      object,
      verb,
      timestamp: Date.now(),
      page: {
        app: appName || 'mover',
        environment: this.config.environment,
        userAgent: window.navigator.userAgent,
        url: window.location.href,
        name: pageName || document.title,
      },
      details: {
        url_source: source || '',
        network,
        viewport: { ...this.currentViewportMetrics },
        ...details,
      },
      user: this.currentUser ? cloneDeep(this.currentUser) : {},
    };
  };

  /**
   * Tracks a new event. You need to pass a special Events object that can be pulled
   * off the tracker:
   *
   * ```
   * const { track, Events } = new Tracker(...);
   *
   * track(Events.offers.landing_page.changed, {...});
   * ```
   *
   * @param eventInput TrackEventInput for new event
   */
  public track<Details extends undefined>(
    eventInput: EventTypeSpec<Details>
  ): void;
  public track<Details extends Record<string, unknown>>(
    eventInput: EventType<Details>,
    details: Details
  ): void;

  public track<Details>(
    eventInput: EventTypeSpec<Details>,
    details?: Details,
    pageName?: string
  ) {
    const event = this.buildEvent({
      domain: eventInput.path[0],
      object: eventInput.path[1],
      verb: eventInput.path[2] as TrackEventVerb,
      details: details || {},
      appName: this.appName,
      pageName,
    });

    this.enqueue(event);
    if (this.config.debug) {
      logger.log(event);
    }
  }

  /**
   * Tracks a new event
   * @param eventInput TrackEventInput for new event
   * @deprecated `track()` should be used instead. This has been implemented
   * for backwards compaitbility with the
   * existing mover app.
   */
  trackLegacy = <Details>(eventInput: TrackEventInput<Details>) => {
    const event = this.buildEvent(eventInput);
    this.enqueue(event);
    if (this.config.debug) {
      logger.log(event);
    }
  };

  /**
   * Tracks a new event
   * @param eventInput TrackEventInput an object containing at least domain,
   * object, verb.
   */
  trackUntyped = <Details>(eventInput: TrackEventInput<Details>) => {
    if (eventInput.verb === 'sent') {
      console.warn(
        'Be careful with `sent` events, as they can cause side effects and have very specific data needs!'
      );
    }
    if (typeof eventInput.domain !== 'string') {
      console.warn('No domain provided on event', eventInput);
    }
    if (typeof eventInput.verb !== 'string') {
      console.warn('No verb provided on event', eventInput);
    }
    if (typeof eventInput.object !== 'string') {
      console.warn('No object provided on event', eventInput);
    }

    const event = this.buildEvent(eventInput);
    this.enqueue(event);
    if (this.config.debug) {
      logger.log(event);
    }
  };

  /**
   * Registers a new set of user attributes into the tracker, which will get passed a
   * long with every event.
   * Call this when a user logs in or out, or when you need to update user properties.
   *
   * Pass `null` when the user logs out.
   * @param user The user properties
   */
  identify = (user: TrackEventUser | null) => {
    if (this.parentTracker) {
      throw new Error(
        'All identify calls must be made on the root Tracker instance'
      );
    }
    const newUser = merge(this.currentUser, user);
    this.internalCurrentUser = newUser;
    this.dependantTrackers.forEach((dependantTracker) => {
      dependantTracker.identify(user);
    });
  };

  /**
   * Set the optimizely client. Used if the optimizely client changes after
   * initial initializaton.
   *
   * @param client The new Client to attach
   */
  attachOptimizelyClient = (client: OptimizelyClientWithTrack) => {
    this.optimizelyTrack = makeOptimizelyTrack(client);
  };

  /**
   * Creates a domain specific Tracker that inherits everything but the eventTypeMap
   *
   * @param eventTypeMap the event definitions for this domain tracker
   * @returns a new Tracker instance linked to the first
   */
  createDomainTracker = <DomainEventTypeMap extends EventTypeMap>(
    eventTypeMap: DomainEventTypeMap
  ): Tracker<DomainEventTypeMap> => {
    return new Tracker({
      parentTracker: this,
      eventTypeMap,
    });
  };
}

export default Tracker;
