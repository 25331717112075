import React, { useMemo } from 'react';
import { Box } from '../box';

export type Width = 1 | 0.5 | 0.25;

interface FieldWrapperProps {
  /**
   *
   * Width is an integer or array of integers that map to a
   * percentage width of the FieldWrapper
   * 1 = 100%, 0.5 = 50%, 0.25 = 25%
   * Passing an array maps to the breakpoints in the theme:
   * [xs, ...xl]
   */
  width?: Width | Width[];
}

const getWidthPixelsFromWidthProp = (width: Width) => {
  switch (width) {
    case 1:
      return '100%';
    case 0.5:
      return 'calc(50% - 8px)';
    case 0.25:
      return 'calc(25% - 8px)';
    default:
      return '100%';
  }
};

export const FieldWrapper: React.FC<FieldWrapperProps> = ({
  children,
  width = 1,
}) => {
  const widthPixels = useMemo(() => {
    if (Array.isArray(width)) {
      return width.map((v) => getWidthPixelsFromWidthProp(v));
    }
    return getWidthPixelsFromWidthProp(width);
  }, [width]);

  return (
    <Box width={widthPixels} mb="s">
      <Box>{children}</Box>
    </Box>
  );
};
