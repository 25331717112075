import { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { useMatchMedia } from './useMatchMedia';

/**
 * @typedef {Object} QueryResults
 * @property {boolean} isExtraSmall - boolean that dictates if screen is extra small
 * @property {boolean} isSmall - boolean that dictates if screen is small
 * @property {boolean} isMobile - boolean that dictates if screen is mobile
 * @property {boolean} isMedium - boolean that dictates if screen is medium
 * @property {boolean} isLarge - boolean that dictates if screen is large
 * @property {boolean} isExtraLarge - boolean that dictates if screen is large
 */
interface ViewportSizes {
  isExtraSmall: boolean;
  isSmall: boolean;
  isMobile: boolean;
  isMedium: boolean;
  isLarge: boolean;
  isExtraLarge: boolean;
}

export function useViewportSizes(): ViewportSizes {
  const { breakpoints } = useTheme();
  const mediaQueryList = useMemo(
    () => [
      `(max-width: calc(${breakpoints[0]} - 1px))` /* xs */,
      `(min-width: ${breakpoints[0]}) and (max-width: calc(${breakpoints[1]} - 1px))` /* s */,
      `(max-width: calc(${breakpoints[1]} - 1px))` /* mobile */,
      `(min-width: ${breakpoints[1]}) and (max-width: calc(${breakpoints[2]} - 1px))` /* m */,
      `(min-width: ${breakpoints[2]}) and (max-width: calc(${breakpoints[3]} - 1px))` /* l */,
      `(min-width: ${breakpoints[3]})` /* xl */,
    ],
    [breakpoints]
  );
  const matches = useMatchMedia(mediaQueryList);

  return {
    isExtraSmall: matches[0],
    isSmall: matches[1],
    isMobile: matches[2],
    isMedium: matches[3],
    isLarge: matches[4],
    isExtraLarge: matches[5],
  };
}
