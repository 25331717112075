import styled from '@emotion/styled';
import { Text, TextProps } from '../text';

export interface ErrorProps extends TextProps {}

/**
 * Component that is used as an ERROR text for form fields.
 */

export const Error = styled(Text)<ErrorProps>`
  color: ${({ theme }) => theme.input.errorColor};
`;

Error.defaultProps = {
  as: 'span',
  variant: 'xs',
};
