import snakeCase from 'lodash/snakeCase';
import { logger } from './logger';
import {
  TrackEvent,
  OptimizelyUserAttributes,
  OptimizelyClientWithTrack,
} from './types';

type Attribute = number | string | boolean | OptimizelyUserAttributes;

type FlattenFn = (source: Attribute, path: string) => OptimizelyUserAttributes;
export function flatten(delimiter = '.'): FlattenFn {
  return function recurse(
    source: Attribute,
    path: string
  ): OptimizelyUserAttributes {
    if (typeof source === 'undefined') {
      return {};
    }

    if (typeof source !== 'object' || !Object.keys(source).length) {
      return { [path]: source };
    }

    return Object.keys(source).reduce((obj, key) => {
      const value = source[key];
      if (Array.isArray(value)) return obj;

      const deeperPath = path ? path + delimiter + key : key;
      return Object.assign(obj, recurse(value, deeperPath));
    }, {});
  };
}

export function generateEventName({ domain, object, verb }: TrackEvent) {
  return [domain || '_', object || '_', verb || '_']
    .map((item) => snakeCase(item))
    .join('.');
}

export function makeOptimizelyTrack(
  optimizelyClient: OptimizelyClientWithTrack
) {
  const flattenFn = flatten();
  return (event: TrackEvent) => {
    if (!optimizelyClient || !optimizelyClient.track) {
      logger.log(
        'NO OPTIMIZELY CLIENT FOUND TO TRACK EVENT. Not tracking event.'
      );
      return;
    }
    const eventKey = generateEventName(event);
    const eventTags = flattenFn(event, '');
    logger.log('Sending Optimizely event:', eventKey, eventTags);
    optimizelyClient.track(eventKey, undefined, eventTags);
  };
}
