import React from 'react';
import styled from '@emotion/styled';
import { baseStyles } from '../text-link';
import { Text } from '../text';

export interface TextButtonProps {
  /**
   * Callback function for onClick event
   */
  onClick: React.MouseEventHandler<HTMLElement>;
}

const TextButtonWrapper = styled(Text)`
  ${baseStyles}
  &:hover {
    color: ${(props) => props.theme.colors.hover};
  }
`;

TextButtonWrapper.defaultProps = {
  color: 'inherit',
  variant: 'inherit',
};

export const TextButton: React.FC<TextButtonProps> = ({
  onClick,
  ...props
}) => <TextButtonWrapper onClick={onClick} {...props} />;
