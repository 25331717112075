import {
  ComponentProps,
  ComponentType,
  FC,
  useCallback,
  useContext,
} from 'react';
import styled from '@emotion/styled';
import { RadioGroupContext } from './RadioGroupContext';
import type { RadioOption } from './index';
import { Flex } from '../flex';
import { Text } from '../text';
import { Spinner } from '../spinner';
import { Box } from '../box';

export type CustomRadioComponentProps = ComponentProps<any> & {
  isSelected: boolean;
  onClick?: () => any;
};

type LoadingComponentProps = { loadingText?: string };

export type CustomRadioInputProps = RadioOption & {
  Component: ComponentType<CustomRadioComponentProps>;
  ariaLabel: string;
  className?: string;
  isLoading?: boolean;
  loadingText?: string;
  LoadingComponent?: ComponentType<LoadingComponentProps>;
};

const DefaultLoadingComponent: FC<LoadingComponentProps> = ({
  loadingText,
}) => {
  return (
    <Flex
      position="absolute"
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      top={0}
      right={0}
      bottom={0}
      left={0}
    >
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        zIndex={999}
        position="relative"
      >
        <Box padding="m">
          <Spinner color="light" />
        </Box>

        <Text mt="s" color="white" textAlign="center" variant="xs">
          {loadingText}
        </Text>
      </Flex>

      <Flex
        position="absolute"
        width="100%"
        height="100%"
        backgroundColor="primary"
        opacity=".8"
      />
    </Flex>
  );
};

const HiddenRadioInput = styled.input`
  appearance: none;
  position: absolute;
`;

export const CustomRadioInput: FC<CustomRadioInputProps> = (props) => {
  const {
    value,
    className,
    children,
    Component,
    ariaLabel,
    isLoading,
    loadingText,
    LoadingComponent = DefaultLoadingComponent,
    ...componentProps
  } = props;
  const { onChange, onReSelect, name, disabled, onClick, ...context } =
    useContext(RadioGroupContext);

  const isSelected = value === context.value;

  const select = useCallback(() => {
    onChange(value);
  }, [onChange, value]);

  return (
    <Flex className={className} flexDirection="column" flexGrow={1}>
      <HiddenRadioInput
        type="radio"
        id={value}
        name={name}
        onChange={select}
        disabled={disabled}
        value={value}
        onClick={(event) => {
          onClick?.(event);
          if (isSelected && onReSelect) {
            onReSelect(value);
          }
        }}
        checked={isSelected}
      />
      <Flex
        as="label"
        // @ts-ignore
        htmlFor={value}
        aria-label={ariaLabel}
        flexGrow={1}
      >
        <Component {...componentProps} isSelected={isSelected}>
          {isLoading && <LoadingComponent loadingText={loadingText} />}
          {children}
        </Component>
      </Flex>
    </Flex>
  );
};
