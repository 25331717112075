import { Palette } from '../constants/palette';
import { Theme } from '../types/theme-types';

/**
 * Colors are generically named (not descriptive of color) to tie
 * closely to their intended use in the UI/ align with the style guide.
 * If we ever add a new theme, i.e. "dark", we keep the same names and
 * simply swap out the Palette color values
 */
export const colors: Theme['colors'] = {
  primary: Palette.darkBlue,
  secondary: Palette.white,
  cta: Palette.royalBlue,
  secondaryGray: Palette.gray96,
  // this our brand color -- only used in logos (prob won't be used much)
  brand: Palette.updaterRed,
  hover: Palette.royalBlue80,
  active: Palette.royalBlue90,
  visited: Palette.royalBlue90,
  disabled: Palette.gray90,
  confirmation: Palette.green,
  warning: Palette.orange,
  error: Palette.red,
  link: Palette.royalBlue,
  text: Palette.darkBlue,
  softerBlue: Palette.softerBlue,
  navBarActiveItem: Palette.white,
  navbarText: Palette.gray40,
  navBarIconBackground: Palette.gray96,
  navBarActiveItemBackground: Palette.blue,
  navBarBackground: Palette.white,
  transparent: 'transparent',
  itemHoverBackground: Palette.gray96,
  switchBackground: Palette.gray80,
  switchButton: Palette.gray94,
  success: Palette.mint,
  info: Palette.softBlue,
  reviewCardBackground: Palette.gray96,
  lightGray: Palette.gray60,
  gray90: Palette.gray90,
  cardIcon: Palette.lightBlue,
  border: Palette.gray80,
  containerBackground: Palette.gray96,
  white: Palette.white,
  divider: Palette.gray90,
};
