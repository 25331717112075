// import fetch from '@updater-core/lib/fetch';

import snakeCase from 'lodash/snakeCase';

import { fetch } from '@updater/ui-utilities';
import { TrackEvent, EventStreamConfig } from './types';
/**
 * Converts a front end event into an event stream event.
 *
 * @param {object} event Front end formatted event
 * @returns {object} Event stream formatted event
 */
export function updaterEventFromEvent(event: TrackEvent) {
  return {
    timestamp: event.timestamp,
    domain: snakeCase(event.domain),
    object: snakeCase(event.object),
    verb: snakeCase(event.verb),
    user: event.user,
    page: event.page,
    details: event.details,
  };
}

/**
 * Makes a function that can be used to send an event to the event stream
 * @param config
 * @returns Function that POSTs to event stream
 */
export function makeEventStreamTrack(config: EventStreamConfig) {
  return (event: TrackEvent) => {
    const { url, app, authorization } = config;
    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        App: app,
        Authorization: authorization,
        'Site-Origin': window.location.href,
        'Content-type': 'application/json',
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify({ updater: updaterEventFromEvent(event) }),
    }).catch((e: Error) => {
      // eslint-disable-next-line no-console
      console.log('[ERROR] - event stream track failed', event, e);
    });
  };
}
