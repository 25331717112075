import { createContext } from 'react';

export type RadioGroupContextType = {
  value: string;
  onChange: (nextValue: string) => any;
  name: string;
  disabled: boolean;
  onClick?: (event: any) => any;
  onReSelect?: (currentValue: string) => any;
};

export const RadioGroupContext = createContext<RadioGroupContextType>(
  {} as RadioGroupContextType
);
