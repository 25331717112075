import React, { ReactNode, useState } from 'react';
import styled from '@emotion/styled';
import { RadioColor, RadioSize } from '../radio';
import { Flex } from '../flex';
import { Box } from '../box';
import { RadioPill } from '../radio-pill';
import { RadioListVariantKey } from '../../types/theme-types';
import { LabeledRadio } from '../labeled-radio';
import { useStyledLikeArray } from '../../hooks/use-styled-like-array';

type RadioListDirection = 'horizontal' | 'vertical';
type RadioListOption = {
  value: string;
  label: string | ReactNode;
  disabled?: boolean;
  rightSlot?: React.ReactNode;
};

type GetGridStylesOptions = {
  direction?: RadioListDirection | RadioListDirection[];
};

export interface RadioListProps extends GetGridStylesOptions {
  color?: RadioColor;
  controlSize?: RadioSize;
  value: string;
  onChange: (newValue: string) => void | Promise<void>;
  options: RadioListOption[];
  name?: string;
  /**
   * type of option 'pill' | 'standard'
   */
  variant?: RadioListVariantKey;
  /**
   * option to space horizontal radio buttons equally using flex
   * default is false
   */
  distributeEqually?: boolean;
}

const RadioListWrapper = styled(Flex)`
  width: 100%;
  flex-wrap: wrap;
`;

const RadioListFlexBox = styled(Box)<{
  distributeEqually: boolean;
  direction: RadioListDirection;
}>`
  flex: ${({ distributeEqually }) => (distributeEqually ? 1 : null)};
  ${({ direction, theme }) =>
    direction === 'horizontal'
      ? `
    margin-right: ${() => theme.space.xs}px;
    & + & {
      margin-left: ${() => theme.space.xs}px;
    }
    &:last-of-type {
      margin-right: 0;
    }
  `
      : ''}
`;

/**
 * RadioList
 *
 * @description This component implement a list of radio inputs.
 *
 * @component
 * @example
 *
 *const options = [
 *  {
 *     label: 'Value 1',
 *     value: 'value_1',
 *   },
 *  {
 *     label: 'Value 2',
 *     value: 'value_2',
 *   },
 * ];
 *
 * const [value, setValue] = useState('value_1');
 *
 * return (
 *   <RadioList
 *     options={options}
 *     onChange={setValue}
 *   />
 * )
 */
export const RadioList: React.FC<RadioListProps> = ({
  options,
  value,
  onChange,
  color = 'green',
  direction = 'vertical',
  controlSize: size = 'm',
  name,
  variant = 'pill',
  distributeEqually = false,
}) => {
  /**
   * Holds the index of the focused input (if any)
   */
  const [focusedInput, setFocusedInput] = useState<number | null>(null);
  const currentDirection = useStyledLikeArray(direction) ?? 'vertical';

  return (
    <RadioListWrapper
      flexDirection={currentDirection === 'horizontal' ? 'row' : 'column'}
    >
      {variant === 'pill'
        ? options.map(
            ({ value: optionValue, label, disabled, rightSlot }, index) => (
              <RadioListFlexBox
                key={optionValue}
                direction={currentDirection}
                distributeEqually={distributeEqually}
                mb="s"
                mr={direction === 'horizontal' ? 's' : 'none'}
              >
                <RadioPill
                  size={size}
                  color={color}
                  isFocused={focusedInput === index}
                  id={optionValue}
                  onFocus={() => setFocusedInput(index)}
                  onBlur={() => setFocusedInput(null)}
                  value={optionValue}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  disabled={disabled}
                  name={name || optionValue}
                  label={label}
                  checked={value === optionValue}
                  rightSlot={rightSlot}
                />
              </RadioListFlexBox>
            )
          )
        : options.map(({ value: optionValue, label, disabled }, index) => (
            <RadioListFlexBox
              key={optionValue}
              direction={currentDirection}
              distributeEqually={distributeEqually}
              mb="s"
              mr={direction === 'horizontal' ? 's' : 'none'}
            >
              <LabeledRadio
                size={size}
                color={color}
                isFocused={focusedInput === index}
                id={optionValue}
                onFocus={() => setFocusedInput(index)}
                onBlur={() => setFocusedInput(null)}
                value={optionValue}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                disabled={disabled}
                name={name || optionValue}
                label={label}
                checked={value === optionValue}
              />
            </RadioListFlexBox>
          ))}
    </RadioListWrapper>
  );
};
