import React, { ElementType, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import { Box } from '../box';
import { Flex } from '../flex';
import { Text } from '../text';
import { useViewportSizes } from '../../hooks';

import { ProfileIcon } from './icons/ProfileIcon';
import { CloseIcon } from './icons/CloseIcon';

type ProfileItemWrapperProps = MenuProps & StyledProps;

interface ProfileMenuProps {
  onClick?: () => void;
  label: string;
}
interface StyledProps {
  /**
   * Use `menuBackgroundColor` to change `backgroundColor` on the open menu.
   * Defaults to `theme.color.primary`.
   */
  menuBackgroundColor?: string | undefined;
}
interface MenuProps {
  isOpen: boolean;
}

const ProfileItemWrapper = styled(Flex)<ProfileItemWrapperProps>`
  transform: ${({ isOpen }) =>
    isOpen ? 'translate3d(0, 0, 0)' : 'translate3d(100%, 0, 0)'};
  background-color: ${({ isOpen, menuBackgroundColor, theme }) =>
    isOpen ? menuBackgroundColor || theme.colors.primary : '#fffff'};
  transition: background-color 0.3s linear, transform 0.3s;
`;

const ProfileMenu: React.FC<StyledProps & ProfileMenuProps> = ({
  children,
  menuBackgroundColor,
  label,
  onClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const theme = useTheme();
  const { isMedium } = useViewportSizes();
  const sideBarWidth = useMemo(
    () => (isMedium ? '376px' : '492px'),
    [isMedium]
  );
  const onClickExposure = () =>
    typeof onClick === 'function' ? (onClick(), toggle()) : toggle();
  return (
    <>
      <Flex
        alignItems="center"
        style={{ visibility: !isOpen ? 'visible' : 'hidden' }}
        onClick={onClickExposure}
      >
        <Text variant="xs">{label}</Text>
        <Box marginLeft="xxs">
          <ProfileIcon width="32px" height="32px" />
        </Box>
      </Flex>
      <ProfileItemWrapper
        isOpen={isOpen}
        menuBackgroundColor={menuBackgroundColor}
        theme={theme}
        zIndex={2}
        position="fixed"
        top="0"
        right="0"
        width={sideBarWidth}
        height="100vh"
        flexDirection="column"
        alignItems="flex-end"
        data-testid="items-wrapper"
        paddingRight={['l', 'l', 's', 'xxxl', 'xxxl']}
      >
        <Box
          position="relative"
          onClick={onClickExposure}
          top={['50px', '50px', '36px', '40px', '44px']}
          width={['32px', '32px', '32px', '40px', '48px']}
          style={{ visibility: isOpen ? 'visible' : 'hidden' }}
        >
          <CloseIcon width="100%" />
        </Box>
        {children}
      </ProfileItemWrapper>
    </>
  );
};

type ProfileItemProps = {
  as?: ElementType;
  onClick?: () => void;
};

export const TextWrapper = styled(Text)`
  color: #ffffff;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.hover};
  }
`;

TextWrapper.defaultProps = {
  variant: 'xxxlBold',
};

const ProfileItem: React.FC<ProfileItemProps> = ({ children, as, onClick }) => (
  <Box
    as={as}
    position="relative"
    top={['80px', '80px', '114px', '146px', '162px']}
    paddingBottom="m"
    onClick={onClick}
  >
    {children}
  </Box>
);

export { ProfileMenu, ProfileItem };
