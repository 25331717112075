import { window } from './window';
import { useNativeWebServerProps } from './server-props';

export const NativeAppUserAgent = 'consumer-native-app';

/**
 * A global that's automatically injected when mounted in the native app.
 */
export type ReactNativeWebview = {
  postMessage(text: string): unknown;
};

/**
 * Returns a simulated or real webview appropriate for the environment
 */
export function useWebview() {
  const webview = useNativeWebServerProps();

  if (webview?.simulated) {
    return SimulatedWebview;
  }

  if (window() == null && webview?.useragent?.includes(NativeAppUserAgent)) {
    return ServerSideWebview;
  }

  return window<ReactNativeWebview>('ReactNativeWebView');
}

/**
 * A tool for testing and debugging use of native-web elements and hooks
 */
const SimulatedWebview: ReactNativeWebview = {
  postMessage(data) {
    console.log('SimulatedWebview.postMessage', JSON.parse(data));
  },
};

/**
 * A noop instance to provide for server side rendering
 */
const ServerSideWebview: ReactNativeWebview = {
  postMessage() {},
};
