/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, ComponentProps } from 'react';
import InputMask from 'react-input-mask';
import { onMaskedStateChange } from '../../utils/react-input-mask-helpers';
import { Input } from '../input';

// capture doesn't align between InputMask and input. It's not a necessary prop
// so we can just omit it
export type ZipCodeInputProps = Omit<ComponentProps<typeof Input>, 'capture'>;

/**
 * Unmasks a masked phone value
 * @param event
 * @returns tuple of raw entry an formatted value
 */
export const unmaskZipCode = (maskedValue: string): string =>
  maskedValue.replace(/[^0-9.]/g, '');

/**
 * Phone Input with North American Standard Format ()
 */
export const ZipCodeInput: React.FC<ZipCodeInputProps> = ({
  value,
  label,
  disabled,
  onChange,
  ...rest
}) => {
  // if zip+ 4 is desired use '99999-9999`;
  // const [mask, setMask] = useState('99999');

  // // Only use this with zip + 4
  // const onZipBlur = (e: React.FocusEvent<HTMLInputElement>) => {
  //   if (e.currentTarget === e.target) {
  //     const rawInput = e.target.value.replace(/[^0-9.]/g, '');
  //     if (rawInput.length === 5 || rawInput.length < 9) {
  //       setMask('99999');
  //     }
  //   }
  // };

  // Only use with zip + 4
  // const onZipFocus = (e: React.FocusEvent<HTMLInputElement>) => {
  //   if (e.currentTarget === e.target) {
  //     setMask('99999-9999');
  //   }
  // };
  /**
   * Will unmask and uplift the changed zip-code number value
   * @param event
   */
  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line no-param-reassign
    event.target.value = unmaskZipCode(event.target.value);
    onChange?.(event);
  };

  const mask = '99999';

  return (
    <InputMask
      mask={mask} // if zip+4 use mask={mask} and restore commented out code
      onChange={onChangeInput}
      disabled={disabled}
      value={value}
      // onFocus={onZipFocus} // Only use this if zip+4 is desired
      {...rest}
      onBlur={(e) => {
        // onZipBlur(e);     // Only needed for zip+ 4 - restore previous code
        // For Formik
        if (rest?.onBlur) {
          rest.onBlur(e);
        }
      }}
      beforeMaskedStateChange={(state) =>
        onMaskedStateChange(state, mask, unmaskZipCode)
      }
    >
      <Input id={rest.id} label={label} />
    </InputMask>
  );
};
