import styled from '@emotion/styled';
import { compose, grid, justifyItems, JustifyItemsProps } from 'styled-system';
import { Box, BoxProps } from '../box/Box';
import { GridProps as GridPropsSS } from '../../types/theme-types';

export interface GridProps extends BoxProps, GridPropsSS, JustifyItemsProps {}

/**
 * A layout component that lets you apply a range
 * of grid properties.
 */
export const Grid = styled(Box)<GridProps>`
  display: grid;
  ${compose(grid, justifyItems)}
`;
