import styled from '@emotion/styled';
import { GridLayoutColumn, GridLayout } from '../grid-layout';

import { GridCardProps } from './GridCard.types';

import { defaultCardBorder } from '../card';

export const GridCardColumn = styled(GridLayoutColumn)<GridCardProps>`
  background-color: ${(props) => props.backgroundColor};
  border: ${defaultCardBorder};
  border-radius: ${(props) =>
    props.variant === 'rounded' ? props.theme.radii.l : props.theme.radii.xs};
  box-shadow: ${(props) =>
    props.shadow ? '0px 4px 8px rgba(0, 0, 0, 0.05);' : 'none'};
  border-radius: ${(props) =>
    props.variant === 'rounded' ? props.theme.radii.l : props.theme.radii.xs};
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const GridCardLayout = styled(GridLayout)`
  row-gap: ${(props) => props.theme.space.s}px;
  ${({ theme }) => theme.mediaQueries.l} {
    row-gap: ${(props) => props.theme.space.m}px;
    margin: ${(props) => props.theme.space.none};
  }
`;
