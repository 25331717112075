import styled from '@emotion/styled';
import {
  flexbox,
  border,
  BorderRadiusProps,
  shadow,
  boxShadow,
  BoxShadowProps,
  ShadowProps,
  BorderProps,
} from 'styled-system';
import { Box } from '../box/Box';
import { FlexboxProps } from '../../types/theme-types';

export type FlexProps = FlexboxProps &
  BorderRadiusProps &
  BorderProps &
  ShadowProps &
  BoxShadowProps;

/**
 * A layout component that lets you apply a range
 * of flex properties.
 */
export const Flex = styled(Box)<FlexProps>`
  display: flex;
  ${flexbox}
  ${shadow}
  ${boxShadow}
  ${border}
`;
