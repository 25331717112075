import React, { useMemo } from 'react';
import {
  ThemeProvider as EmotionThemeProvider,
  Global,
  css,
} from '@emotion/react';
import { IconContext, IconProps } from 'phosphor-react';
import merge from 'lodash/merge';
import { theme } from '../theme';
import { ToastProvider } from '../components/toast';
import { ReactDayPickerStyles } from '../styles/vendors';

import type { ThemeOverride, Theme } from '../types/theme-types';

interface ThemeProviderProps {
  /**
   * If `font-family` should be set on the `body`. Defaults to true.
   * Useful if for some reason multiple text styles need to be supported on the same page.
   */
  addFontFamilyToBody?: boolean;
  /**
   * Modify variants style attributes for select components.
   */
  customTheme?: ThemeOverride;
}

// TODO: We need to host these ttf-variable fonts then swap out the URL
const globalFontFace = css`
  /* latin-ext */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 300 600;
    font-display: swap;
    src: url('https://app.staging.updater.com/font/WorkSans-VariableFont-LatinSubset-Updater.woff2')
      format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 300 600;
    font-display: swap;
    src: url('https://app.staging.updater.com/font/WorkSans-VariableFont-LatinSubset-Updater.woff2')
      format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
`;
const fontFamily = css`
  // adds anti-aliasing to retina display devices Chrome and Safari
  // reduces visual font weight
  body {
    font-family: 'Work Sans', sans-serif;
    font-feature-settings: 'salt';
  }
  @supports (-webkit-font-smoothing: antialiased){
    body{
      -webkit-font-smoothing: antialiased;
    }
  }
  // adds anti-aliasing to retina display devices Firefox
  // reduces visual font weight
  @supports (-moz-osx-font-smoothing: grayscale){
    body{
      -moz-osx-font-smoothing: grayscale;
  }

`;
const saltOn = css`
  * {
    font-feature-settings: 'salt';
  }
`;
const reset = css`
  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }
`;

const defaultIconContext: IconProps = {
  size: theme.fontSizes.xl,
  weight: 'regular',
  mirrored: false,
};

// TODO: Write test for this
const deepMergeTheme = (baseTheme: Theme, customTheme: ThemeOverride) =>
  merge({}, baseTheme, customTheme);

/**
 * Main provider to populate global styles and inject the theme in your application.
 * Your app needs to wrapped in this provider for ui-uds to function.
 * @returns Component
 */

export const ThemeProvider: React.FC<ThemeProviderProps> = ({
  children,
  addFontFamilyToBody = true,
  customTheme = {},
}) => {
  const mergedTheme = useMemo<Theme>(
    () => deepMergeTheme(theme, customTheme),
    []
  );

  return (
    <EmotionThemeProvider theme={mergedTheme}>
      <Global
        styles={css`
          ${reset}
          ${ReactDayPickerStyles}
          ${globalFontFace}
          ${addFontFamilyToBody ? fontFamily : ''}
          ${saltOn}
        `}
      />

      <IconContext.Provider value={defaultIconContext}>
        <ToastProvider>{children}</ToastProvider>
      </IconContext.Provider>
    </EmotionThemeProvider>
  );
};
