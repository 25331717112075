import React, { useState, useRef, FC } from 'react';
import styled from '@emotion/styled';
import { Flex } from '../flex';
import { Error } from '../error';
import { Box } from '../box';

const nonDigitsRegex = /[^0-9]/;

export interface CodeInputProps {
  /**
   * The length of the code inputs.
   * @example
   * length = 4
   */
  length?: number;
  /**
   * The isLoading property controls the read-only state of the CodeInput.
   * @example
   * isLoading = true
   */
  isLoading?: boolean;
  /**
   * The error message of the CodeInput component.
   * @example
   * error = "Something went wrong"
   */
  error?: string;
  /**
   * The onChange will be called when the user changes the code input content
   */
  onChange: (code: string) => void;
}
type InputWrapperProps = Pick<CodeInputProps, 'error'>;

const InputWrapper = styled.input<InputWrapperProps>`
  text-align: center;
  width: inherit;
  padding: 0;
  font-size: ${({ theme }) => theme.fontSizes.xxxxl}px;
  line-height: ${({ theme }) => theme.lineHeights.xxxxl};
  color: ${({ theme }) => theme.colors.primary};
  border: none;
  height: 74px;
  border-radius: ${({ theme }) => theme.space.xs}px
    ${({ theme }) => theme.space.xs}px 0 0;
  border-bottom: 2px solid
    ${({ error, theme }) => (error ? theme.colors.error : theme.colors.primary)};
  margin-right: ${({ theme }) => theme.space.s}px;
  background-color: ${({ theme, value }) =>
    value ? theme.colors.itemHoverBackground : 'transparent'};
  &:focus {
    outline: none;
    border-width: 3px;
    background-color: ${({ theme }) => theme.colors.itemHoverBackground};
    box-shadow: 0px 7px 4px -7px #d9d9d9;
  }
  ${({ theme }) => theme.mediaQueries.m} {
    margin-right: ${({ theme }) => theme.space.m}px;
  }
  &:last-of-type {
    margin-right: 0;
  }
`;

export const CodeInput: FC<CodeInputProps> = ({
  length = 4,
  isLoading,
  error,
  onChange,
}) => {
  const [code, setCode] = useState(Array.from(Array(length)).map(() => ''));
  const inputs = useRef<(HTMLInputElement | null)[]>([]);

  const onCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    slot: number
  ) => {
    const { value } = e.target;

    if (nonDigitsRegex.test(value)) return;

    const newCode = [...code];
    newCode[slot] = value;
    setCode(newCode);

    const isNotLastSlot = slot !== length - 1;
    if (isNotLastSlot && value !== '') {
      inputs.current[slot + 1]?.focus();
    }
    onChange(newCode.join(''));
  };

  const onKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    slot: number
  ) => {
    const isBackspaceKeyDown = e.key === 'Backspace';
    const isNotFirstSlotBackspaced =
      isBackspaceKeyDown && !code[slot] && slot !== 0;

    if (isNotFirstSlotBackspaced) {
      const previousSlot = slot - 1;
      const newCode = [...code];

      newCode[previousSlot] = '';
      setCode(newCode);

      inputs.current[previousSlot]?.focus();

      onChange(newCode.join(''));
    }
  };

  const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = e.clipboardData
      .getData('Text')
      .replace(new RegExp(nonDigitsRegex, 'g'), '');

    /**
     * Ensure that the code length is always `length` characters long
     * regardless of the pasted input length.
     */
    const pastedCode = Array.from(Array(length)).map(
      (_, idx) => pastedValue[idx] ?? ''
    );

    setCode(() => [...pastedCode]);

    /**
     * Setting state directly will not trigger `onCodeChange` so we need to
     * call `onChange` here to notify the parent of the change.
     */
    onChange(pastedCode.join(''));
  };

  return (
    <>
      <Flex justifyContent="start" alignItems="center" width="100%">
        {code.map((num, idx) => {
          const initFocus = !code[0].length && idx === 0;
          return (
            <InputWrapper
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              type="text"
              inputMode="numeric"
              maxLength={1}
              value={num}
              autoFocus={initFocus}
              readOnly={isLoading}
              error={error}
              onChange={(e) => onCodeChange(e, idx)}
              onKeyDown={(e) => onKeyDown(e, idx)}
              ref={(ref) => inputs.current.push(ref)}
              onPaste={onPaste}
              autoComplete={idx === 0 ? 'one-time-code' : undefined}
            />
          );
        })}
      </Flex>
      <Box minHeight="20px" marginTop="xs">
        {error && <Error variant="s">{error}</Error>}
      </Box>
    </>
  );
};
